import React from 'react';
import  { Redirect } from 'react-router-dom'
import * as apiPathes from '../apiPathes';
import * as serviceCaller from '../serviceCaller'
import DatePicker from 'react-datepicker';
import uiHelpers from '../../helpers/uiHelpers'
import {pageInitialising} from '../constants'
import {convertErrorCode} from '../conveters'
import {CheckSquare, Refresh, XSquare} from '../../components/tools/Icons'

class NewPeriod  extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            initialising: true,
            disableFunctions: true,
            approvals: null,
            selectedApproval: null,
            startDate: null,
            endDate: null,
            selectedStorages: [],
            selectedMeters: [],

            startDateError: null,
            endDateError: null,
            noStorageOrMeterError : null,
            globalError: null,
            
            nominatedMeasurePeriodId: null,
            redirect: false,
            confirming: false
        };
    }

    async componentDidMount(){
        this.refresh();
    }

    refresh = async ()=>{

        serviceCaller.get(apiPathes.getApprovals,{
            noIWasLoginHandler: this.props.noLoginCallback,
            loadingMsg: 'Getting your approvals...',
            beforeSend: ()=>{
                this.setState({disableFunctions: true})
            },
            success: (data)=>{
                this.setState({approvals: data, initialising: false, disableFunctions: false});
            }
        });
    }

    createPeriod = () => {
        if(!this.state.selectedApproval) return;
        let startDateError = null;
        let endDateError = null;
        let noStorageOrMeterError = null;
        const today = new Date();

        if(!this.state.startDate) startDateError = "This is mandatory";
        else if(this.state.startDate > today) startDateError="This cannot be future date";

        if(this.state.endDate && this.state.endDate > today) endDateError = "This cannot be future date";
        else if(this.state.endDate && this.state.startDate >= this.state.endDate) endDateError = "End date needs to be after start date";
        
        if(this.state.selectedMeters.length == 0 && this.state.selectedStorages.length == 0) noStorageOrMeterError="Please select at least a storage or point of intake meter for the period";
        
        this.state.startDateError = startDateError;
        this.state.endDateError = endDateError;
        this.state.noStorageOrMeterError = noStorageOrMeterError;

        if(startDateError || endDateError || noStorageOrMeterError) {
            this.state.globalError = "Please fix the above errors before continue";
            this.setState(this.state)
        } else {
            this.state.globalError = null;
            this.state.confirming = true;
            this.setState(this.state)
        }
        //this.requestCreatePeriod();
    }

    cancelConfirmation = () => {
        this.setState({confirming:false})
    }

    requestCreatePeriod = ()=> {

        serviceCaller.post(apiPathes.createNominatedMeasurementPeriod,
            {
                approvalNumber: this.state.selectedApproval.approvalNumber, 
                periodStartDate: this.state.startDate, 
                periodEndDate: this.state.endDate,
                ratingTableIds : this.state.selectedStorages.map(s=>s.ratingTable.id),
                meterIds : this.state.selectedMeters.map(s=>s.poitmTable.id),
            },
            {
                noIWasLoginHandler: this.props.noLoginCallback,
                loadingMsg: 'Trying to create nominated measurement period...',
                beforeSend: ()=>{
                    this.setState({globalError: null, disableFunctions:true});
                },
                errorCodeHandler: (errorCode) =>{
                    this.setState({disableFunctions: false, globalError: convertErrorCode(errorCode)});
                },
                success: (data)=>{
                    this.setState({nominatedMeasurePeriodId: data.id, redirect: true})
                }
            }
        );
    }

    onApprovalChange = (approval) => {
        this.setState({selectedApproval: approval,startDateError:null,endDateError:null,globalError:null, selectedStorages:[], selectedMeters:[],noStorageOrMeterError:null});
    }

    onSelectStorageChanged = (storage) => {
        var index = -1
        for(var i=0; i < this.state.selectedStorages.length; i++ ) {
            if(this.state.selectedStorages[i].ratingTable.id == storage.ratingTable.id) {
                index = i;
            }
        }
        if(index == -1) {
            this.state.selectedStorages.push(storage);
        } else {
            this.state.selectedStorages.splice(index,1);
        }
        this.setState({selectedStorages: this.state.selectedStorages});
    }

    onSelectMeterChanged = (meter) => {

        var index = -1
        for(var i=0; i < this.state.selectedMeters.length; i++ ) {
            if(this.state.selectedMeters[i].poitmTable.id == meter.poitmTable.id) {
                index = i;
            }
        }
        if(index == -1) {
            this.state.selectedMeters.push(meter);
        } else {
            this.state.selectedMeters.splice(index,1);
        }
        this.setState({selectedMeters: this.state.selectedMeters});
    }

    isStorageSelected = (storage) => {
        for(var i=0; i < this.state.selectedStorages.length; i++ ) {
            if(this.state.selectedStorages[i].ratingTable.id == storage.ratingTable.id) {
                return true;
            }
        }
        return false;
    }

    isMeterSelected = (meter) => {
        for(var i=0; i < this.state.selectedMeters.length; i++ ) {
            if(this.state.selectedMeters[i].poitmTable.id == meter.poitmTable.id) {
                return true;
            }
        }
        return false;
    }

    render() {
        if(this.state.initialising) return <>
            <h3 className='mb-3'>New nominated measurement period</h3>
            {pageInitialising}
        </>
        return <>
            {this.state.redirect && <Redirect to={"/details/" + this.state.nominatedMeasurePeriodId} />}
            <h3 className='mb-3'>New nominated measurement period</h3>
            <p>Please select the approval for the new nominated measurement period</p>
            {this.state.approvals.length == 0 && <>
                <div className='col-6 alert alert-warning'>
                    We cannot find any approval from you account can create nominated measurement period. 
                </div>
            </>}
            {this.state.approvals.length > 0 && <>
                <div class="list-group fs-90 col-md-6 mb-3">
                    {this.state.approvals.map(item=> {
                        var disabled = false;
                        if(item.poitms.length == 0 && item.storages.length==0) disabled = true;
                        disabled = this.state.disableFunctions || disabled || this.state.confirming;

                        return <label for={item.approvalNumber} className="list-group-item list-group-item-action">
                            <div className='float-start pt-1'>
                                <input
                                    type="radio"
                                    value={item.approvalNumber}
                                    id={item.approvalNumber}
                                    checked={this.state.selectedApproval && this.state.selectedApproval.approvalNumber == item.approvalNumber}
                                    onChange={(e)=>this.onApprovalChange(item)}
                                    disabled={disabled}
                                />
                            </div>
                            <div className={`${item.poitms.length == 0 && item.storages.length==0 ? 'ms-4 text-muted': 'ms-4'}`}>
                                <p class="mb-1">
                                    <strong>{item.approvalNumber}</strong>
                                    {item.roi!=null && item.roi!="" && <small> (ROI: {item.roi})</small>}
                                </p>
                                {item.poitms.length == 0 && item.storages.length==0 && <em>No storages or meters</em>}
                                {item.poitms.length > 0 && <span class="badge bg-primary me-2">{item.poitms.length} point of intake meter{item.poitms.length >1 && 's'}</span>}
                                {item.storages.length > 0 && <span class="badge bg-primary">{item.storages.length} storage{item.storages.length >1 && 's'}</span>}
                            </div>
                        </label>
                    })}
                </div>

                {this.state.selectedApproval &&  <>

                    {this.state.confirming ? <>
                        <div className='row mb-3'>
                            <div className='col-md-6 mb-3'>
                                <p className='text-primary'>The following storage(s) and point of intake meter(s) are selected to be used in this period for <strong>{this.state.selectedApproval.approvalNumber}</strong></p>

                                {this.state.selectedStorages.length > 0 && <>
                                    <p>Storage(s)</p>
                                    <ul className="list-group mb-3">
                                        {this.state.selectedStorages.map(item=><>
                                            <li class="list-group-item bg-light">{item.ratingTable.ratingTableName}</li>
                                        </>)}
                                    </ul>
                                </>}

                                {this.state.selectedMeters.length > 0 && <>
                                    <p>Point of intake meter(s)</p>
                                    <ul className="list-group mb-3">
                                        {this.state.selectedMeters.map(item=><>
                                            <li class="list-group-item bg-light">{item.poitmTable.MeterSiteId}</li>
                                        </>)}
                                    </ul>
                                </>}
                            </div>
                        </div>
                    </> : <>
                        <div className='row mb-3'>
                            <div className='col-md-6 mb-3'>
                                <div className={`card ${this.state.noStorageOrMeterError!=null ? 'border-danger' : ''}`}>
                                    <div class="card-header">
                                        Storage(s) and/or Meter(s) for <strong className='text-primary'>{this.state.selectedApproval.approvalNumber}</strong>
                                    </div>
                                    <div class="card-body">
                                        {this.state.selectedApproval.storages.length > 0 && <div className='mb-3'>

                                            <p className='text-primary'>Please select storage(s) or point of intake meter(s) to be used in this period</p>

                                            <p>Storage(s)</p>
                                            <div class="list-group">
                                                {this.state.selectedApproval.storages.map((item, index)=><label className={`list-group-item ${(!item.dasMeterExists && !item.secondaryMeterExists) ? 'bg-light':''}`}>

                                                    <input class="form-check-input me-1" disabled={!item.dasMeterExists && !item.secondaryMeterExists} type="checkbox" onChange={()=>this.onSelectStorageChanged(item)} checked={this.isStorageSelected(item)} value={item.ratingTable.id}/>
                                                    {item.ratingTable.ratingTableName}
                                                    <span className='fs-80 ms-2 text-secondary'>
                                                        (&nbsp;
                                                        {item.dasMeterExists ? 
                                                            <span>
                                                                Primary Device <span className='text-success'> <CheckSquare /></span>
                                                            </span> 
                                                            : 
                                                            <span>
                                                                Primary Device <XSquare />
                                                            </span> 
                                                        }
                                                        &nbsp;,&nbsp;
                                                        {item.secondaryMeterExists ? 
                                                            <span>
                                                                Secondary Device <span className='text-success'> <CheckSquare /></span>
                                                            </span> 
                                                            : 
                                                            <span>
                                                                Secondary Device <XSquare />
                                                            </span> 
                                                        }
                                                        &nbsp;
                                                        {(!item.dasMeterExists && !item.secondaryMeterExists) && <span className='text-danger'> - not compliant</span>}
                                                        )
                                                    </span>
                                                </label>)}
                                            </div>

                                            
                                            
                                        </div>}

                                        {this.state.selectedApproval.poitms.length > 0 && <div className='mb-3'>
                                            <p>Point of intake meter(s)</p>
                                            <div class="list-group">
                                                {this.state.selectedApproval.poitms.map(item=><label class="list-group-item">
                                                    <input class="form-check-input me-1" type="checkbox" onChange={()=>this.onSelectMeterChanged(item)} checked={this.isMeterSelected(item)}/>
                                                    {item.poitmTable.MeterSiteId} <span className='fs-80 text-secondary'>({item.poitmTable.isTelemetry ? 'Telemetry' : 'non-Telemetry'})</span>
                                                </label>)}
                                            </div>
                                        </div>}

                                        <div className='text-danger fs-90'>{this.state.noStorageOrMeterError}</div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </>}
                    


                    <div className='row mb-3'>
                        <div className='col-md-3 mb-2'>
                            <div className={`react-datepicker-block ${this.state.startDateError ? "react-date-invalid" : ""}`}>
                                <label class="form-label d-block">Period starts at <span className='text-danger'>*</span>:</label>
                                <DatePicker
                                    className={`form-control ${this.state.startDateError? "is-invalid" : ""}`}
                                    maxDate={new Date()}
                                    onChange={(date)=>{this.setState({startDate: date})}}
                                    selected={this.state.startDate}
                                    showTimeSelect
                                    timeIntervals={15}
                                    dateFormat={"dd/MM/yyyy h:mm aa"}
                                    timeCaption="Time"
                                    disabled={this.state.disableFunctions || this.state.confirming}
                                />
                                <div class="invalid-feedback">{this.state.startDateError}</div>
                            </div>
                        </div>
                        <div className='col-md-3 mb-2'>
                            <div className={`react-datepicker-block ${this.state.endDateError? "react-date-invalid" : ""}`}>
                                <label class="form-label d-block">Period ends at:</label>
                                <DatePicker
                                    className={`form-control ${this.state.endDateError? "is-invalid" : ""}`}
                                    maxDate={new Date()}
                                    onChange={(date)=>{this.setState({endDate: date})}}
                                    selected={this.state.endDate}
                                    showTimeSelect
                                    timeIntervals={15}
                                    dateFormat={"dd/MM/yyyy h:mm aa"}
                                    timeCaption="Time"
                                    disabled={this.state.disableFunctions || this.state.confirming}
                                />
                                <div class="invalid-feedback">{this.state.endDateError}</div>
                            </div>
                        </div>
                    </div>
                </>}

                {this.state.globalError && <div className='col-md-6 alert alert-danger'>    
                    {this.state.globalError}
                </div>}

                {this.state.confirming ? <div className='row mb-3'>
                    <div className='col-md-6'>
                        <div className='alert alert-primary'>
                            <p>
                            Please confirm that all information above is correct. Also you are required to meet compliance obligations under the following Flood Plain Harvesting Policies:
                            </p>
                            <ul>
                                <li>NSW Floodplain Harvesting Policy</li>
                                <li>NSW Floodplain Harvesting Measurement Policy</li>
                                <li>Water Management Act 2000</li>
                            </ul>
                            <div className='text-center'>
                                <button class="btn btn-secondary me-3" onClick={this.cancelConfirmation} disabled={this.state.disableFunctions}>
                                    Cancel
                                </button>
                                <button class="btn btn-success" onClick={this.requestCreatePeriod} disabled={this.state.disableFunctions}>
                                Yes, I confirm and I am compliant
                                </button>
                            </div>
                        </div>
                    </div>
                </div> : <div className='row mb-3'>
                    <div className='col-md-6'>
                        <a disabled href="/measurement-period/#/dashboard" className={`btn btn-warning ${this.state.disableFunctions? 'disabled' : ''}`}>
                            Back
                        </a>
                        {this.state.selectedApproval && <>
                            <button class="btn btn-primary float-end" disabled={this.state.disableFunctions} onClick={this.createPeriod}>
                                Create
                            </button>
                        </>}
                    </div>
                </div>}

            </>}
        </>
    }
}

export default NewPeriod;