import React, {useState} from 'react';
import { reduxForm, Field, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux';
import 'redux';
import "react-datepicker/dist/react-datepicker.css";
import { updateDocuments, updateDeleteDocuments, changeFormStatus, uploadFiles } from '../design/MeterDesignCertificate.Reducers';
import Authentication from '../../../helpers/Authentication';
import { validate } from './MeterValidationCertificatePartFValidate.Validation';
import { MESSAGE_UPLOAD_LIMITS, closedConduitandProtableOptionsForValidationCert as closedConduitandProtableOptions, openChannelOptionsForValidationCert as  openChannelOptions } from '../../../constants/MeterFormConstants'
import { saveDraftAction, saveSubmitAction, scrollTofocusError } from "../common/MeterFormValidations"
import { RadiosForRedux, InputText , DatePickerForRedux, SelectForRedux}  from '../../tools/BootstrapControls'
import * as userHelpers from '../../../helpers/userHelpers';
import FileUploader from '../../common/FileUploader'


let fileUploaderDisplayListUpdate;

let _validateFiles;

const validateAll = (values, props) => {
   const result = validate(values, props);

   let clickSaveDraft = (props.clickSaveDraft== undefined? values.clickSaveDraft:props.clickSaveDraft);
   let clickNext = props.clickNext== undefined ? values.clickNext : props.clickNext;

   if (clickSaveDraft == 0 && clickNext === 1 && values.meteringEquipmentInformation.formStatus != 2) {
      //alert(_validateFiles)
      if(_validateFiles) {
         if(!_validateFiles()){
            result.meteringEquipmentInformation.fileType = 'Please upload all mandatory supporting documents';
            return result;
         }
      }
   }
   return result;
}

let MeterValidationCertificatePartF = props => {

   const [renderCnt, setRenderCnt] = useState(0);

   const authentication = new Authentication();
   let dqpUserName = "";
   let dqpUserEmail = "";
   var user = authentication.currentUser();
   if (authentication.isLoggedIn) {
      dqpUserName = user.firstName + ' ' + user.lastName;
      dqpUserEmail = user.emails;
   }

   let uploadOptions = closedConduitandProtableOptions;
   if (parseInt(props.masterDataValues.OpenChannelId) == parseInt(props.meterEquipInfo.meteringSystemTypeId)) {
      uploadOptions = openChannelOptions;
   }

   const validateFiles =()=>{
      var validated=[];
      var options=[];
      for (var k=0;k<=uploadOptions.length-1;k++)
      {
           if (uploadOptions[k].name!="Other")
           {
              options.push(uploadOptions[k]);
           }
      }

      if (props.documents.length>0)
      {
         for (var i=0;i<=props.documents.length-1;i++)
         {
            if(!(props.documents[i].status == 'new' || props.documents[i].status == 'existing')){
               continue;
            }
            for (var j=0; j<=options.length-1;j++)
            {
               if (parseInt(props.documents[i].documentType)==parseInt(options[j].id))
               {
                   validated.push(options[j].id);
               }
            }
         }
      }

      return validated.length>=options.length;
   }
   _validateFiles = validateFiles;

   const updateDisplayFileList = ()=>{

      if(!fileUploaderDisplayListUpdate) return;
      const newList = [];

      

      if(documents && documents.length!=0) {

         var option;
         for(var i = 0; i< documents.length; i++){
            if(documents[i].status=='new' || documents[i].status=='existing'){
               var option = uploadOptions.filter(option=>option.id==documents[i].documentType)[0];
               newList.push({
                  id: documents[i].id,
                  fileTypeDesc : option?option.name:"",
                  description: documents[i].description,
                  fileOriginalName: documents[i].fileOriginalName,
                  fileGUID: documents[i].fileGUID
               });
            }
         }

      }
      fileUploaderDisplayListUpdate(newList);
      setRenderCnt(renderCnt+1)
   }

   const fileUploaderFilesUploaded=(files)=>{
      
      var fileType = props.meterEquipInfo.fileType;
      var docs = props.documents;

      for(var i = 0; i< files.length; i++){

         docs.push({
            id : files[i].id,
            documentType : fileType,
            description : files[i].description,
            fileOriginalName : files[i].fileOriginalName,
            fileGUID : files[i].fileGUID,
            status : "new"
         });
      }
      props.dispatch(change(props.form, 'documents', docs))
      updateDisplayFileList();
   }

   
   const fileUploaderFileDeleted=(file)=>{
      if(!props.documents) return;

      for(var i = 0; i< props.documents.length; i++){
         if(props.documents[i].id==file.id){
            if (props.documents[i].status == "new")
               props.documents[i].status = "newDeleted";
            else if (props.documents[i].status == "existing")
               props.documents[i].status = "existingDeleted";
         }
      }

      updateDisplayFileList();
   }

   let {
      previousPage, handleSubmit, meterEquipInfo, userProfileValues, documents, registrationDoc, loading, hasError
   } = props

   const disableFields = (parseInt(meterEquipInfo.formStatus) == 2)|| props.disableFields || props.disableEditing || loading ? true : false;

   let dqpPhoneNumber = "";
   let dqpAddress = "";
   let dqpCmiNo = "";
   let dqpQualificationType = "";
   let dqpBusinessName = "";
   let dqpAbnAcn = "";

   if (userProfileValues != undefined && userProfileValues != null && userProfileValues.length > 0) {
      dqpPhoneNumber = userProfileValues[0].phoneNumber;
      dqpAddress = userProfileValues[0].postalAddress.addressLine;
      dqpCmiNo = userProfileValues[0].cmino;
      dqpQualificationType = userHelpers.getDQPQulificationTypeNameFromValue(userProfileValues[0].dqpQualificationType);
      dqpBusinessName = userProfileValues[0].businessName;
      dqpAbnAcn = userProfileValues[0].abnOrAcn;
   }

   

   const dateCreated = new Date()
   if (meterEquipInfo.date != null && meterEquipInfo.date == "")
      meterEquipInfo.date = dateCreated;

   meterEquipInfo.date = new Date(meterEquipInfo.date)

   if (meterEquipInfo.dateValidation != null && meterEquipInfo.dateValidation == "")
      meterEquipInfo.dateValidation = dateCreated;

   meterEquipInfo.dateValidation = new Date(meterEquipInfo.dateValidation)

   const meterEquipmentCompliesOptions =
      [
         { id: "1", name: "complies with Australian Standarld 4747: Meters for non-urban water supply", selected: false },
         { id: "2", name: "does not comply with Australian Standard 4747: Meters for non-urban water supply for the reasons specified in the Non-compliance section however it can be modified to comply with AS 4747 as specified in that part", selected: false },
         { id: "3", name: "does not comply with Australian Standard 4747: Meters for non-urban water supply for the reasons specified in the Non-compliance section and it cannot be modified to comply with AS 4747", selected: false },
      ];

   const permissibleErrorOptions =
      [
         { id: "1", name: "does not exceed +/-5%", selected: false },
         { id: "2", name: "exceeds +/-5%", selected: false },
      ];

   const ownMeteringValidationOptions =
      [
         { id: "1", name: "YES", selected: false },
         { id: "2", name: "NO", selected: false },
      ];
   if ((!props.meterEquipInfo.fileType || props.meterEquipInfo.fileType == 0) && uploadOptions.length > 0) {
      if (parseInt(props.masterDataValues.OpenChannelId) == parseInt(props.meterEquipInfo.meteringSystemTypeId)) {
         props.dispatch(change(props.form, 'meteringEquipmentInformation.fileType', "4"))
      } else {
         props.dispatch(change(props.form, 'meteringEquipmentInformation.fileType', "3"))
      }
   }

   if (!props.loadingFile) {
      props.dispatch(change(props.form, 'loadingFile', 0))
   }

   return (
      <form className="vertical" onSubmit={handleSubmit}>
         <h5 className='mb-3'>Certification</h5>

         <div className='card mb-3'>
            <div className='card-header'>
               <strong>I certify that:</strong>
            </div>
            <div className='card-body'>
               <p className='fw-bold mb-3'>
                  a) I am a duly qualified person under the Water Management (General) Regulation 2018 for the purpose of validating the metering equipment described in this certificate
               </p>
               <p className='fw-bold mb-1'>
                  b) The metering equipment:
               </p>
               <Field
                  name="meteringEquipmentInformation.validationMeterEquipmentComplies"
                  component={RadiosForRedux}
                  options={meterEquipmentCompliesOptions}
                  values={meterEquipInfo.validationMeterEquipmentComplies}
                  props={{ disabled: true }}
                  className="mb-3"
               />
               <p className='fw-bold mb-1'>
                  c) The maximum permissible error of the metering equipment: (choose one) <span className='text-danger'>* </span>
               </p>
               <Field
                  name="meteringEquipmentInformation.validationMaxErrorMeteringEquipment"
                  component={RadiosForRedux}
                  options={permissibleErrorOptions}
                  values={meterEquipInfo.validationMaxErrorMeteringEquipment}
                  props={{ disabled: disableFields}}
                  className="mb-3"
               />
               <p className='fw-bold mb-3'>
                d) all information contained in this certificate is accurate, true and complete
               </p>
               <p className='fw-bold mb-3'>
               e)I am aware that I must notify the Minister within 7 days if I know or reasonably suspect that metering equipment I am installing or carrying out work on has been tampered with
               </p>
               <p className='fw-bold mb-1'>
               f) I am validating my own metering equipment <span className='text-danger'>* </span>
               </p>
               <Field
                  name="meteringEquipmentInformation.validationOwnMeteringEquipment"
                  component={RadiosForRedux}
                  options={ownMeteringValidationOptions}
                  values={meterEquipInfo.validationOwnMeteringEquipment}
                  props={{ disabled: disableFields }}
                  className="mb-3"
               />

               <hr className='my-4'/>

               <div className='row mb-3'>
                  <div className='col-sm-6'>
                     <InputText
                        label="Name"
                        id="dqpUserName"
                        value={dqpUserName}
                        disabled={true}
                     />
                  </div>
                  <div className='col-sm-6'>
                     <InputText
                        id="certifiedmeterinstallernumber"
                        label="Certified meter installer number"
                        value={dqpCmiNo}
                        disabled={true}
                     />
                  </div>
               </div>
               <div className='row mb-3'>
                  <div className='col-sm-6'>
                     <InputText
                        label="DQP qalification type"
                        id="dqpQulaificationType"
                        value={dqpQualificationType}
                        disabled={true}
                     />
                  </div>
               </div>

               <div className='row mb-3'>
                  <div className='col-sm-6'>
                     <InputText
                        label="Business name"
                        id="dqpBusinessName"
                        value={dqpBusinessName}
                        disabled={true}
                     />
                  </div>
                  <div className='col-sm-6'>
                     <InputText
                        label="ABN or ACN"
                        id="dqpAbnOrAcn"
                        value={dqpAbnAcn}
                        disabled={true}
                     />
                  </div>
               </div>
               <div className='row mb-3'>
                  <div className='col-sm-6'>
                     <InputText
                        label="Address"
                        id="dqpAddress"
                        value={dqpAddress}
                        disabled={true}
                     />
                  </div>
                  <div className='col-sm-6'>
                     <InputText
                        label="Phone"
                        id="dqpPhoneNumber"
                        value={dqpPhoneNumber}
                        disabled={true}
                     />
                  </div>
               </div>
               <div className='row mb-5'>
                  <div className='col-sm-6'>
                     <InputText
                        label="Email"
                        id="dqpUserEmail"
                        value={dqpUserEmail}
                        disabled={true}
                     />
                  </div>
                  <div className='col-sm-4'>
                     <Field 
                        name="meteringEquipmentInformation.dateValidation" 
                        label={<>Date of validation <span className='text-danger'>*</span></>}
                        component={DatePickerForRedux}
                        props={{ disabled: disableFields, maxDate: new Date()}}
                        values={meterEquipInfo.dateValidation} 
                        handleChange={date => {props.change(props.form,'meteringEquipmentInformation.dateValidation', date)}}
                     />
                  </div>
               </div>
               <div className='row mb-3'>
                  <div className='col-sm-4'>
                     <Field
                        name="meteringEquipmentInformation.date"
                        label={<>Date <span className='text-danger'>*</span></>}
                        component={DatePickerForRedux}
                        values={meterEquipInfo.date}
                        props={{ disabled: disableFields, maxDate: new Date() }}
                        handleChange={date => { props.change(props.form, 'meteringEquipmentInformation.date', date) }}
                     />
                  </div>
               </div>
               <div>
                  <i>
                  It is an offence under section 91J of the Water Management Act 2000 for a person to make a statement or furnish information in connection with a metering record the person knows to be false or misleading.
                  </i>
               </div>
            </div>
         </div>

         <div className='card mb-3'>
            <div className='card-header fw-bold'>
               Supporting Documentation Checklist
            </div>
            <div className='card-body'>
               <p>
               Attach photos of the meter installation
               </p>
               <p>
               For closed-conduit meters this must include photos of the meter marker plate, meter seals, meter body and pipe, evidence of functioning display/readout panel, a picture of the site which shows the whole installation.
               </p>
               <p>
               For open-channel meters this must include photos of the sensor location, the channel looking upstream and downstream, evidence of functioning display/readout panel, a picture of the site which shows the whole installation.
               </p>

               <p className='fw-bold'>The following supporting information is mandatory and must be loaded into the DQP portal: </p>

               <div className='mb-3 ps-2'>
                  {uploadOptions.map((option) => {
                     if(option.name=='Other') return null;
                     return <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id={"checklist_" + option.id} checked={props.documents.filter(doc=>( (doc.status=='new' || doc.status=='existing' ) && doc.documentType == option.id)).length > 0} />
                        <label class="form-check-label" for={"checklist_" + option.value}>
                           {option.name}
                        </label>
                     </div>
                  })}
               </div>

               <div className='row mb-3 mt-4'>
                  <div className='col-sm-6'>
                     <Field
                        name="meteringEquipmentInformation.fileType"
                        component={SelectForRedux}
                        props={{ disabled: disableFields }}
                        value="3"
                        options={uploadOptions} 
                     />
                  </div>
               </div>
               <FileUploader
                  fileInputLabel={<strong>Upload your supporting documents here:</strong>}
                  fileTableHeader={<p><strong>Your uploaded files: </strong></p>}
                  warning={MESSAGE_UPLOAD_LIMITS}
                  maxFileSizeMB={10}
                  exceedFileSizeErrorMsg = "Your file is too big"
                  disabled={disableFields}
                  provideDisplayDocumentsUpdator={(updateFunction)=>{fileUploaderDisplayListUpdate = updateFunction;}}

                  fileTableHeadComponent={<tr>
                     <th style={{width:"80px"}}></th>
                     <th style={{width:"280px"}}>Type</th>
                     <th>File name</th>
                     <th>Description</th>
                  </tr>}

                  fileTableRowCompponent={(file, index, deleteButton)=>{
                     return <tr key={index}>
                        <td>
                           {deleteButton}
                        </td>
                        <td>
                           {file.fileTypeDesc}
                        </td>
                        <td>
                           <a target='_blank' href={process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_FIELS_GET + "/" + file.id}>
                              {file.fileOriginalName}
                           </a>
                        </td>
                        <td>{file.description}</td>
                  </tr>
                  }}

                  onFilesUploaded={fileUploaderFilesUploaded}
                  askForDocumentUpdate={updateDisplayFileList}
                  onFileDeleted={fileUploaderFileDeleted}
               />
            </div>
         </div>

         {
           registrationDoc.length > 0  &&
            <table id="myGrid" className='table fs-90' >
               {registrationDoc.map((option, index) => (
                  <tr>
                     <td>{option.description}</td>
                     <td>{option.fileOriginalName}</td>
                     <td></td>
                  </tr>
               ))}
            </table>
         }

      {hasError && <div className='alert alert-danger'>
         Please fix all errors before you continue
      </div>}

      <div className="text-end mt-4">
        <button type="button" class="btn btn-primary float-start" data-bs-toggle="modal" data-bs-target="#checkListModal" disabled={loading}>
            View Checklist
        </button>
        {(parseInt(meterEquipInfo.formStatus) == 1) && (!props.disableEditing) && <>
            {meterEquipInfo.id!=0 && <button type="button"  
              className="btn btn-danger me-3" disabled={loading} 
              data-bs-toggle="modal" data-bs-target="#deleteDraftModal">
              Delete Draft
            </button>}
            {/* 
            <button type="button"  className="btn btn-primary" disabled={loading}
              onClick={() => { saveDraftAction(props); }}>
              Save Draft
            </button>
            */}
            <div className='vr mx-3' />
        </>}
        <button type="button"  className="btn btn-warning me-3" 
            disabled={loading}
            onClick={()=>{previousPage(meterEquipInfo.formStatus)}}>
            Previous
        </button>
        {/*(parseInt(meterEquipInfo.formStatus) == 1) && (!props.disableEditing) &&<button type="button"  className="btn btn-success" disabled={loading}
            onClick={() => { saveSubmitAction(props);}}>
            Submit
        </button>*/}
      </div>
      </form>
   )
}

MeterValidationCertificatePartF = reduxForm({
   destroyOnUnmount: false,
   forceUnregisterOnUnmount: true,
   validate : validateAll,
   onSubmitFail: (errors, dispatch ) => {
      dispatch(change('certificate', 'hasError', true));
   },
   onSubmitSuccess:(result , dispatch )=>{
      dispatch(change('certificate', 'hasError', false));
      window.scrollTo(0, 0);
   }
})(MeterValidationCertificatePartF)

MeterValidationCertificatePartF = connect(
   (state, props) => {
      const selectorCert = formValueSelector('certificate')
      const hasError = selectorCert(state, 'hasError')

      // can select values individually
      const selector = formValueSelector(props.form)
      const userProfileValues = selector(state, 'mdata.userProfile')
      const meterEquipInfo = selector(state, 'meteringEquipmentInformation')
      const documents = selector(state, 'documents')
      const registrationDoc = selector(state, 'registrationDoc')
      const masterDataValues = selector(state, 'mdata')
      const loading = selector(state, 'loading')
      const form = props.form
      return {
         userProfileValues,
         meterEquipInfo,
         documents,
         registrationDoc,
         masterDataValues,
         loading,
         form,
         hasError
      }
   }, {
   uploadFiles: (formData) => uploadFiles(formData),
   updateDeleteDocuments: (doc) => updateDeleteDocuments(doc),
   changeFormStatus: (payload, val) => changeFormStatus(payload, val),
   updateDocuments: (payload) => updateDocuments(payload)
}
)(MeterValidationCertificatePartF)

export default MeterValidationCertificatePartF



