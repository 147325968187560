import { SECONDARY_METER_VALIDATE_URL,REF_DATA_BY_MODULE_URL } from '../../../constants/MeterFormConstants';
import axios from 'axios';
import dispatch from 'redux';
import Authentication from '../../../helpers/Authentication';
const authentication = new Authentication();
const LOAD = '[Certificate] LOAD'
const SAVE = '[Certificate] SAVE'
const SEACRH_METER_DESIGN = '[Certificate] SEARCH'
const UPDATE_METER_DESIGN = '[Certificate] UPDATE'
const CHECK_METER_SERIALNUMBER = '[Certificate] SERIALNUMBER'
const POST_SAVE = '[Certificate] POST_SAVE'
let initial = {
  loaded: false,
  searchForm: {
    workApprovalNo: "",
    holderName: "",
    isMatched: false,
    authorityHolderLastName: "",
    organisation: "",
    isCustomerConsent: false,
    customerEmail: ''
  }, 
   clickNext: 0,
  clickSaveDraft: 1,
  authorityHolderDetails: {
    id: 0,
    holderType : '',
    salutationId: 0,
    salutationText: '',
    authorityHolderName: '',
    corporationNameOrAcn: '',
    postalAddress: '',
    phone: '',
    alternatePhone: '',
    emailAddress: '',
    contactPersonName: '',
    contactPersonPhone: '',
    contactPersonEmailAddress: '',
    firstName:'',
    lastName:''
  },  
  floodplainWorkDetails: {
    id: 0,    
    propertyName: '',
    lotDp: '',    
    waterAccessLicenceNo: '',   
    approvedByCustomer: 0 ,
    //storageIdentifyingNumber: '',
    ratingTable : {
      id : 0,
      roiNumber : '',
      ratingTableName : '',
      activityNumber : '',
      ofsReference : '',
      storageWorkApprovalNumber : ''
    }
  },
  secondaryEquipmentValidationData: {
    id:0,
    updateReason: 0,
    updateDescription: '',
    surveyType: '',
    surveyDate: undefined,
    validationDate: undefined,
    certificateDate: undefined,
    createdBy: undefined,
    modifiedBy: undefined,
    confirmCert: 1,    
    sealNos: [],
    sealNo: "",
    formStatus: 1,
    fileType : 0, //not to be added in db
    loadRatingTableContents: false
  },    
  mdata: [{}],
  documents: []  
}

const sevReducer = (state = initial, action) => {
  console.log('---------sevReducer action--------' + JSON.stringify(action));
  
  switch (action.type) {
    case LOAD:
      console.log("in dispatch LOAD. data is" + JSON.stringify(action.data.data));
      return {        
        ...state,
        loaded: true,
        mdata: action.data.data,             
      }
      case SAVE:
        return state;

      case POST_SAVE:        
      return {
        ...state,
        id: action.data.benchmarkDetailsId
      }

    case SEACRH_METER_DESIGN:
      console.log("in dispatch SEACRH_METER_DESIGN. data is" + JSON.stringify(action.data.payload,null,2));
      return {
        ...state,
        loaded: action.data.payload.loaded,
        mdata: action.data.payload.mdata,
        authorityHolderDetails: action.data.payload.authorityHolderDetails,       
        documents : action.data.payload.documents,           
        secondaryEquipmentValidationData: action.data.payload.secondaryEquipmentValidation,
         
        floodplainWorkDetails: action.data.payload.floodplainWorkDetails,     
        ratingTableContents: action.data.payload.ratingTableContents
      }

    case UPDATE_METER_DESIGN:
      return state;
    case CHECK_METER_SERIALNUMBER:
      return state;
    default:
      return state;
  }
  return state;
}

const CERT_MODULE = 'SecondaryEquipmentValidation'; 
export const load = (payload) => {  
  const options = {    
    url: process.env.REACT_APP_WEBAPI_URL + REF_DATA_BY_MODULE_URL+ CERT_MODULE,

    method: 'GET',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': 'Bearer ' + authentication.getToken()
    },
    data: {
      payload
    }
  };

  return async (dispatch) => {    
    console.log("url  in sevCert/Reducers" + JSON.stringify(options));
    const response = await axios(options);
    const data = await response.data;
    console.log("printing in sevCert/Reducers");   
    console.log(JSON.stringify(data));
    dispatch({ type: LOAD, data });
  }
}
export const save = (payload, callback) => {
  
  console.log("data sent to save" + JSON.stringify(payload,null,2));
  debugger;
  const options = {
    url: process.env.REACT_APP_WEBAPI_URL + SECONDARY_METER_VALIDATE_URL,
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': 'Bearer ' + authentication.getToken()
    },
    data: {
      payload
    }
  };
  return async (dispatch) => {
    try {       
    const response = await axios(options);   
    const data = await response.data;
    console.log("save response" + JSON.stringify(data));
     if (data.success) {
        if(callback){
          callback(data.id);
        } else {
          document.location.href = "/SecondaryEquipmentValidation?id=" + data.id;
        }
      }
     else {
        alert("Error in saving Data");
    }   
     dispatch({type: SAVE});

    }
    catch (error) {
      alert("Errors in saving Data"+ error);
    }

  }
}

export const findById = (id) => {

  const options = {
    url: process.env.REACT_APP_WEBAPI_URL + SECONDARY_METER_VALIDATE_URL + id,
    method: 'GET',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': 'Bearer ' + authentication.getToken()
    }
  };
  return async (dispatch) => {
    try
    {
      const response = await axios(options);
      const data = await response.data;
      debugger;
      console.log("printing values");
      console.log(JSON.stringify(data,null,2));
      if(!data.success)
      {
        if(data.message != undefined && data.message != "")
          alert(data.message);
        else if(data.noPermission)
          alert('Access denied.');
        else
          alert("Error in loading Data");
      }
      else
      {       
        if(data.payload.secondaryEquipmentValidation.sensorInstallationDate) 
          data.payload.secondaryEquipmentValidation.sensorInstallationDate = new Date(data.payload.secondaryEquipmentValidation.sensorInstallationDate);
        if(data.payload.secondaryEquipmentValidation.siteCommissioningDate) 
          data.payload.secondaryEquipmentValidation.siteCommissioningDate = new Date(data.payload.secondaryEquipmentValidation.siteCommissioningDate);
        if(data.payload.secondaryEquipmentValidation.dateSensorLevelledIn) 
          data.payload.secondaryEquipmentValidation.dateSensorLevelledIn = new Date(data.payload.secondaryEquipmentValidation.dateSensorLevelledIn);
        dispatch({type: SEACRH_METER_DESIGN, data });
      }
      debugger;
    }
    catch(error)
    {
      
      if(!error.success)
      {
        if(error.message != undefined && error.message != "")
        {
          alert(error.message);
        }
      }
    }
    
    
  }
}


export default sevReducer