import React from 'react';
import axios from 'axios';

import * as EmailValidator from 'email-validator';
import uiHelpers from '../../helpers/uiHelpers'
import Authentication from '../../helpers/Authentication'
import InstallationTypeModal from '../../components/meterRegistration/InstallationTypeModal';
import FileUploader from '../../components/common/FileUploader'
import Audit from '../../components/meterRegistration/Audit'
import Admin from '../../components/meterRegistration/Admin'
import MultipleMeterLIDSelection from '../../components/meterRegistration/MultipleMeterLIDSelection'
import DisplayForRoles from '../../components/tools/DisplayForRoles'

import $ from 'jquery';
import { POST_CODE } from '../../constants/MeterFormConstants'
import ApprovalSearchFormWithConsent from '../../components/common/ApprovalSearchFormWithConsent'
import EsidSelect from '../../components/common/EsidSelect'
import MeterSelectForEsid from '../../components/common/MeterSelectForEsid'
import RegistrationFormReadOnly from '../../components/meterRegistration/RegistrationFormReadOnly'
import RegistrationApiHelpers from '../../helpers/RegistrationApiHelpers'
import { telemetryReplacementSelectOptions } from '../../constants/TelemetryReplacementConstants'

import { ReactSelect, InputText, Popover, InputTextWithEndAddOn } from '../../components/tools/BootstrapControls'
import { QuestionFill } from '../../components/tools/Icons'

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { WebMapViewActualLocation } from '../../components/tools/DQP.Map';

import LIDSection from '../../components/meterRegistration/LIDSection';
import ReApplyConsentNotice from '../../components/common/ReApplyConsentNotice'


import { userRole, addUserRoleUpdateNotifier } from '../../helpers/userHelpers';


const authentication = new Authentication();

const FIELD_ERRORS_INITIALISATION = {
   installationType: null,
   workApproval: null,
   linkedAl: null,
   esid: null,
   replaceMeterNumber: null,
   primaryHolder: null,
   meterDescription: null,
   serialNumber: null,
   holderType: null,
   holderSurname: null,
   holderState: null,
   holderPhone: null,
   holderEmail: null,
   holderComments: null,
   holderAddress: null,
   holderSuburb: null,
   holderState: null,
   holderPostcode: null,
   meterScalingFactor: null,
   replaceManufacturerAndModel: null,
   replaceMeterSize: null,
   replaceSerialNumber: null,
   replaceMeterNumber: null,
   meterType: null,
   manufacturerAndModel: null,
   otherManufacturerAndModelText: null,
   meterSize: null,
   meterUnitOfMeasures: null,
   meterFlowUnits: null,
   meterScalingFactor: null,
   serialNumber: null,
   latitude: null,
   longitude: null,
   telemetryType: null,
   lid: null,
   isLidTelemetry: null,
   depthSesorLevel: null,
   storageDepthSensorTypeId: null,
   replaceStorageDepthSensorTypeId: null,
   dateOfInstallation: null,
   meterReadingAtInstallation: null,
   isFirstMeterForLID: null,
   meterRegistrationLIDId: null
};

const filterPassedTime = (time) => {
   const currentDate = new Date();
   const selectedDate = new Date(time);
   return currentDate.getTime() >= selectedDate.getTime();
};


class RegistrationFormBase extends React.Component {

   config = {
      baseURL: process.env.REACT_APP_WEBAPI_URL,
      headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authentication.getToken() }
   };

   #currentUserEmail;
   #currentUserId;
   #meterRegistrationIdFromUrl;

   installationTypes = [];
   telemetryTypes = [];
   meterUnitOfMeasures = [];
   meterflowUnits = [];
   meterTypes = [];
   billingFlowUnits = [];
   manufacturerModels = [];
   lids = [];
   states = [
      { label: 'NSW', value: 'NSW' },
      { label: "VIC", value: "VIC" },
      { label: "QLD", value: "QLD" },
      { label: "TAS", value: "TAS" },
      { label: "WA", value: "WA" },
      { label: "SA", value: "SA" },
      { label: "NT", value: "NT" },
      { label: "ACT", value: "ACT" }
   ];
   formStatus = [{ label: 'OPEN', value: 0 }, { label: 'SUBMITTED', value: 1 }, { label: 'Installed', value: 2 }, { label: 'Removed', value: 3 }];
   //this dictionary controls length validations. We only put fields we want validated. others remain untouched.
   lengthDictionary = {
      workApproval: { title: "Work Approval Number", len: 10 },
      linkedAl: { title: "Water Access License", len: 10 },
      primaryHolder: { title: "Primary Holder First Name/Organisation", len: 250 },
      meterDescription: { title: "Meter Description", len: 500 },
      serialNumber: { title: "Meter Serial Number", len: 20 },
      holderSurname: { title: "Primary Holder Surname", len: 50 },
      holderPhone: { title: "Primary Holder Phone", len: 20 },
      holderEmail: { title: "Email Address", len: 100 },
      holderComments: { title: "Primary Holder Comments", len: 500 },
      holderAddress: { title: "Address", len: 200 },
      holderSuburb: { title: "Suburb", len: 200 },
      holderPostcode: { title: "Postcode", len: 10 },
      meterScalingFactor: { title: "Meter scaling / calibration factor", len: 15 }
   };

   requiredFiledOnSave = [
      "installationType",
   ];

   requiredFieldOnSubmission = [
      "installationType"
      , "primaryHolder"
      , "holderEmail"
      , "holderType"
      , "holderAddress"
      , "holderPhone"
      , "holderPostcode"
      , "holderSuburb"
      , "holderState"
      , "holderEmail"
      , "manufacturerAndModel"
      , "latitude"
      , "longitude"
      , "telemetryType"
      , "lid"
   ];

   pageStages = {
      searchForm: () => "SEARCH_FORM",
      loadExisting: () => "LOAD_EXISTING",
      infoPage: () => "INFO_PAGE",
      mainForm: () => "MAIN_FORM",
      confirmation: () => "CONFIRMATION"
   }

   constructor(props) {
      super(props);

      addUserRoleUpdateNotifier(() => {

         if (userRole.isSuperAdmin) {
            this.state.displayControl.needReApplyConsent = false;
            this.state.displayControl.lock = false;
            this.forceUpdate();
         }


      });

      this.#currentUserEmail = authentication.currentUser().emails;
      this.childRef = React.createRef(null);
      //this.onSubmit = this.onSubmit.bind(this);

      let search = window.location.search;
      let params = new URLSearchParams(search);
      this.#meterRegistrationIdFromUrl = params.get('id');

      this.registrationApiHelpers = new RegistrationApiHelpers(authentication.getToken());
      axios.defaults.preflightCheck = true;

      this.state = {
         displayControl: {
            isInstalledVisible: false,
            showOtherManufactuerModel: false,
            showReplaceOtherManufactuerModel: false,
            requestingReadyForSetToInstallStatus: false,
            lock: false,
            needReApplyConsent: false,
            pageStage: this.#meterRegistrationIdFromUrl ? this.pageStages.loadExisting() : this.pageStages.searchForm(),
            hideReplacementDetails: true,
            tab: 'MAIN'
         },

         fieldErrors: Object.assign({}, FIELD_ERRORS_INITIALISATION),

         lidOptions: [],

         dqp: null,

         meterRegistration: {
            id: null,
            registrationNo: null,
            formStatus: 0,
            workApproval: null,
            primaryHolder: null,
            esid: null,
            linkedAl: null,
            description: null,
            installationType: null,
            meterType: null,
            meterNumber: null,
            meterSize: null,
            meterUnitOfMeasures: null,
            meterFlowUnits: null,
            telemetryType: null,
            lid: null,
            isLidTelemetry: null,
            latitude: -32,
            longitude: 154,
            serialNumber: null,
            meterDescription: null,
            billingPeriod: 1,//11771 - Remove LID billing fields from Registration form
            billingDataAllowance: 1,//11771 - Remove LID billing fields from Registration form
            holderSurname: null,
            holderPhone: null,
            holderEmail: null,
            holderComments: null,
            holderAddress: null,
            holderSuburb: null,
            holderState: null,
            holderPostcode: null,
            meterScalingFactor: null,
            manufacturerAndModel: null,
            otherManufacturerAndModelText: null,
            meterId: null,
            meterSiteId: null,
            meterNumberGUID: null,
            documents: [],
            replaceMeterNumber: null,
            replaceMeterSize: null,
            replaceSerialNumber: null,
            replaceManufacturerAndModel: null,
            replaceOtherManufacturerAndModelText: null,
            replaceMeterDescription: null,
            replacementStartDate: null,
            ratingTableId: null,
            storageMeasurementMethodId: null,
            storageDepthSensorTypeId: null,
            replaceStorageDepthSensorTypeId: null,
            holderType: null,
            depthSesorLevel: null,
            dateOfInstallation: null,
            meterReadingAtInstallation: null,
            noOfWorks: null,
            worksId: null,
            isFirstMeterForLID: null,
            meterRegistrationLIDId: null
         }
      };

   }

   async componentDidMount() {

      this.retrieveInstallationTypes();
      this.retrieveTelemetryTypes();
      this.retrieveMeterUnitOfMeasures();
      this.retrieveMeterflowUnits();
      this.retrieveMeterTypes();
      this.retrieveBillingFlowUnits();



      const currentContext = this;
      var data = { email: this.#currentUserEmail };

      axios.post('users/getprofile', JSON.stringify(data), this.config).then(response => {
         this.#currentUserId = response.data.data.user.user.id;
         if (this.#meterRegistrationIdFromUrl != null) {
            uiHelpers.showProgressOnTop("Loading registration record...")
            currentContext.retrieveRegistrationRecord(this.#meterRegistrationIdFromUrl, async () => {
               await this.renderManufacturerModelsTypes();
               await this.renderLids();
               if (this.#meterRegistrationIdFromUrl) this.setState(Object.assign(this.state.displayControl, { pageStage: currentContext.pageStages.mainForm() }))
               uiHelpers.hideProgressOnTop();
            });
         } else {
            this.setState({ dqp: response.data.data.user.user });
            this.renderManufacturerModelsTypes();
            this.renderLids();
         }
      }).catch(function (error) {
         console.log(error);
         uiHelpers.showErrorTop('Error occurred when reading your user info');
      });
   }

   validateRequiredFieldsOnSave = () => {
      var currrentContext = this;
      this.requiredFiledOnSave.map(fieldName => {
         currrentContext.validateFieldIfNoPreviousError(fieldName, () => {
            if (!currrentContext.state.meterRegistration[fieldName] || $.trim(currrentContext.state.meterRegistration[fieldName]) == '') return "This field is required";
         });
      });
   }

   validateRequiredFieldsOnSubmission = () => {
      var currrentContext = this;
      this.requiredFieldOnSubmission.map(fieldName => {
         currrentContext.validateFieldIfNoPreviousError(fieldName, () => {
            if (!currrentContext.state.meterRegistration[fieldName] || $.trim(currrentContext.state.meterRegistration[fieldName]) == '') return "This field is required";
         });
      });

      if (this.isLIDForMultipleMeters()) {
         currrentContext.validateFieldIfNoPreviousError("isFirstMeterForLID", () => {
            if (currrentContext.state.meterRegistration.isFirstMeterForLID == null) return "This field is required";
         });
      }
   }

   hasValidationError = () => {
      var hasError
      Object.keys(this.state.fieldErrors).map(key => {
         if (this.state.fieldErrors[key]) {
            hasError = true;
         }
      });
      return hasError;
   }

   validateLengths = () => {
      var currentContext = this;
      Object.keys(this.lengthDictionary).map(key => {
         let fieldValue = this.state.meterRegistration[key];
         let fieldLength = this.lengthDictionary[key].len;
         currentContext.validateFieldIfNoPreviousError(key, () => {
            if (!currentContext.validateLength(fieldValue, fieldLength)) return "Length exceeded for " + currentContext.lengthDictionary[key].title + " Only " + currentContext.lengthDictionary[key].len + " characters are allowed";
         });
      });
   }

   //Map start



   updateMapPoints = () => {
      debugger;
      if (this.state.meterRegistration.longitude != null && this.state.meterRegistration.latitude != null)
         this.childRef.current.updateActualMapPoints({ longitude: this.state.meterRegistration.longitude, latitude: this.state.meterRegistration.latitude });
   }

   updatePoints = (x, y) => {
      this.setState(Object.assign(this.state.meterRegistration, { longitude: parseFloat(x).toFixed(7), latitude: parseFloat(y).toFixed(7) }));
   }

   //Map end




   validateLength(fieldValue, fieldLength) {

      if (fieldValue == null) {
         return true; //field wasnt even visible or user did not enter a value yet
      }

      let fieldValueString = fieldValue.toString();
      if (fieldValueString.trim().size == 0) {
         return true; // user entered one or more blank space
      }

      //console.log("compare "+ fieldValueString.length+ " " + fieldLength);
      if (fieldValueString.length > fieldLength) {
         return false; // the real length check
      }

      return true; // all is well
   }

   confirm = (e) => {
      e.preventDefault();
      if (this.validateForSubmission())
         this.setState(Object.assign(this.state.displayControl, { pageStage: this.pageStages.confirmation() }));

   }

   saveForm = (e) => {
      e.preventDefault();

      if (this.state.meterRegistration.formStatus == 1) {
         uiHelpers.showErrorTop('This form has been submitted, please refresh the page', true, false);
         return;
      }

      this.state.fieldErrors = Object.assign({}, FIELD_ERRORS_INITIALISATION);
      this.validateRequiredFieldsOnSave();
      this.validateLengths();
      this.validateFieldsOnSave();
      if (this.hasValidationError()) {
         console.log(this.state.fieldErrors);
         return;
      }

      //new registration
      if (this.state.meterRegistration.registrationNo == null) {
         this.state.meterRegistration.createdDate = new Date();
         this.state.meterRegistration.formStatus = 0;
      }

      this.preprocessOnSave();

      if (this.state.meterRegistration.latitude != null) {
         this.state.meterRegistration.latitude = parseFloat(this.state.meterRegistration.latitude);
      }

      if (this.state.meterRegistration.longitude != null) {
         this.state.meterRegistration.longitude = parseFloat(this.state.meterRegistration.longitude);
      }

      if (this.state.meterRegistration.meterScalingFactor != null) {
         this.state.meterRegistration.meterScalingFactor = parseFloat(this.state.meterRegistration.meterScalingFactor);
      }

      this.state.meterRegistration.modifiedDate = new Date();
      const currentContext = this;


      uiHelpers.showProgressOnTop("Saving registration record...");
      currentContext.setState(Object.assign(currentContext.state.displayControl, { lock: true }));
      axios.post('/meterregistration', JSON.stringify(this.state.meterRegistration), this.config).then(function (response) {
         currentContext.retrieveRegistrationRecord(response.data.data.id, () => {
            uiHelpers.showSuccessMsgTop("Registration saved successfully", false, 2500);
            currentContext.setState(Object.assign(currentContext.state.displayControl, { lock: false }));
         });
      }).catch(function (error) {
         console.log(error);
         currentContext.setState(Object.assign(currentContext.state.displayControl, { lock: false }));
         uiHelpers.showErrorTop('Error attempting to save registration.');
      });

   }

   validateForSubmission = () => {
      this.state.fieldErrors = Object.assign({}, FIELD_ERRORS_INITIALISATION);
      this.validateRequiredFieldsOnSubmission();
      this.validateLengths();




      if (this.state.meterRegistration.holderType == 'INDIVIDUAL') {
         this.validateFieldIfNoPreviousError("holderSurname", () => {
            if (!this.state.meterRegistration.holderSurname || $.trim(this.state.meterRegistration.holderSurname) == '') return "This field is required";
         });
      }
      this.validateFieldIfNoPreviousError("latitude", () => {
         if (this.state.meterRegistration.latitude != null && !this.validateLatitude(this.state.meterRegistration.latitude)) return "Please enter valid latitude value between -37.50538547 and -28.15709423";
      });
      this.validateFieldIfNoPreviousError("longitude", () => {
         if (this.state.meterRegistration.longitude != null && !this.validateLongitude(this.state.meterRegistration.longitude)) return "Please enter valid longitude value between 140.9953061 and 153.6387301";
      });
      this.validateFieldIfNoPreviousError("holderPostcode", () => {
         if (this.state.meterRegistration.holderPostcode != null && !POST_CODE.test(this.state.meterRegistration.holderPostcode)) return "Post code must contain 4 digits. Alphabets, spaces or characters are not allowed";
      });
      this.validateFieldIfNoPreviousError("holderEmail", () => {
         if (this.state.meterRegistration.holderEmail != null && !EmailValidator.validate(this.state.meterRegistration.holderEmail)) return "Please enter a valid customer\'s current email address for the Holder";
      });
      this.validateFieldIfNoPreviousError("meterScalingFactor", () => {
         if (!this.validateScalingFactor(this.state.meterRegistration.meterScalingFactor)) return 'Please enter a valid Meter Scaling / calibration factor';
      });
      this.validateFieldIfNoPreviousError("isLidTelemetry", () => {
         if (this.state.meterRegistration.isLidTelemetry == null) {
            return "This field is required";
         } else {
            if (this.state.meterRegistration.isLidTelemetry == false && this.state.meterRegistration.lid) {
               const cContext = this;
               let selectedLid = cContext.lids.find(lid => lid.value == cContext.state.meterRegistration.lid);
               let selectedLidName = selectedLid?.name;
               if (selectedLidName && !(selectedLidName == 'ydoc_64_bit' || selectedLidName == 'ydoc')) {
                  return "The selected LID does not support a Non-Telemetry setup"
               }
            }
         }

      });



      if (this.state.meterRegistration.installationType == 4 || this.state.meterRegistration.installationType == 5) {
         if (this.displayOldMeterNumber()) {
            this.validateFieldIfNoPreviousError("replaceMeterNumber", () => {
               if (!this.state.meterRegistration.replaceMeterNumber || $.trim(this.state.meterRegistration.replaceMeterNumber) == '') return "This field is required";
            });
         }

         if (this.displayMeterSize() && this.state.meterRegistration.installationType == 4) {
            this.validateFieldIfNoPreviousError("replaceMeterSize", () => {
               if (!this.state.meterRegistration.replaceMeterSize || $.trim(this.state.meterRegistration.replaceMeterSize) == '') return "This field is required";
               if (!/^\d+$/.test($.trim(this.state.meterRegistration.replaceMeterSize))) return 'Please enter a valid meter size (between 10 and 9999, no decimals values allowed)';
               var size = parseInt(this.state.meterRegistration.replaceMeterSize);
               if (size < 10 || size > 9999) return 'Please enter a valid meter size (between 10 and 9999, no decimals values allowed)';
            });
         }
      }
      if (this.state.meterRegistration.installationType == 4 && this.displayMeterDetails()) {
         this.validateFieldIfNoPreviousError("replaceManufacturerAndModel", () => {
            if (!this.state.meterRegistration.replaceManufacturerAndModel || $.trim(this.state.meterRegistration.replaceManufacturerAndModel) == '') return "This field is required";
         });
         if (this.state.displayControl.showReplaceOtherManufactuerModel) {
            this.validateFieldIfNoPreviousError("replaceOtherManufacturerAndModelText", () => {
               if (!this.state.meterRegistration.replaceOtherManufacturerAndModelText || $.trim(this.state.meterRegistration.replaceOtherManufacturerAndModelText) == '') return "This field is required";
            });
         }
      }
      if (this.displayMeterDetails() && this.displayMeterType()) {
         this.validateFieldIfNoPreviousError("meterType", () => {
            if (!this.state.meterRegistration.meterType || $.trim(this.state.meterRegistration.meterType) == '') return "This field is required";
         });
      }
      if (this.state.displayControl.showOtherManufactuerModel) {
         this.validateFieldIfNoPreviousError("otherManufacturerAndModelText", () => {
            if (!this.state.meterRegistration.otherManufacturerAndModelText || $.trim(this.state.meterRegistration.otherManufacturerAndModelText) == '') return "This field is required";
         });
      }


      if (this.displayMeterSize()) {
         this.validateFieldIfNoPreviousError("meterSize", () => {
            if (!this.state.meterRegistration.meterSize || $.trim(this.state.meterRegistration.meterSize) == '') return "This field is required";
            if (!/^\d+$/.test($.trim(this.state.meterRegistration.meterSize))) return 'Please enter a valid meter size (between 10 and 9999, no decimals values allowed)';
            var size = parseInt(this.state.meterRegistration.meterSize);
            if (size < 10 || size > 9999) return 'Please enter a valid meter size (between 10 and 9999, no decimals values allowed)';
         });
      }
      if (this.displayMeterUnitOfMeasures()) {
         if (this.state.meterRegistration.formStatus != 1) {
            this.validateFieldIfNoPreviousError("meterUnitOfMeasures", () => {
               if (!this.state.meterRegistration.meterUnitOfMeasures || $.trim(this.state.meterRegistration.meterUnitOfMeasures) == '') return "This field is required";
            });
         }
      }
      if (this.displayMeterUnits()) {
         this.validateFieldIfNoPreviousError("meterFlowUnits", () => {
            if (!this.state.meterRegistration.meterFlowUnits || $.trim(this.state.meterRegistration.meterFlowUnits) == '') return "This field is required";
         });
      }
      if (this.displayScallingFactor()) {
         this.validateFieldIfNoPreviousError("meterScalingFactor", () => {
            if (!this.state.meterRegistration.meterScalingFactor || $.trim(this.state.meterRegistration.meterScalingFactor) == '') return "This field is required";
         });
      }

      if (this.state.meterRegistration.formStatus == 0) {
         if (this.state.meterRegistration.isFirstMeterForLID === false) {
            this.validateFieldIfNoPreviousError("meterRegistrationLIDId", () => {
               if (!this.state.selectedMeterRegistrationLID) return "Please select a previous submitted LID";
               if (this.state.selectedMeterRegistrationLID.submittedMeterRegistrations.length >= 4) return "You exceeded the maximum number of meters (four) allowed to connect to this LID. Please use a different LID.";
            });
         }
      }


      if (this.state.meterRegistration.formStatus == 1 && this.state.displayControl.isInstalledVisible) {
         this.validateFieldIfNoPreviousError("dateOfInstallation", () => {
            if (!this.state.meterRegistration.dateOfInstallation) return "This field is required";
         });
         this.validateFieldIfNoPreviousError("meterReadingAtInstallation", () => {
            if ((!this.state.meterRegistration.meterReadingAtInstallation) || $.trim(this.state.meterRegistration.meterReadingAtInstallation) == '') return "This field is required";
            if (!/^\d+(\.\d{1,5})?$/.test(this.state.meterRegistration.meterReadingAtInstallation)) return "'Meter Reading At Installation' must be a number with 5 or less decimals";
         });
      }

      this.validateFieldsOnSubmit();
      return !this.hasValidationError();
   }

   submitOrSetToInstall = (e) => {
      e.preventDefault();

      if (!this.validateForSubmission()) {
         this.setState(Object.assign(this.state.displayControl, { pageStage: this.pageStages.mainForm() }));
         return;
      }

      //Begin pre-process 
      if (this.state.meterRegistration.id == null) {
         this.state.meterRegistration.createdDate = new Date();
         this.state.meterRegistration.formStatus = 1;
      }

      if (!this.state.displayControl.isInstalledVisible) {
         this.state.meterRegistration.formStatus = 1;
      } else {
         this.state.meterRegistration.formStatus = 2;
         this.state.meterRegistration.installationDate = new Date();
      }

      if (this.state.meterRegistration.latitude != null) {
         this.state.meterRegistration.latitude = parseFloat(this.state.meterRegistration.latitude);
      }

      if (this.state.meterRegistration.longitude != null) {
         this.state.meterRegistration.longitude = parseFloat(this.state.meterRegistration.longitude);
      }

      if (this.state.meterRegistration.meterScalingFactor != null) {
         this.state.meterRegistration.meterScalingFactor = parseFloat(this.state.meterRegistration.meterScalingFactor);
      }
      this.state.meterRegistration.modifiedDate = new Date();
      this.preprocessOnSubmit();
      // End : pre process

      const currentContext = this;
      currentContext.setState(Object.assign(currentContext.state.displayControl, { lock: true }));
      uiHelpers.showProgressOnTop("Submitting registration form...");
      axios.post('/meterregistration', JSON.stringify(this.state.meterRegistration), this.config).then(function (response) {

         currentContext.setState(Object.assign(currentContext.state.displayControl, { lock: false }));

         if (response.data.data.workspaceAlreadyExistsInDAS) {
            uiHelpers.showErrorTop('A workspace and active source for this meter already exists in the DAS. If there is an issue with the current workspace and active source in the DAS it will need to be removed before a new source can be created.\r\nPlease contact us via "Submit an Enquiry" on top right section of every page.'
               , true, false
            );
            currentContext.state.meterRegistration.formStatus = 0;
            return;
         }

         if (response.data.data.errorMsg) {
            uiHelpers.showErrorTop(response.data.data.errorMsg, true, false);
            currentContext.state.meterRegistration.formStatus = 0;
            return;
         }

         //document.location.href = "/" + currentContext.getPageName() + "?id=" + response.data.data.id;
         currentContext.retrieveRegistrationRecord(response.data.data.id, () => {
            uiHelpers.showSuccessMsgTop('Meter registration submitted.', false, 3000);
            currentContext.setState(Object.assign(currentContext.state.displayControl, { lock: false, pageStage: currentContext.pageStages.mainForm() }));
         });
      }).catch(function (error) {
         uiHelpers.showErrorTop('Error attempting to save registration.');
         currentContext.setState(Object.assign(currentContext.state.displayControl, { lock: false }));
      });

   }

   cancelConfirm = () => {
      window.scrollTo(0, 0);
      this.setState(Object.assign(this.state.displayControl, { pageStage: this.pageStages.mainForm() }));
   }


   #fileUploaderDisplayListUpdate;

   updateDisplayFileList = () => {
      if (!this.#fileUploaderDisplayListUpdate) return;
      const newList = [];
      if (this.state.meterRegistration.documents && this.state.meterRegistration.documents.length != 0) {
         for (var i = 0; i < this.state.meterRegistration.documents.length; i++) {
            if (this.state.meterRegistration.documents[i].status == 'new' || this.state.meterRegistration.documents[i].status == 'existing') {
               newList.push({
                  id: this.state.meterRegistration.documents[i].id,
                  description: this.state.meterRegistration.documents[i].description,
                  fileOriginalName: this.state.meterRegistration.documents[i].fileOriginalName,
                  fileGUID: this.state.meterRegistration.documents[i].fileGUID
               });
            }
         }
      }
      this.#fileUploaderDisplayListUpdate(newList);
   }

   fileUploaderFilesUploaded = (files) => {
      const documentType = 1;
      for (var i = 0; i < files.length; i++) {
         this.state.meterRegistration.documents.push({
            id: files[i].id,
            documentType: documentType,
            description: files[i].description,
            fileOriginalName: files[i].fileOriginalName,
            fileGUID: files[i].fileGUID,
            status: "new"
         });
      }
      this.updateDisplayFileList();
   }

   fileUploaderFileDeleted = (file) => {
      if (!this.state.meterRegistration.documents) return;

      for (var i = 0; i < this.state.meterRegistration.documents.length; i++) {
         if (this.state.meterRegistration.documents[i].id == file.id) {
            if (this.state.meterRegistration.documents[i].status == "new")
               this.state.meterRegistration.documents[i].status = "newDeleted";
            else if (this.state.meterRegistration.documents[i].status == "existing")
               this.state.meterRegistration.documents[i].status = "existingDeleted";
         }
      }
      this.updateDisplayFileList();
   }

   retrieveRegistrationRecord = (registrationId, callback) => {

      const currentContext = this;
      axios.get('/meterregistration?meterregistrationid=' + registrationId, this.config).then(response => {

         if(response.data.noPermission) {
            this.setState({noPermission:true});
            uiHelpers.hideProgressOnTop();
            return;
         }

         var data = response.data.data.meterRegistration;
         var selectedMeterRegistrationLID = response.data.data.selectedMeterRegistrationLID;
         currentContext.state.displayControl.lock = this.#currentUserId != data.createdBy.id;

         if (data.formStatus == 0 && !response.data.data.consentValid && !userRole.isSuperAdmin) {
            currentContext.state.displayControl.needReApplyConsent = true;
            currentContext.state.displayControl.lock = true;
         }


         this.retrieveSpecificSelectOptions(data);
         this.setState({ meterRegistration: data, dqp: data.createdBy, selectedMeterRegistrationLID });

         if (data.manufacturerAndModel != null && this.manufacturerModels.length != 0) {
            if (this.manufacturerModels.filter(option => option.value === data.manufacturerAndModel)[0].label == 'Other') {
               this.setState(Object.assign(this.state.displayControl, { showOtherManufactuerModel: true }));
            }
         }

         if (data.replaceManufacturerAndModel != null && this.manufacturerModels.length != 0) {
            if (this.manufacturerModels.filter(option => option.value === data.replaceManufacturerAndModel)[0].label == 'Other') {
               this.setState(Object.assign(this.state.displayControl, { showReplaceOtherManufactuerModel: true }));
            }
         }

         var rowData = [];
         var documentType = 1;
         for (var i = 0; i < response.data.data.documents.length; i++) {
            var row = {};
            row.id = response.data.data.documents[i].id;
            row.documentType = documentType;
            row.description = response.data.data.documents[i].description;
            row.fileOriginalName = response.data.data.documents[i].fileOriginalName;
            row.fileGUID = response.data.data.documents[i].fileGUID;
            row.status = "existing"
            rowData.push(row);
         }
         this.state.meterRegistration.documents = [];
         var docs = this.state.meterRegistration.documents;
         this.setState(Object.assign(this.state.meterRegistration, { documents: docs.concat(rowData) }));
         this.updateDisplayFileList();

         if (this.state.meterRegistration.formStatus == 1 && !this.state.meterRegistration.isLidTelemetry) {
            currentContext.setState(Object.assign(currentContext.state.displayControl, { isInstalledVisible: true, msgForNotAllowToInstall: null }));
            callback();
         } else if (this.state.meterRegistration.formStatus == 1 && data.eagleIoIntegrationStatus == null) {
            currentContext.setState(Object.assign(currentContext.state.displayControl, { msgForNotAllowToInstall: 'DAS site not created' }));
            callback();
         } else if (this.state.meterRegistration.formStatus == 1 && data.eagleIoIntegrationStatus == 0) {
            axios.get('/meterregistration/readyForSetToInstall?meterregistrationid=' + data.id, currentContext.config)
               .then(function (response) {
                  if (response.data.success && response.data.data.canSetToInstalled) {
                     currentContext.setState(Object.assign(currentContext.state.displayControl, { isInstalledVisible: true, msgForNotAllowToInstall: null }));
                     if (response.data.data.lastestReading) {
                        currentContext.setState(Object.assign(currentContext.state, { dasLastestReading: response.data.data.lastestReading, dasLastestReadingUnit: response.data.data.lastestReadingUnit }));
                     }
                  } else if (response.data.success && !response.data.data.canSetToInstalled) {
                     currentContext.setState(Object.assign(currentContext.state.displayControl, { msgForNotAllowToInstall: 'Message from DAS : ' + response.data.data.msgFromDas }));
                  } else {
                     uiHelpers.showErrorTop('We encountered issue when requesting status of whether the meter can be set to installed', true, false);
                  }
                  callback();
               }).catch(function (error) {
                  uiHelpers.showErrorTop('We encountered issue when requesting status of whether the meter can be set to installed', true, false);
                  callback();
               });
         } else {
            callback();
         }

      });
   }

   retrieveInstallationTypes = async () => {
      this.installationTypes = await this.registrationApiHelpers.getInstallationTypeOptions(this.state.meterRegistration.formType, this.state.meterRegistration.storageMeasurementMethodId);
   }

   renderLids = async () => {

      this.lids = await this.registrationApiHelpers.getLidOptions(this.state.meterRegistration.formType, this.state.meterRegistration.storageMeasurementMethodId);
      this.setState(Object.assign(this.state, { lidOptions: this.lids }));
   }

   renderManufacturerModelsTypes = async () => {
      this.manufacturerModels = await this.registrationApiHelpers.getMeterSystemTypeOptions(this.state.meterRegistration.formType, this.state.meterRegistration.storageMeasurementMethodId);
   }

   retrieveTelemetryTypes = async () => {
      this.telemetryTypes = await this.registrationApiHelpers.getTelemetryTypeOptions();
   }

   retrieveMeterUnitOfMeasures = async () => {
      this.meterUnitOfMeasures = await this.registrationApiHelpers.getMeterUnitOfMeasureOptions();
   }

   retrieveMeterflowUnits = async () => {
      this.meterflowUnits = await this.registrationApiHelpers.getMeterFlowUnitOptions();
   }

   retrieveMeterTypes = async () => {
      this.meterTypes = await this.registrationApiHelpers.getMeterTypeOptions();
   }

   retrieveBillingFlowUnits = async () => {
      this.billingFlowUnits = await this.registrationApiHelpers.getBillingFlowUnitOptions();
   }



   onChangeOfInstallationType = (e) => {
      debugger;
      this.setState(Object.assign(this.state.meterRegistration, {
         installationType: e.value
      }));
      this.state.fieldErrors = Object.assign({}, FIELD_ERRORS_INITIALISATION);
      if (this.childRef.current) {
         this.updateMapPoints();
      }
   }

   onReplacementStartDate = (e) => {
      console.log(e);
      this.setState(Object.assign(this.state.meterRegistration, { replacementStartDate: e }));
   }

   onChangeOfLinkedAL = (e) => {
      this.setState(Object.assign(this.state.meterRegistration, { linkedAl: e.target.value }));
   }

   onChangeOfDescription = (e) => {
      this.setState(Object.assign(this.state.meterRegistration, { description: e.target.value }));
   }

   onChangeOfMeterType = (e) => {
      this.setState(Object.assign(this.state.meterRegistration, { meterType: e.value }));
      if (this.displayScallingFactor() && this.state.meterRegistration.meterType == 2) {
         this.setState(Object.assign(this.state.meterRegistration, { meterScalingFactor: 1 }));
      }
   }

   onChangeOfMeterSize = (e) => {
      this.setState(Object.assign(this.state.meterRegistration, { meterSize: e.target.value }));
   }

   onChangeOfReplaceMeterSize = (e) => {
      this.setState(Object.assign(this.state.meterRegistration, { replaceMeterSize: e.target.value }));
   }

   onChangeOfMeterUnitOfMeasures = (e) => {
      this.setState(Object.assign(this.state.meterRegistration, { meterUnitOfMeasures: e.value }));
   }

   onChangeOfMeterFlowUnit = (e) => {
      this.setState(Object.assign(this.state.meterRegistration, { meterFlowUnits: e.value }));
   }

   onChangeOfTelemetryType = (e) => {
      if (e.value == 2 || e.value == "2") {
         this.setState(Object.assign(this.state, { lidOptions: [] }));
         this.setState(Object.assign(this.state.meterRegistration, { lid: null }));
      } else {
         this.setState(Object.assign(this.state, { lidOptions: this.lids }));
      }
      this.setState({
         meterRegistration: Object.assign(this.state.meterRegistration, { telemetryType: e.value, isFirstMeterForLID: null, meterRegistrationLIDId: null, meterRegistrationLID: null })
         , selectedMeterRegistrationLID: null
      });
   }

   onChangeisLidTelemetry = (isLidTelemetry) => {
      this.setState(Object.assign(this.state.meterRegistration, { isLidTelemetry }));
   }

   onChangeIsFirstMeterForLID = (isFirstMeterForLID) => {
      this.setState(Object.assign(this.state.meterRegistration, { isFirstMeterForLID }));
   }

   onChangeOfLID = (e) => {
      this.setState({
         meterRegistration: Object.assign(this.state.meterRegistration, { lid: e.value, isFirstMeterForLID: null, meterRegistrationLIDId: null, meterRegistrationLID: null })
         , selectedMeterRegistrationLID: null
      });
   }

   onChangeOfLatitude = (e) => {
      this.setState(Object.assign(this.state.meterRegistration, { latitude: e.target.value }));
   }

   onChangeOfLongitude = (e) => {
      this.setState(Object.assign(this.state.meterRegistration, { longitude: e.target.value }));
   }

   onChangeOfMeterDescription = (e) => {
      this.setState(Object.assign(this.state.meterRegistration, { meterDescription: e.target.value }));
   }

   onChangeOfReplaceMeterDescription = (e) => {
      this.setState(Object.assign(this.state.meterRegistration, { replaceMeterDescription: e.target.value }));
   }

   onChangeOfSerialNumber = (e) => {
      this.setState(Object.assign(this.state.meterRegistration, { serialNumber: e.target.value }));
   }

   onChangeOfReplaceSerialNumber = (e) => {
      this.setState(Object.assign(this.state.meterRegistration, { replaceSerialNumber: e.target.value }));
   }

   onChangeOfReplaceMeterSize = (e) => {
      this.setState(Object.assign(this.state.meterRegistration, { replaceMeterSize: e.target.value }));
   }

   onChangeOfReplaceMeterNumber = (e) => {
      this.setState(Object.assign(this.state.meterRegistration, { replaceMeterNumber: e.target.value }));
   }

   onChangeOfHolderPhone = (e) => {
      this.setState(Object.assign(this.state.meterRegistration, { holderPhone: e.target.value }));
   }

   onChangeOfHolderEmail = (e) => {
      this.setState(Object.assign(this.state.meterRegistration, { holderEmail: e.target.value }));
   }

   onChangeOfHolderComments = (e) => {
      this.setState(Object.assign(this.state.meterRegistration, { holderComments: e.target.value }));
   }

   onChangeOMeterScalingFactor = (e) => {
      this.setState(Object.assign(this.state.meterRegistration, { meterScalingFactor: e.target.value }));
   }

   onChangeOfManufacturerModel = (e) => {
      this.setState(Object.assign(this.state.meterRegistration, {
         manufacturerAndModel: e.value,
         otherManufacturerAndModelText: ''
      }));
      this.setState(Object.assign(this.state.displayControl, { showOtherManufactuerModel: e.label == "Other" }));
   }

   onChangeOfReplaceManufacturerModel = (e) => {
      this.setState(Object.assign(this.state.meterRegistration, { replaceManufacturerAndModel: e.value }));
      this.setState(Object.assign(this.state.displayControl, { showReplaceOtherManufactuerModel: e.label == "Other" }));
      if (e.label != "Other") {
         this.setState(Object.assign(this.state.meterRegistration, { replaceOtherManufacturerAndModelText: '' }));
      }
   }

   onChangeOfState = (e) => {
      this.setState(Object.assign(this.state.meterRegistration, { holderState: e.value }));
   }

   onChangeOfAddress = (e) => {
      this.setState(Object.assign(this.state.meterRegistration, { holderAddress: e.target.value }));
   }
   onChangeOfSuburb = (e) => {
      this.setState(Object.assign(this.state.meterRegistration, { holderSuburb: e.target.value }));
   }
   onChangeOfPostcode = (e) => {
      this.setState(Object.assign(this.state.meterRegistration, { holderPostcode: e.target.value }));
   }

   onChangeOfPrimaryHolder = (e) => {
      this.setState(Object.assign(this.state.meterRegistration, { primaryHolder: e.target.value }));
   }

   onChangeOfOtherManufactuerModel = (e) => {
      this.setState(Object.assign(this.state.meterRegistration, { otherManufacturerAndModelText: e.target.value }));
   }

   onChangeOfReplaceOtherManufactuerModel = (e) => {
      this.setState(Object.assign(this.state.meterRegistration, { replaceOtherManufacturerAndModelText: e.target.value }));
   }

   onChangeOfDateOfInstallation = (date) => {
      this.setState(Object.assign(this.state.meterRegistration, { dateOfInstallation: date }));
   }

   onChangeOfMeterReadingAtInstallation = (e) => {
      this.setState(Object.assign(this.state.meterRegistration, { meterReadingAtInstallation: e.target.value }));
   }

   onChangeExistingLidSelected = (lid) => {
      if (lid) {
         this.setState({
            meterRegistration: Object.assign(this.state.meterRegistration, {
               meterRegistrationLIDId: lid.id,
               isLidTelemetry: lid.isLidTelemetry,
               lid: lid.lid,
               meterScalingFactor: lid.meterScalingFactor,
               meterFlowUnits: lid.meterFlowUnits
            }), selectedMeterRegistrationLID: lid
         });
      } else {
         this.setState({
            meterRegistration: Object.assign(this.state.meterRegistration, {
               meterRegistrationLIDId: null,
               isLidTelemetry: null,
               lid: null,
               meterScalingFactor: null,
               meterFlowUnits: null
            }), selectedMeterRegistrationLID: null
         });
      }

   }

   validateFieldIfNoPreviousError = (fieldName, getMsgIfError) => {
      if (this.state.fieldErrors[fieldName]) return;
      var msg = getMsgIfError();

      if (msg) this.setState(Object.assign(this.state.fieldErrors, { [fieldName]: msg }));
      else this.setState(Object.assign(this.state.fieldErrors, { [fieldName]: null }));
   }

   validateLatitude = (latitude) => {
      return latitude >= -37.50538547 && latitude <= -28.15709423;
   }

   validateLongitude = (longitude) => {
      return longitude >= 140.9953061 && longitude <= 153.6387301;
   }


   validateScalingFactor(scalingfactor) {
      if (scalingfactor != null && (typeof (scalingfactor) == 'string' && scalingfactor.trim() == '')) return false;
      if (scalingfactor != null && isNaN(scalingfactor)) {
         return false;
      }
      return true;
   }

   deleteDraft = () => {
      if (this.state.meterRegistration.id != null) {
         let currentContext = this;
         currentContext.setState(Object.assign(this.state.displayControl, { lock: true }));
         axios.post('/meterregistration/deleteDraft', { id: parseInt(this.state.meterRegistration.id) }, this.config)
            .then(function (response) {
               if (response.data.success) {
                  window.location = 'Home';
               } else {
                  uiHelpers.showErrorTop(response.data.message, true, false);
               }
            }).catch(function (error) {
               console.log(error);
               uiHelpers.showErrorTop('Error occurred while deleteing this record', true, false);
            }).then(function () {
               currentContext.setState(Object.assign(this.state.displayControl, { lock: false }));
               $('#deleteDraftModalCloseBtn').trigger('click');
            });
      } else {
         alert('You are accessing this function in a invalid way');
      }
   }

   onChangeOfApproval = (e) => {
      this.setState(Object.assign(this.state.meterRegistration, { workApproval: e.target.value }));
   }

   onChangeOfPrimaryHolderFirstname = (e) => {
      this.setState(Object.assign(this.state.meterRegistration, { primaryHolder: e.target.value }));
   }

   onChangeOfPrimaryHolderSurname = (e) => {
      this.setState(Object.assign(this.state.meterRegistration, { holderSurname: e.target.value }));
   }

   onChangeOfHolderType = (e) => {
      if (e.target.value == "ORGANISATION") {
         this.setState(Object.assign(this.state.meterRegistration, { holderSurname: null }));
      }
      this.setState(Object.assign(this.state.meterRegistration, { holderType: e.target.value }));
   }


   esidChangeHandler = (esid) => {

      this.setState(Object.assign(this.state.meterRegistration, {
         esid: esid.esid,
         noOfWorks: esid.numberOfWorks,
         worksId: esid.workId,
         latitude: esid.esLat,
         longitude: esid.esLong
      }));
      if (this.childRef.current) {
         this.updateMapPoints();
      }
   }

   isLIDForMultipleMeters = () => {
      let lids = this.state.lidOptions.filter(l => l.value == this.state.meterRegistration.lid);
      if (lids.length == 0) {
         return false;
      } else if (lids[0].name == 'flowpro_campbell') {
         return true;
      }
      return false;
   }


   meterChangeHandler = (meter) => {
      if (this.state.meterRegistration.installationType == 5) {
         this.setState(Object.assign(this.state.meterRegistration, { meterNumber: meter.METER_NO }));
      }

      this.state.meterRegistration.meterNumberGUID = meter.GUID;
      var esidMeterSize = meter.SIZE_IN_MM;
      var meterSerialNumber = meter.SERIAL_NUMBER;
      var meterCategoryDesc = meter.METER_CATEGORY_DESC;
      var meterTypeDesc = meter.METER_TYPE_DESCRIPTION;
      var replaceMeterNo = meter.METER_NO;


      var meterTypeModel = "";
      if (meterCategoryDesc != null && meterCategoryDesc != "") {
         meterTypeModel = meterCategoryDesc;
      }

      var meterTypeModelId = this.manufacturerModels.filter(function (option) { if (option.label === meterTypeModel + "") return option });
      if (meterTypeModelId != undefined && meterTypeModelId != null && meterTypeModelId.length > 0) {
         if (this.state.meterRegistration.installationType == 4) {
            this.state.meterRegistration.replaceManufacturerAndModel = meterTypeModelId[0].value;
         } else {
            this.state.meterRegistration.manufacturerAndModel = meterTypeModelId[0].value;
         }
      } else {
         var otherId = this.manufacturerModels.filter(function (option) { if (option.label === "Other") return option });
         if (otherId != undefined && otherId != null && otherId.length > 0) {

            if (this.state.meterRegistration.installationType == 4) {
               this.state.meterRegistration.replaceManufacturerAndModel = otherId[0].value;
               this.setState(Object.assign(this.state.displayControl, { showReplaceOtherManufactuerModel: true }));
               this.setState(Object.assign(this.state.meterRegistration, { replaceOtherManufacturerAndModelText: meterTypeModel }));
            } else {
               this.state.meterRegistration.manufacturerAndModel = otherId[0].value;
               this.setState(Object.assign(this.state.displayControl, { showOtherManufactuerModel: true }));
               this.setState(Object.assign(this.state.meterRegistration, { otherManufacturerAndModelText: meterTypeModel }));
            }
         }
      }

      if (this.state.meterRegistration.installationType == 4) {
         this.setState(Object.assign(this.state.meterRegistration, { replaceMeterSize: esidMeterSize }));
      } else {
         this.setState(Object.assign(this.state.meterRegistration, { meterSize: esidMeterSize }));
      }

      if (this.state.meterRegistration.installationType == 4) {
         this.setState(Object.assign(this.state.meterRegistration, { replaceSerialNumber: meterSerialNumber }));
      } else {
         this.setState(Object.assign(this.state.meterRegistration, { serialNumber: meterSerialNumber }));

      }

      if (this.state.meterRegistration.installationType != 4) {
         this.setState(Object.assign(this.state.meterRegistration, { meterScalingFactor: meter.CALIBRATION_FACTOR }));
      }

      if (this.state.meterRegistration.installationType == 4 && replaceMeterNo != null || replaceMeterNo != "") {
         this.setState(Object.assign(this.state.meterRegistration, { replaceMeterNumber: replaceMeterNo }));
      }


      if (meter.ENTRY_DESC_1 != null && meter.ENTRY_DESC_1 != "") {
         if (this.state.meterRegistration.installationType == 4)
            this.setState(Object.assign(this.state.meterRegistration, { replaceMeterDescription: meter.ENTRY_DESC_1 }));
         else
            this.setState(Object.assign(this.state.meterRegistration, { meterDescription: meter.ENTRY_DESC_1 }));
      }

      if (meter.ENTRY_DESC_2 != null && meter.ENTRY_DESC_2 != "" && meter.ENTRY_DESC_1 != null) {

         if (this.state.meterRegistration.installationType == 4) {
            this.setState(Object.assign(this.state.meterRegistration, {
               replaceMeterDescription: meter.ENTRY_DESC_1 + ' ' + meter.ENTRY_DESC_2
            }));
         }
         else {
            this.setState(Object.assign(this.state.meterRegistration, {
               meterDescription: meter.ENTRY_DESC_1 + ' ' + meter.ENTRY_DESC_2
            }));
         }

      }

      if (meterTypeDesc != null && meterTypeDesc != "") {
         if (this.state.meterRegistration.meterDescription != null && this.state.meterRegistration.meterDescription != "") {
            if (this.state.meterRegistration.installationType == 4) {
               this.setState(Object.assign(this.state.meterRegistration, { replaceMeterDescription: meterTypeDesc + " " + this.state.meterRegistration.meterDescription }));
            } else {
               this.setState(Object.assign(this.state.meterRegistration, { meterDescription: meterTypeDesc + " " + this.state.meterRegistration.meterDescription }));
            }

         }
         else {
            if (this.state.meterRegistration.installationType == 4) {
               this.setState(Object.assign(this.state.meterRegistration, { replaceMeterDescription: meterTypeDesc + "" }));
            }
            else {
               this.setState(Object.assign(this.state.meterRegistration, { meterDescription: meterTypeDesc + "" }));
            }

         }
      }
   }

   renderHolderNameSession = (readonly) => {
      return <>
         <div className="row  mb-3">
            <label class="form-label">What is the authority  holder type?<span className='text-danger'>*</span></label>
            <div className='pl-4'>
               <div class="form-check">
                  <input className={`form-check-input ${this.state.fieldErrors.holderType && this.state.fieldErrors.holderType != "" ? "is-invalid" : ""}`}
                     type="radio" name="holderType" id="holderTypeIndividual" autocomplete="off" value="INDIVIDUAL"
                     checked={this.state.meterRegistration.holderType == "INDIVIDUAL"} onChange={this.onChangeOfHolderType} disabled={this.state.meterRegistration.formStatus >= 1 || readonly}
                  />
                  <label class="form-check-label" for="holderTypeIndividual">
                     Individual
                  </label>
               </div>
               <div class="form-check">
                  <input className={`form-check-input ${this.state.fieldErrors.holderType && this.state.fieldErrors.holderType != "" ? "is-invalid" : ""}`}
                     type="radio" name="holderType" id="holderTypeOrg" autocomplete="off" value="ORGANISATION"
                     checked={this.state.meterRegistration.holderType == "ORGANISATION"} onChange={this.onChangeOfHolderType} disabled={this.state.meterRegistration.formStatus >= 1 || readonly}
                  />
                  <label class="form-check-label" for="holderTypeOrg">
                     Organisation
                  </label>
                  <div class="invalid-feedback">{this.state.fieldErrors.holderType}</div>
               </div>
            </div>
         </div>

         {this.state.meterRegistration.holderType && <div className="row mb-3">
            <div className='col-sm-4'>
               <InputText
                  label={function (holderType) {
                     if (holderType == "INDIVIDUAL")
                        return <>Authority holder first name <span className='text-danger'>*</span></>;
                     return <>Organisation name <span className='text-danger'>*</span></>
                  }(this.state.meterRegistration.holderType)}
                  id="primaryHolder"
                  onChange={this.onChangeOfPrimaryHolderFirstname}
                  value={this.state.meterRegistration.primaryHolder}
                  error={this.state.fieldErrors.primaryHolder}
                  readOnly={this.state.meterRegistration.formStatus >= 1 || readonly}
               />
            </div>
            {this.state.meterRegistration.holderType && this.state.meterRegistration.holderType == "INDIVIDUAL" &&
               <div className="col-sm-4">
                  <InputText
                     label={<>Authority holder surname <span className='text-danger'>*</span></>}
                     id="holderSurname"
                     onChange={this.onChangeOfPrimaryHolderSurname}
                     value={this.state.meterRegistration.holderSurname}
                     error={this.state.fieldErrors.holderSurname}
                     readOnly={this.state.meterRegistration.formStatus >= 1 || readonly}
                  />
               </div>
            }
         </div>}
      </>
   }

   renderApprovalSession = () => {
      return <>
         <div className='row mb-3'>
            <div className='col-sm-4'>
               <InputText
                  label={<>Work approval number <span className='text-danger'>*</span></>}
                  error={this.state.fieldErrors.workApproval}
                  id="approval"
                  onChange={this.onChangeOfApproval}
                  defaultValue={this.state.meterRegistration.workApproval}
                  readOnly={true}
               />
            </div>
         </div>
         <div className='row mb-3'>
            <div className='col-sm-4'>
               <InputText
                  label={<>Water access license (WAL) number</>}
                  error={this.state.fieldErrors.linkedAl}
                  id="linkedal"
                  onChange={this.onChangeOfLinkedAL}
                  defaultValue={this.state.meterRegistration.linkedAl}
                  readOnly={this.state.meterRegistration.formStatus >= 1 || this.state.displayControl.lock}
               />
            </div>
         </div>
         <div className='row mb-3'>
            <div className='col-sm-4'>
               <label for="esid" class="form-label">Unique extraction site ID (ESID) <span className='text-danger'>*</span></label>
               {!this.state.meterRegistration.installationType && <div className={this.state.fieldErrors.esid ? "alert alert-danger" : "alert alert-primary"}>You must select the installation type before you can select the ESID</div>}
               {this.state.meterRegistration.installationType && !(this.state.meterRegistration.formStatus >= 1) && !this.state.displayControl.lock &&
                  <EsidSelect
                     approvalNumber={this.state.meterRegistration.workApproval}
                     onEsidChange={this.esidChangeHandler}
                     defaultEsid={this.state.meterRegistration.esid}
                     error={this.state.fieldErrors.esid}
                  />
               }
               {(this.state.meterRegistration.formStatus >= 1 || this.state.displayControl.lock) && <input type="text" className='form-control' defaultValue={this.state.meterRegistration.esid} readOnly={true} />}
            </div>
            <div className='col-sm-3'>
               <label for="worksId" className='form-label'>Works ID</label>
               <input type="text" id="worksId" className='form-control' defaultValue={this.state.meterRegistration.worksId} readOnly={true} />
            </div>
            <div className='col-sm-3'>
               <label for="noOfWorks" className='form-label'>No. of works</label>
               <input type="text" id="noOfWorks" className='form-control' defaultValue={this.state.meterRegistration.noOfWorks} readOnly={true} />
            </div>
         </div>
         {this.state.meterRegistration.installationType && this.state.meterRegistration.installationType != 2 && <div className="row mb-3">
            <div className='col-sm-6'>
               {(this.state.meterRegistration.installationType == 5) &&
                  <label class="form-label">Existing meter number<span className='text-danger'> *</span>
                     <div class="form-text">Please select the meter you are adding telemetry to</div>
                  </label>
               }
               {(this.state.meterRegistration.installationType == 4) &&
                  <label class="form-label">Old meter<span className='text-danger'>*</span>
                     <div class="form-text">Please select the meter you are replacing</div>
                  </label>
               }
               {!(this.state.meterRegistration.formStatus >= 1) &&
                  <MeterSelectForEsid
                     approvalNumber={this.state.meterRegistration.workApproval}
                     esid={this.state.meterRegistration.esid}
                     onMeterChange={this.meterChangeHandler}
                     defaultMeterNo={this.state.meterRegistration.replaceMeterNumber}
                     error={this.state.fieldErrors.replaceMeterNumber}
                  />
               }
               {this.state.meterRegistration.formStatus >= 1 && <input type="text" className='form-control' defaultValue={"Meter No.: " + this.state.meterRegistration.replaceMeterNumber} readOnly={true} />}
            </div>
         </div>}

         {(this.state.meterRegistration.installationType && this.state.meterRegistration.installationType != 5) && <div className="row">
            <div className='col-sm-4  mb-3'>
               <label for="meterNumber" className='form-label'>New meter number</label>
               {(this.state.meterRegistration.meterNumber) && <input type="text" className='form-control' onChange={() => { }} defaultValue={this.state.meterRegistration.meterNumber} readOnly={true} />}
               {(!this.state.meterRegistration.meterNumber) && <div class="alert alert-primary">Will be generated on submission</div>}
            </div>

         </div>}
         {this.renderHolderNameSession(true)}
      </>
   }



   approvalSelectedHandler = async (approvalNumber, selectedHolder, callback) => {
      var currentContext = this;

      uiHelpers.showProgressOnTop("Getting customer address..");
      await axios.post('/datalake/getCustomerAddress', JSON.stringify({ dlwcInpaAddrId: selectedHolder.DLWC_INPA_ADDR_ID }), this.config).then(function (response) {
         if (response.data.data.addressLine2 != null) {
            currentContext.setState(Object.assign(currentContext.state.meterRegistration, { holderAddress: response.data.data.addressLine1 + " " + response.data.data.addressLine2 }));
         } else {
            currentContext.setState(Object.assign(currentContext.state.meterRegistration, { holderAddress: response.data.data.addressLine1 }));
         }
         currentContext.setState(Object.assign(currentContext.state.meterRegistration, {
            holderSuburb: response.data.data.suburb,
            holderState: response.data.data.state,
            holderPostcode: response.data.data.postcode
         }));
      }).catch(function (error) {
         uiHelpers.showErrorTop('Error happened on the server side when getting address data.');
      });

      uiHelpers.showProgressOnTop("Getting customer phone numbes");
      await axios.post('/datalake/getCustomerPhoneNumbers', JSON.stringify({ dlwcInpaId: selectedHolder.DLWC_INPA_ID }), this.config).then(function (response) {
         //currentContext.setState(Object.assign(currentContext.state, { selectedRatingTable: response.data.data }));
         if (response.data.data.length == 0) return;

         if (response.data.data[0].prefix != null) {
            currentContext.setState(Object.assign(currentContext.state.meterRegistration, { holderPhone: response.data.data[0].prefix + ' ' + response.data.data[0].phoneNumber }));
         }
         else {
            currentContext.setState(Object.assign(currentContext.state.meterRegistration, { holderPhone: response.data.data[0].phoneNumber }));
         }
      }).catch(function (error) {
         uiHelpers.showErrorTop('Error happened on the server side when getting customer phone number data.');
      });

      uiHelpers.hideProgressOnTop();

      this.setState(Object.assign(this.state.meterRegistration, {
         workApproval: approvalNumber,
         holderEmail: selectedHolder.E_MAIL
      }));

      if (selectedHolder.INPA_TYPE == "P") {

         this.setState(Object.assign(this.state.meterRegistration, {
            primaryHolder: selectedHolder.Given_Names
            , holderSurname: selectedHolder.family_name
            , holderType: 'INDIVIDUAL'
         }));
      } else {
         this.setState(Object.assign(this.state.meterRegistration, {
            primaryHolder: selectedHolder.ORGANISATION_NAME
            , holderType: 'ORGANISATION'
         }));
      }

      this.setState(Object.assign(this.state.displayControl, { pageStage: this.pageStages.infoPage() }));

      callback()
   }

   renderWorkApprovalSearchForm = () => {
      return <div className='alert alert-warning my-3'>
         This function is replaced by the Meter Compliance Process and it is no longer available.
      </div>
      //return <ApprovalSearchFormWithConsent onCustomerConsentValid={this.approvalSelectedHandler} />;
   }


   displayLatestReadingMsg = () => {

      if (!this.state.meterRegistration.isLidTelemetry || !this.state.meterRegistration.meterReadingAtInstallation || this.state.meterRegistration.formStatus != 1) return;

      if (this.state.meterRegistration.meterReadingAtInstallation && !this.state.dasLastestReading) {
         return <div className='alert alert-warning'>
            We have not detected any data received in the DAS for this workspace. Please check the DAS to ensure that data has been received before continuing
         </div>
      }

      const meterReadingAtInstallation = parseFloat(this.state.meterRegistration.meterReadingAtInstallation);
      const dasLastestReading = parseFloat(this.state.dasLastestReading);
      if (isNaN(meterReadingAtInstallation) || isNaN(dasLastestReading)) return null;

      const meterReadingAtInstallationUnit = this.state.meterRegistration.meterUnitOfMeasures ? this.meterUnitOfMeasures.filter(option => option.value === this.state.meterRegistration.meterUnitOfMeasures)[0].label : null;
      const dasLastestReadingUnit = this.state.dasLastestReadingUnit;
      if (!dasLastestReadingUnit || !meterReadingAtInstallationUnit) return null;

      var meterReadingAtInstallationFactor = 1;
      var dasLastestReadingFactor = 1;

      switch (meterReadingAtInstallationUnit) {
         case "L": meterReadingAtInstallationFactor = 1 / 1000000; break;
         case "kL": meterReadingAtInstallationFactor = 1 / 1000; break;
         case "m³": meterReadingAtInstallationFactor = 1 / 1000; break;
         case "m³x10": meterReadingAtInstallationFactor = 1 / 100; break;
         case "m³x100": meterReadingAtInstallationFactor = 1 / 10; break;
         default: meterReadingAtInstallationFactor = 1;
      }

      switch (dasLastestReadingUnit) {
         case "L": dasLastestReadingFactor = 1 / 1000000; break;
         case "kL": dasLastestReadingFactor = 1 / 1000; break;
         case "m3": dasLastestReadingFactor = 1 / 1000; break;
         case "m3x10": dasLastestReadingFactor = 1 / 100; break;
         case "m3x100": dasLastestReadingFactor = 1 / 10; break;
         default: dasLastestReadingFactor = 1;
      }

      if ((meterReadingAtInstallation * meterReadingAtInstallationFactor - dasLastestReading * dasLastestReadingFactor > 1) || (dasLastestReading * dasLastestReadingFactor - meterReadingAtInstallation * meterReadingAtInstallationFactor > 1)) {
         return <div className='alert alert-warning'>The reading you have entered has a large difference from the latest reading recorded in DAS, please check and confirm that this is correct.</div>
      }

      return null
   }


   render() {

      if(this.state.noPermission) return <div className='alert alert-danger my-5'>
         Access denied.
      </div>

      return <>

         {this.renderPageTitle()}

         {this.state.displayControl.pageStage == this.pageStages.searchForm() && this.renderSearchForm()}

         {this.state.displayControl.pageStage == this.pageStages.infoPage() && this.renderInfoPage()}

         {
            (
               this.state.displayControl.pageStage == this.pageStages.confirmation()
               ||
               this.state.displayControl.pageStage == this.pageStages.mainForm()
            )
            &&
            (
               this.state.meterRegistration.id != null
            )
            &&
            <ul class="nav nav-tabs mb-3">
               <li class="nav-item ">
                  <a class={`nav-link ${this.state.displayControl.tab == 'MAIN' ? 'active' : ''}`} aria-current="page" href="#"
                     onClick={(e) => { e.preventDefault(); this.setState(Object.assign(this.state.displayControl, { tab: 'MAIN' })); }}
                  >Record details</a>
               </li>
               <li class="nav-item">
                  <a class={`nav-link ${this.state.displayControl.tab == 'AUDIT' ? 'active' : ''}`} href="#"
                     onClick={(e) => { e.preventDefault(); this.setState(Object.assign(this.state.displayControl, { tab: 'AUDIT' })); }}
                  >Audit/Logs</a>
               </li>
               <DisplayForRoles roles={{ superAdmin: true }}>
                  <li class="nav-item">
                     <a class={`nav-link ${this.state.displayControl.tab == 'ADMIN' ? 'active' : ''}`} href="#"
                        onClick={(e) => { e.preventDefault(); this.setState(Object.assign(this.state.displayControl, { tab: 'ADMIN' })); }}
                     >Admin</a>
                  </li>
               </DisplayForRoles>
            </ul>
         }

         {this.state.displayControl.tab == 'AUDIT' && <Audit id={this.state.meterRegistration.id} />}
         <DisplayForRoles roles={{ superAdmin: true }}>
            {this.state.displayControl.tab == 'ADMIN' && <Admin id={this.state.meterRegistration.id} meterRegistration={this.state.meterRegistration} lidOptions={this.state.lidOptions} />}
         </DisplayForRoles>



         {this.state.displayControl.tab == 'MAIN' && this.state.displayControl.pageStage == this.pageStages.confirmation() && <>
            <h5>Review</h5>
            <div class="alert alert-primary" role="alert">
               Please review the information below before completing your submission.
               <br />
               The form cannot be edited once submitted. Therefore, please confirm all configuration details are correct before submitting this form.
            </div>

            <RegistrationFormReadOnly
               meterRegistration={this.state.meterRegistration}
               installationTypes={this.installationTypes}
               telemetryTypes={this.telemetryTypes}
               lids={this.lids}
               meterflowUnits={this.meterflowUnits}
               billingFlowUnits={this.billingFlowUnits}
               meterTypes={this.meterTypes}
               manufacturerModels={this.manufacturerModels}
               meterUnitOfMeasures={this.meterUnitOfMeasures}
               storageMeasurementMethods={this.storageMeasurementMethods}
               storageDepthSensorTypes={this.storageDepthSensorTypes}
               ratingTable={this.getRatingTable ? this.getRatingTable() : null}
               selectedMeterRegistrationLID={this.state.selectedMeterRegistrationLID}
            />


            <div className='text-end'>
               {
                  this.state.meterRegistration.id != null && this.state.meterRegistration.formStatus == 0 &&
                  <button type="button" className="btn btn-danger me-3" disabled={this.state.displayControl.lock}
                     data-bs-toggle="modal" data-bs-target="#deleteDraftModal">
                     Delete Draft
                  </button>
               }
               {this.state.meterRegistration.formStatus == 0 && <>
                  <button type="button" class="btn btn-primary" disabled={this.state.displayControl.lock} onClick={this.saveForm}>Save as draft</button>
                  <div className='vr mx-3'></div>
               </>}
               <button type="Button" class="btn btn-secondary me-3" disabled={this.state.displayControl.lock} onClick={this.cancelConfirm}>Back to edit</button>
               {this.state.meterRegistration.formStatus == 0 && <button type="Submit" disabled={this.state.displayControl.lock} class="btn btn-success" onClick={this.submitOrSetToInstall}>Confirm &amp; Submit</button>}
               {this.state.meterRegistration.formStatus == 1 && <button type='Submit' disabled={this.state.displayControl.lock} class="btn btn-success" onClick={this.submitOrSetToInstall}>Confirm set to Installed</button>}
            </div>
         </>}

         {this.state.displayControl.tab == 'MAIN' && this.state.displayControl.pageStage == this.pageStages.mainForm() && <>

            {this.state.displayControl.needReApplyConsent && <ReApplyConsentNotice approvalNumber={this.state.meterRegistration.workApproval} />}

            {this.state.dqp && (this.state.dqp.id != this.#currentUserId) && <div className='alert alert-primary my-3'>
               This form is owned by <strong>{this.state.dqp.firstName} {this.state.dqp.lastName}</strong> and cannot be edited
            </div>}

            {this.state.meterRegistration.formType != 'FPH' && this.state.meterRegistration.formStatus == 1 && <div class="alert alert-warning text-start my-3" role="alert">
               The form is on temporary hold due to administrative reasons. Please contact WaterNSW via dqp.enquiries@waternsw.com.au for more information.
               </div>}

            {(this.state.meterRegistration.formType != 'FPH' && this.state.meterRegistration.formStatus < 1) && <div className='alert alert-warning mb-3'>You cannot use this form since the Meter Compliance Process form has replaced it. Please click <a href='/#/compliance-process/start'>here</a> to start a new registration.</div>}

            {(this.state.meterRegistration.replacementFormType) && <>

               <div class="panel panel-default">
                  <div class="panel-heading">Replacing registration form</div>
                  <div class="panel-body">
                     <p><strong>This is a <strong className="text-blue">
                        {telemetryReplacementSelectOptions.map((option, index) => {
                           if (option.value == this.state.meterRegistration.replacementFormType) {
                              return option.label;
                           }
                        })}
                     </strong> for the below registration</strong></p>

                     <RegistrationFormReadOnly
                        meterRegistration={this.state.meterRegistration.replacingMeterRegistration}

                        installationTypes={this.installationTypes}
                        telemetryTypes={this.telemetryTypes}
                        lids={this.lids}
                        meterflowUnits={this.meterflowUnits}
                        billingFlowUnits={this.billingFlowUnits}
                        meterTypes={this.meterTypes}
                        manufacturerModels={this.manufacturerModels}
                        meterUnitOfMeasures={this.meterUnitOfMeasures}
                        storageMeasurementMethods={this.storageMeasurementMethods}
                        storageDepthSensorTypes={this.storageDepthSensorTypes}

                        hideDetails={this.state.hideReplacementDetails}
                        selectedMeterRegistrationLID={this.state.selectedMeterRegistrationLID}
                     />

                     <p>
                        {this.state.hideReplacementDetails && <a href="#" onClick={(e) => { e.preventDefault(); this.setState(Object.assign(this.state, { hideReplacementDetails: false })); }}>Show more details</a>}
                        {!this.state.hideReplacementDetails && <a href="#" onClick={(e) => { e.preventDefault(); this.setState(Object.assign(this.state, { hideReplacementDetails: true })); }}>Hide details</a>}
                     </p>
                  </div>
               </div>

            </>}

            <div className='row mb-3'>
               <div className="col-sm-4">
                  <InputText
                     label="Registration ID"
                     id="registrationNo"
                     inputExtraClasses={this.state.meterRegistration.registrationNo ? "" : "fst-italic text-secondary"}
                     value={this.state.meterRegistration.registrationNo ? this.state.meterRegistration.registrationNo : "Not saved yet"}
                     readOnly={true}
                  />
               </div>
               <div className="col-sm-4">
                  <InputText
                     label="DQP"
                     id="dqp"
                     value={this.state.dqp ? this.state.dqp.firstName + " " + this.state.dqp.lastName : ""}
                     readOnly={true}
                  />
               </div>
            </div>

            <div className='row mb-3'>
               <div className="col-sm-4">
                  <InputText
                     label="Form status"
                     id="formStatus"
                     value={this.formStatus.filter((option) => option.value == this.state.meterRegistration.formStatus)[0].label}
                     readOnly={true}
                  />
               </div>
               <div className="col-sm-4">
                  <InputText
                     label="Form type"
                     id="formType"
                     value={this.state.meterRegistration.formType == 'FPH' ? "Floodplain Havesting" : "Non-urban Metering"}
                     readOnly={true}
                  />
               </div>
            </div>


            {this.renderStorageManagementMethodOptions()}

            <div className='row mb-3'>
               <div className="col-sm-4">
                  <ReactSelect
                     label={<>
                        Installation type <span className='text-danger'>*</span>
                        <a href="#" data-bs-toggle="modal" data-bs-target="#installationTypeModal" ><QuestionFill /></a>
                     </>}
                     onChange={this.onChangeOfInstallationType}
                     value={this.state.meterRegistration.installationType}
                     disabled={this.state.meterRegistration.formStatus >= 1 ? true : false || this.state.displayControl.lock}
                     error={this.state.fieldErrors.installationType}
                     options={this.installationTypes}
                  />
                  <InstallationTypeModal id="installationTypeModal" />
               </div>
               {this.state.meterRegistration.installationType == 4 && <div className="col-sm-3">
                  {function (date, onChange, readOnly) {
                     if (date != null && date != "") {
                        date = new Date(date);
                     }
                     return <div className='react-datepicker-block'>
                        <label for="replacementStartDate" className='form-label'>Replacement start Date</label>
                        <DatePicker dateFormat="dd/MM/yyyy" className='form-control' id="replacementStartDate" onChange={onChange} selected={date} readOnly={readOnly || (this && this.state.displayControl.lock)} />
                     </div>
                  }(this.state.meterRegistration.replacementStartDate, this.onReplacementStartDate, this.state.meterRegistration.formStatus >= 1 ? true : false)}
               </div>}
            </div>

            {(this.state.meterRegistration.formStatus == 1 || this.state.meterRegistration.formStatus == 2) && <div className='row mb-3'>
               <div className="col-sm-4">
                  <label className='form-label'>Workspace ID</label>
                  <input className='form-control' type="text" onChange={() => { }} value={this.state.meterRegistration.meterSiteId ? this.state.meterRegistration.meterSiteId.meterSiteId : ""} readOnly={true} />
               </div>
               <div className="col-sm-4">
                  <label className='form-label'>Source ID</label>
                  <input className='form-control' type="text" onChange={() => { }} value={this.state.meterRegistration.meterId ? this.state.meterRegistration.meterId.meterId : ""} readOnly={true} />
               </div>
            </div>}

            <div className='card my-4'>
               <div className='card-header fw-bold'>
                  {this.getTitleForApprovalOrStorageSession()}
               </div>
               <div className='card-body'>
                  {this.renderApprovalOrStorageSession()}

                  <div className='row mb-3'>
                     <div className='col-sm-6'>
                        <InputText
                           label={<>Address <span className='text-danger'>*</span></>}
                           error={this.state.fieldErrors.holderAddress}
                           id="address"
                           onChange={this.onChangeOfAddress}
                           value={this.state.meterRegistration.holderAddress}
                           readOnly={this.state.meterRegistration.formStatus >= 1 ? true : false || this.state.displayControl.lock}
                        />
                     </div>
                     <div className='col-sm-6'>
                        <InputText
                           label={<>Suburb <span className='text-danger'>*</span></>}
                           error={this.state.fieldErrors.holderSuburb}
                           id="suburb"
                           onChange={this.onChangeOfSuburb}
                           value={this.state.meterRegistration.holderSuburb}
                           readOnly={this.state.meterRegistration.formStatus >= 1 ? true : false || this.state.displayControl.lock}
                        />
                     </div>
                  </div>

                  <div className='row mb-3'>
                     <div className='col-sm-6'>
                        <InputText
                           label={<>Postcode <span className='text-danger'>*</span></>}
                           error={this.state.fieldErrors.holderPostcode}
                           id="postcode"
                           onChange={this.onChangeOfPostcode}
                           value={this.state.meterRegistration.holderPostcode}
                           readOnly={this.state.meterRegistration.formStatus >= 1 ? true : false || this.state.displayControl.lock}
                        />
                     </div>
                     <div className='col-sm-4'>
                        <ReactSelect
                           id="state"
                           label={<>State <span className='text-danger'>*</span></>}
                           onChange={this.onChangeOfState}
                           value={this.state.meterRegistration.holderState}
                           disabled={this.state.meterRegistration.formStatus >= 1 ? true : false || this.state.displayControl.lock}
                           error={this.state.fieldErrors.holderState}
                           options={this.states}
                        />
                     </div>
                  </div>

                  <div className='row mb-3'>
                     <div className='col-sm-6'>
                        <InputText
                           label={<>Phone number <span className='text-danger'>*</span></>}
                           error={this.state.fieldErrors.holderPhone}
                           id="holderphone"
                           onChange={this.onChangeOfHolderPhone}
                           value={this.state.meterRegistration.holderPhone}
                           readOnly={this.state.meterRegistration.formStatus >= 1 ? true : false || this.state.displayControl.lock}
                        />
                     </div>
                     <div className='col-sm-6'>
                        <InputText
                           label={<>Customer's current email address <span className='text-danger'>*</span></>}
                           error={this.state.fieldErrors.holderEmail}
                           id="holderemail"
                           onChange={this.onChangeOfHolderEmail}
                           value={this.state.meterRegistration.holderEmail}
                           readOnly={this.state.meterRegistration.formStatus >= 1 ? true : false || this.state.displayControl.lock}
                        />
                     </div>
                  </div>

               </div>
            </div>

            {this.state.meterRegistration.installationType == 4 && this.displayMeterDetails() && <>
               <div className='card mb-4'>
                  <div className='card-header fw-bold'>
                     {this.getTitleForOldMeterDetails()}
                  </div>
                  <div className='card-body'>
                     {this.displayStorageDepthSensorTypeOptions() && this.renderStorageDepthSensorTypeOptions(true)}
                     <div className='row mb-3'>
                        <div className='col-sm-6'>
                           <ReactSelect
                              id="replaceManufacturerModel"
                              label={<>Manufacturer &amp; model <span className='text-danger'>*</span></>}
                              onChange={this.onChangeOfReplaceManufacturerModel}
                              value={this.state.meterRegistration.replaceManufacturerAndModel}
                              disabled={this.state.meterRegistration.formStatus >= 1 || this.state.displayControl.lock}
                              error={this.state.fieldErrors.replaceManufacturerAndModel}
                              options={this.manufacturerModels}
                           />
                        </div>
                        {this.state.displayControl.showReplaceOtherManufactuerModel && <div className='col-sm-6'>
                           <InputText
                              label={<>Other Manufactuer and Model <span className='text-danger'>*</span></>}
                              error={this.state.fieldErrors.replaceOtherManufacturerAndModelText}
                              id="otherReplaceManufacturerModel"
                              onChange={this.onChangeOfReplaceOtherManufactuerModel}
                              value={this.state.meterRegistration.replaceOtherManufacturerAndModelText}
                              readOnly={this.state.meterRegistration.formStatus >= 1 || this.state.displayControl.lock}
                           />
                        </div>}
                     </div>

                     {(this.displayMeterSize() || this.displayMeterSerialNumber()) && <div className='row mb-3'>
                        {this.displayMeterSize() && <div className='col-sm-6'>
                           <InputText
                              label={<>Meter Size (mm) <span className='text-danger'>*</span></>}
                              error={this.state.fieldErrors.replaceMeterSize}
                              id="replaceMeterSize"
                              onChange={this.onChangeOfReplaceMeterSize}
                              value={this.state.meterRegistration.replaceMeterSize}
                              readOnly={this.state.meterRegistration.formStatus >= 1 || this.state.displayControl.lock}
                           />
                        </div>}
                        {this.displayMeterSerialNumber() && <div className='col-sm-6'>
                           <InputText
                              label={<>Serial number</>}
                              error={this.state.fieldErrors.replaceSerialNumber}
                              id="replaceSerialnumber"
                              onChange={this.onChangeOfReplaceSerialNumber}
                              value={this.state.meterRegistration.replaceSerialNumber}
                              readOnly={this.state.meterRegistration.formStatus >= 1 || this.state.displayControl.lock}
                           />
                        </div>}
                     </div>}

                     {this.displayOldMeterNumber() && <div className='row mb-3'>
                        <div className='col-sm-4'>
                           <InputText
                              label={<>Meter Number</>}
                              error={this.state.fieldErrors.replaceMeterNumber}
                              id="replaceMeterNumber"
                              onChange={this.onChangeOfReplaceMeterNumber}
                              value={this.state.meterRegistration.replaceMeterNumber}
                              readOnly={true}
                           />
                        </div>
                     </div>}

                     <div className='mb-3'>
                        <label for="replaceMeterdescription" className='form-label'>Description</label>
                        <textarea
                           cols="50" rows="2"
                           className='form-control'
                           id="replaceMeterdescription"
                           onChange={this.onChangeOfReplaceMeterDescription}
                           value={this.state.meterRegistration.replaceMeterDescription}
                           readOnly={this.state.meterRegistration.formStatus >= 1 || this.state.displayControl.lock}></textarea>
                     </div>
                  </div>
               </div>
            </>}
            {this.displayMeterDetails() &&
               <div className='card mb-4'>
                  <div className='card-header fw-bold'>
                     {(this.state.meterRegistration.installationType == 2) && <>{this.getTitleForNewMeterDetails()}</>}
                     {(this.state.meterRegistration.installationType == 4) && <>{this.getTitleForNewMeterDetails()}</>}
                     {(this.state.meterRegistration.installationType == 5) && <>{this.getTitleForExistingMeterDetails()}</>}
                  </div>
                  <div className='card-body'>
                     {this.displayMeterType() && <div className='row mb-3'>
                        <div className='col-sm-6'>
                           <ReactSelect
                              id="meterType"
                              label={<>Type <span className='text-danger'>*</span></>}
                              onChange={this.onChangeOfMeterType}
                              value={this.state.meterRegistration.meterType}
                              disabled={this.state.meterRegistration.formStatus >= 1 || this.state.displayControl.lock}
                              error={this.state.fieldErrors.meterType}
                              options={this.meterTypes}
                           />
                        </div>
                     </div>}
                     {this.displayStorageDepthSensorTypeOptions() && this.renderStorageDepthSensorTypeOptions(false)}
                     {this.displayDepthSesorLevel() && this.renderDepthSesorLevel()}
                     <div className='row mb-3'>
                        <div className='col-sm-6'>
                           <ReactSelect
                              id="manufacturerModel"
                              label={<>Manufacturer &amp; model <span className='text-danger'>*</span></>}
                              onChange={this.onChangeOfManufacturerModel}
                              value={this.state.meterRegistration.manufacturerAndModel}
                              disabled={this.state.meterRegistration.formStatus >= 1 || this.state.displayControl.lock}
                              error={this.state.fieldErrors.manufacturerAndModel}
                              options={this.manufacturerModels}
                           />
                        </div>
                        {this.state.displayControl.showOtherManufactuerModel && <div className='col-sm-6'>
                           <InputText
                              label={<>Other manufactuer and model <span className='text-danger'>*</span></>}
                              error={this.state.fieldErrors.otherManufacturerAndModelText}
                              id="otherManufacturerModel"
                              onChange={this.onChangeOfOtherManufactuerModel}
                              value={this.state.meterRegistration.otherManufacturerAndModelText}
                              readOnly={this.state.meterRegistration.formStatus >= 1 || this.state.displayControl.lock}
                           />
                        </div>}
                     </div>
                     {(this.displayMeterSize() || this.displayMeterSerialNumber()) && <div className='row mb-3'>
                        {this.displayMeterSize() && <div className='col-sm-6'>
                           <InputText
                              label={<>Meter Size (mm) <span className='text-danger'>*</span></>}
                              error={this.state.fieldErrors.meterSize}
                              id="metersize"
                              onChange={this.onChangeOfMeterSize}
                              value={this.state.meterRegistration.meterSize}
                              readOnly={this.state.meterRegistration.formStatus >= 1 || this.state.displayControl.lock}
                           />
                        </div>}
                        {this.displayMeterUnitOfMeasures() && <div className='col-sm-6'>
                           <ReactSelect
                              id="meterUnitOfMeasures"
                              label={<>Unit of measure as displayed on meter <span className='text-danger'>*</span></>}
                              onChange={this.onChangeOfMeterUnitOfMeasures}
                              value={this.state.meterRegistration.meterUnitOfMeasures}
                              disabled={this.state.meterRegistration.formStatus >= 1 || this.state.displayControl.lock}
                              error={this.state.fieldErrors.meterUnitOfMeasures}
                              options={this.meterUnitOfMeasures}
                           />
                        </div>}
                     </div>}
                     {this.displayMeterSerialNumber() && <div className='row'>
                        <div className='col-sm-6'>
                           <InputText
                              label={<>Serial Number</>}
                              error={this.state.fieldErrors.serialNumber}
                              id="metersize"
                              onChange={this.onChangeOfSerialNumber}
                              value={this.state.meterRegistration.serialNumber}
                              readOnly={this.state.meterRegistration.formStatus >= 1 || this.state.displayControl.lock}
                           />
                        </div>
                     </div>}
                     <h5 className='my-4'>Location</h5>
                     <div className='row mb-3'>
                        <div className='col-sm-6'>
                           <InputText
                              label={<>Latitude <span className='text-danger'>*</span></>}
                              error={this.state.fieldErrors.latitude}
                              id="lattitude"
                              onChange={this.onChangeOfLatitude}
                              value={this.state.meterRegistration.latitude}
                              readOnly={this.state.meterRegistration.formStatus >= 2 || this.state.displayControl.lock}
                              onBlur={this.updateMapPoints}
                           />
                        </div>
                        <div className='col-sm-6'>
                           <InputText
                              label={<>Longitude <span className='text-danger'>*</span></>}
                              error={this.state.fieldErrors.longitude}
                              id="longitude"
                              onChange={this.onChangeOfLongitude}
                              value={this.state.meterRegistration.longitude}
                              readOnly={this.state.meterRegistration.formStatus >= 2 || this.state.displayControl.lock}
                              onBlur={this.updateMapPoints}
                           />
                        </div>
                     </div>
                     <div className='mb-3' id="map" style={{ minHeight: "360px" }}>
                        {this.state.meterRegistration.latitude != null && this.state.meterRegistration.longitude != null &&
                           <WebMapViewActualLocation
                              form="Meter Registration" formStatus={this.state.meterRegistration.formStatus}
                              updatePoints={this.updatePoints} ref={this.childRef}
                              actualPoint={{ latitude: this.state.meterRegistration.latitude, longitude: this.state.meterRegistration.longitude }}
                           />
                        }
                     </div>
                     <div className='mb-3'>
                        <label for="meterdescription" className='form-label'>Description</label>
                        <textarea
                           cols="50" rows="2"
                           className='form-control'
                           id="meterdescription"
                           onChange={this.onChangeOfMeterDescription}
                           value={this.state.meterRegistration.meterDescription}
                           readOnly={this.state.meterRegistration.formStatus >= 1 || this.state.displayControl.lock}></textarea>
                     </div>
                  </div>
               </div>
            }

            <div className='card mb-4'>
               <div className='card-header fw-bold'>LID details</div>
               <div className='card-body'>
                  <div className='row mb-3'>
                     <div className='col-sm-6'>
                        <ReactSelect
                           id="telemetrytype"
                           label={<>LID type <span className='text-danger'>*</span></>}
                           onChange={this.onChangeOfTelemetryType}
                           value={this.state.meterRegistration.telemetryType}
                           disabled={this.state.meterRegistration.formStatus >= 1 || this.state.displayControl.lock}
                           error={this.state.fieldErrors.telemetryType}
                           options={this.telemetryTypes}
                        />
                        {(this.state.meterRegistration.telemetryType == '2' || this.state.meterRegistration.telemetryType == 2) && this.state.lidOptions.length == 0 &&
                           <div className="alert alert-warning mt-3">There are currently no Satellite LID devices approved for use in this process. You cannot proceed with the form with Satellite selected.</div>
                        }
                     </div>

                     <div className='col-sm-6'>
                        <ReactSelect
                           id="lid"
                           label={<>LID device <span className='text-danger'>*</span></>}
                           onChange={this.onChangeOfLID}
                           value={parseInt(this.state.meterRegistration.lid)}
                           disabled={this.state.meterRegistration.formStatus >= 1 || this.state.displayControl.lock}
                           error={this.state.fieldErrors.lid}
                           options={this.state.lidOptions.filter(l => {
                              return l.active || (this.state.meterRegistration.lid && l.value == parseInt(this.state.meterRegistration.lid))
                           })}
                        />
                     </div>


                  </div>


                  {this.state.meterRegistration.lid && <>

                     {this.isLIDForMultipleMeters() ?
                        <div className='mb-3'>
                           <div className='mb-3'>
                              <label class="form-label">
                                 Is this the first meter registered to this LID? <span className='text-danger'>*</span>
                              </label>
                              <div className='ps-2'>
                                 <div class="form-check">
                                    <input
                                       className={`form-check-input ${this.state.fieldErrors.isFirstMeterForLID && this.state.fieldErrors.isFirstMeterForLID != "" ? "is-invalid" : ""}`}
                                       type="radio" name="isFirstMeterForLID" id="isFirstMeterForLIDYes"
                                       autocomplete="off" value="YES"
                                       checked={this.state.meterRegistration.isFirstMeterForLID === true ? true : false}
                                       onChange={() => { this.onChangeIsFirstMeterForLID(true); }}
                                       disabled={this.state.meterRegistration.formStatus >= 1 || this.state.displayControl.lock}
                                    />
                                    <label class="form-check-label" for="isFirstMeterForLIDYes">
                                       YES
                                    </label>
                                 </div>
                                 <div class="form-check">
                                    <input
                                       className={`form-check-input ${this.state.fieldErrors.isFirstMeterForLID && this.state.fieldErrors.isFirstMeterForLID != "" ? "is-invalid" : ""}`}
                                       type="radio" name="isFirstMeterForLID" id="isFirstMeterForLIDNo"
                                       autocomplete="off" value="NO"
                                       checked={this.state.meterRegistration.isFirstMeterForLID === false ? true : false}
                                       onChange={() => { this.onChangeIsFirstMeterForLID(false); }}
                                       disabled={this.state.meterRegistration.formStatus >= 1 || this.state.displayControl.lock}
                                    />
                                    <label class="form-check-label" for="isFirstMeterForLIDNo">
                                       NO
                                    </label>
                                    <div class="invalid-feedback">{this.state.fieldErrors.isFirstMeterForLID}</div>
                                 </div>
                              </div>
                           </div>


                           {this.state.meterRegistration.isFirstMeterForLID != null && <>

                              {this.state.meterRegistration.isFirstMeterForLID ? <div className='card card-body'>
                                 <h5 class="card-title">Please enter the LID details as this is the first meter for this LID</h5>
                                 <LIDSection
                                    meterRegistration={this.state.meterRegistration}
                                    fieldErrors={this.state.fieldErrors}
                                    displayScallingFactor={this.displayScallingFactor}
                                    displayMeterUnits={this.displayMeterUnits}
                                    onChangeOMeterScalingFactor={this.onChangeOMeterScalingFactor}
                                    onChangeOfMeterFlowUnit={this.onChangeOfMeterFlowUnit}
                                    meterflowUnits={this.meterflowUnits}
                                    onChangeisLidTelemetry={this.onChangeisLidTelemetry}
                                    onChangeOfHolderComments={this.onChangeOfHolderComments}
                                    readOnly={this.state.displayControl.lock}
                                 />
                              </div>
                                 :
                                 <>
                                    <MultipleMeterLIDSelection
                                       approvalNumber={this.state.meterRegistration.workApproval}
                                       onChangeLid={this.onChangeExistingLidSelected}
                                       disabled={this.state.meterRegistration.formStatus >= 1 || this.state.displayControl.lock}
                                       meterRegistrationLIDId={
                                          this.state.meterRegistration.meterRegistrationLIDId != null ? this.state.meterRegistration.meterRegistrationLIDId
                                             :
                                             this.state.meterRegistration.meterRegistrationLID != null ? this.state.meterRegistration.meterRegistrationLID.id
                                                :
                                                null
                                       }
                                    />

                                    {this.state.fieldErrors.meterRegistrationLIDId && <div class="alert alert-danger mb-3">{this.state.fieldErrors.meterRegistrationLIDId}</div>}

                                    {(this.state.meterRegistration.meterRegistrationLIDId || this.state.meterRegistration.meterRegistrationLID) && <div className='card card-body'>
                                       <h5 class="card-title">LID information of {this.state.selectedMeterRegistrationLID.firstRegistrationMeterSiteId}</h5>

                                       <LIDSection
                                          meterRegistration={this.state.meterRegistration}
                                          fieldErrors={this.state.fieldErrors}
                                          onChangeOfTelemetryType={this.onChangeOfTelemetryType}
                                          telemetryTypes={this.telemetryTypes}
                                          displayScallingFactor={this.displayScallingFactor}
                                          displayMeterUnits={this.displayMeterUnits}
                                          onChangeOMeterScalingFactor={this.onChangeOMeterScalingFactor}
                                          onChangeOfMeterFlowUnit={this.onChangeOfMeterFlowUnit}
                                          meterflowUnits={this.meterflowUnits}
                                          onChangeisLidTelemetry={this.onChangeisLidTelemetry}
                                          onChangeOfHolderComments={this.onChangeOfHolderComments}
                                          readOnly={true}
                                       />
                                    </div>}
                                 </>}
                           </>}
                        </div> :
                        <div className="mb-3">
                           <LIDSection
                              meterRegistration={this.state.meterRegistration}
                              fieldErrors={this.state.fieldErrors}
                              onChangeOfTelemetryType={this.onChangeOfTelemetryType}
                              telemetryTypes={this.telemetryTypes}
                              displayScallingFactor={this.displayScallingFactor}
                              displayMeterUnits={this.displayMeterUnits}
                              onChangeOMeterScalingFactor={this.onChangeOMeterScalingFactor}
                              onChangeOfMeterFlowUnit={this.onChangeOfMeterFlowUnit}
                              meterflowUnits={this.meterflowUnits}
                              onChangeisLidTelemetry={this.onChangeisLidTelemetry}
                              onChangeOfHolderComments={this.onChangeOfHolderComments}
                              readOnly={this.state.displayControl.lock}
                           />
                        </div>}

                     <div className='mb-3'>
                        <label for="holdercomments" className='form-label'>Additional comments</label>
                        <textarea
                           cols="50" rows="2"
                           className='form-control'
                           id="holdercomments"
                           onChange={this.onChangeOfHolderComments}
                           value={this.state.meterRegistration.holderComments}
                           readOnly={this.state.meterRegistration.formStatus >= 2}></textarea>
                     </div>
                  </>}







               </div>
            </div>

            <div className='card mb-4'>
               <div className='card-header fw-bold'>Supporting documents</div>
               <div className='card-body'>
                  <FileUploader
                     fileInputLabel={<strong>Upload your supporting documents here:</strong>}
                     fileTableHeader={<p><strong>Your uploaded files: </strong></p>}
                     warning={<>Max File Upload Size : 10MB</>}
                     maxFileSizeMB={10}
                     exceedFileSizeErrorMsg="Your file is too big"
                     disabled={this.state.meterRegistration.formStatus != 0 || this.state.displayControl.lock}

                     provideDisplayDocumentsUpdator={(updateFunction) => { this.#fileUploaderDisplayListUpdate = updateFunction; }}
                     askForDocumentUpdate={this.updateDisplayFileList}
                     onFilesUploaded={this.fileUploaderFilesUploaded}
                     onFileDeleted={this.fileUploaderFileDeleted}

                  />
               </div>
            </div>
            {(this.state.meterRegistration.formStatus >= 1) && <>
               {this.state.meterRegistration.isLidTelemetry && <div className='row mb-3'>
                  <div className='col-md-6'></div>
                  <div className='col-md-6'>
                     {this.state.dasLastestReadingUnit
                        ?
                        <InputTextWithEndAddOn
                           label={<>Latest reading recorded in DAS <span className='text-primary'><Popover content="The data displayed here may be up to 24h old. It might not reflect the real time data in the DAS" /></span></>}
                           value={this.state.dasLastestReading}
                           readOnly={true}
                           addOnText={this.state.dasLastestReadingUnit}
                           inputExtraClasses="text-end"

                        />
                        :
                        <InputText
                           label={<>Latest reading recorded in DAS <span className='text-primary'><Popover content="The data displayed here may be up to 24h old. It might not reflect the real time data in the DAS" /></span></>}
                           value={this.state.dasLastestReading}
                           readOnly={true}
                           inputExtraClasses="text-end"
                        />
                     }

                  </div>
               </div>}
               <div className='row mb-3'>
                  <div className='col-md-3'>
                     <div className='react-datepicker-block'>
                        <label className='form-label'>Date Of Installation <span className='text-danger'>*</span></label>
                        <DatePicker
                           className={`form-control w-auto ${(this.state.fieldErrors.dateOfInstallation && this.state.fieldErrors.dateOfInstallation != "") ? "is-invalid" : ""}`}
                           maxDate={new Date()}
                           id="dateOfInstallation"
                           onChange={this.onChangeOfDateOfInstallation}
                           selected={
                              (this.state.meterRegistration.dateOfInstallation == null || this.state.meterRegistration.dateOfInstallation == "")
                                 ?
                                 this.state.meterRegistration.dateOfInstallation
                                 :
                                 new Date(this.state.meterRegistration.dateOfInstallation)
                           }
                           readOnly={((this.state.meterRegistration.formStatus == 1) ? false : true) || this.state.displayControl.lock}
                           showTimeSelect
                           timeIntervals={15}
                           filterTime={filterPassedTime}
                           dateFormat={"dd/MM/yyyy h:mm aa"}
                           timeCaption="Time"
                           open={this.state.isInstallationDateCalendarOpen}
                           onInputClick={() => { this.setState(Object.assign(this.state, { isInstallationDateCalendarOpen: true })); }}
                           onClickOutside={() => { this.setState(Object.assign(this.state, { isInstallationDateCalendarOpen: false })); }}
                        />
                        <div className='invalid-feedback'
                           style={{ display: (this.state.fieldErrors.dateOfInstallation && this.state.fieldErrors.dateOfInstallation != "") || true ? "block" : "none" }}
                        >
                           {this.state.fieldErrors.dateOfInstallation}
                        </div>
                     </div>
                  </div>
                  <div className='col-md-3'></div>
                  <div className='col-md-6'>
                     {!this.state.meterRegistration.meterUnitOfMeasures ?
                        <InputText
                           label={<>Meter reading at the time of installation, as shown on meter display <span className='text-danger'>*</span></>}
                           error={this.state.fieldErrors.meterReadingAtInstallation}
                           id="meterReadingAtInstallation"
                           onChange={this.onChangeOfMeterReadingAtInstallation}
                           value={this.state.meterRegistration.meterReadingAtInstallation}
                           readOnly={this.state.meterRegistration.formStatus != 1 || this.state.displayControl.lock}
                           placeholder="Enter reading in units shown on meter display"
                           inputExtraClasses="text-end"
                        />
                        :
                        <InputTextWithEndAddOn
                           label={<>Meter reading at the time of installation, as shown on meter display <span className='text-danger'>*</span></>}
                           error={this.state.fieldErrors.meterReadingAtInstallation}
                           id="meterReadingAtInstallation"
                           onChange={this.onChangeOfMeterReadingAtInstallation}
                           value={this.state.meterRegistration.meterReadingAtInstallation}
                           readOnly={this.state.meterRegistration.formStatus != 1 || this.state.displayControl.lock}
                           addOnText={this.meterUnitOfMeasures.filter(option => option.value === this.state.meterRegistration.meterUnitOfMeasures)[0].label}
                           placeholder="Enter reading in units shown on meter display"
                           inputExtraClasses="text-end"
                        />
                     }
                     {this.displayLatestReadingMsg()}
                  </div>
               </div>


            </>}

            {this.hasValidationError() && <div>
               <div className="alert alert-danger mb-3">Please fix all errors before you continue</div>
            </div>}

            <div class="text-end mb-3" >
               {this.state.meterRegistration.id != null && this.state.meterRegistration.formStatus == 0 && <>
                  <button
                     type="button"
                     className="btn btn-danger me-3"
                     data-bs-toggle="modal" data-bs-target="#deleteDraftModal"
                     disabled={this.state.displayControl.lock}>
                     Delete draft
                  </button>

               </>}
               {(this.state.meterRegistration.formType == 'FPH' && this.state.meterRegistration.formStatus < 1) && <>
                  <button
                     type="button" class="btn btn-primary"
                     onClick={this.saveForm}
                     disabled={this.state.displayControl.lock}
                  >
                     Save as draft
                  </button>
                  <div class="vr mx-3"></div>
                  <button
                     type="button" class="btn btn-success"
                     disabled={this.state.displayControl.lock}
                     onClick={this.confirm}
                  >
                     Next
                  </button>
               </>}

               {this.state.meterRegistration.formType == 'FPH' && this.state.displayControl.isInstalledVisible && this.state.meterRegistration.formStatus == 1 && <button
                  type='Submit' class="btn btn-primary"
                  disabled={this.state.displayControl.lock}
                  onClick={this.confirm}
               >
                  Set to installed
               </button>}
               


               {
                  (this.state.meterRegistration.formType == 'FPH' && !this.state.displayControl.isInstalledVisible && this.state.meterRegistration.formStatus == 1) &&
                  <div class="alert alert-primary text-start" role="alert">
                     Pending - <em>{this.state.displayControl.msgForNotAllowToInstall}</em>
                  </div>
               }

               
            </div>

            



         </>}


         {/* start the delete draft dialog */}
         <div class="modal fade" id="deleteDraftModal" tabindex="-1" role="dialog" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered" role="document">
               <div class="modal-content">
                  <div class="modal-header">
                     <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                     <strong>Are you sure you want to delete this draft version of the form?</strong>
                  </div>
                  <div class="modal-footer">
                     <button type="button" class="btn btn-secondary" disabled={this.state.displayControl.lock} data-bs-dismiss="modal">Cancel</button>
                     <button type="button" class="btn btn-primary"
                        onClick={() => { this.deleteDraft(); }} disabled={this.state.displayControl.lock}
                     >Confirm deletion</button>
                  </div>
               </div>
            </div>
         </div>
         {/* end the delete draft dialog */}




      </>
   }
}

export default RegistrationFormBase;

