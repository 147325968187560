import React from 'react';
import ApprovalFormFileUploader from './ApprovalFormFileUploader';
import ValidationApprovalHistory from './ValidationApprovalHistory';
import { isStringNullOrEmpty } from '../../../../helpers/validators';

const scenarioOpts = [
    {id:1,passing:true,scenario:"Approved", outcome:"Successful completion of meter and LID commissioning.  A Meter Validation Certificate will be emailed to the Customer and DQP for your records. Thank you."},
    {id:2,passing:true,scenario:"Other/Note", outcome:""},
    {id:3,passing:false,scenario:"DAS meter read mismatch", outcome:"Discrepancy between the meter readings captured in the form and photos against the values transmitted to the DAS, please confirm current meter readings against current DAS volume, update form details and resubmit for review. "},
    {id:4,passing:false,scenario:"Details do not match photos", outcome:"The details of xxx in the form do not match the photos provided.  Please update the form and resubmit."},
    {id:5,passing:false,scenario:"Emplacement details missing", outcome:"No emplacement details provided.  Please complete emplacement details and re-submit."},
    {id:6,passing:false,scenario:"Installation Date/Time before LID connected to DAS", outcome:"LID connected with DAS at 00:00 am on xx/xx/xxxx. Please update 'Site Commissioning Date' field to match these details and resubmit."},
    {id:7,passing:false,scenario:"Meter Read doesn’t match photo", outcome:`Meter reading mismatch between meter display photo (xxx) and the reading at time of installation (14 m3). \r\nPlease confirm correct meter reading and re-submit.`},
    {id:8,passing:false,scenario:"Meter unit mismatch", outcome:"Meter display unit (pic): m3 and in form : KL. Please confirm correct meter display unit and re-submit. "},
    {id:9,passing:false,scenario:"Old meter final read not provided", outcome:"Unable to confirm old meter final reading. Please provide photo evidence of the meter display showing the last read. and then resubmit."},
    {id:10,passing:false,scenario:"Photo not clear", outcome:"Photos provided are not clear.  Please resubmit clear photos that match the details in the form and resubmit."},
    {id:11,passing:false,scenario:"Site commissioning Date field incorrect", outcome:"The site commissioning date is incorrect, this date should be at least 24 hrs after the first recorded data coming though in the DAS. POlease update and resubmit."},
    {id:12,passing:false,scenario:"Other/Note", outcome:""},
];

export default class ValidationApprovalFields  extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            rejecting:false,
            approving:false,
            callingBackend:false            
        }

        const form = this.props.approvalForm;
        form
        .setPostChangeActions({
            rejectOrApprove:(val,vals,form)=>{
                form.vals({scenarios:[]},false);
                form.errs({scenarios:null});      
            }
        })
        .defineAction('reject',this.reject,{
            rejectOrApprove:[(val,vals)=>{
                if(!vals.scenarios || vals.scenarios.length==0) return "Please select one of the scenarios";
                vals.scenarios.forEach(e => {
                    if(isStringNullOrEmpty(e.outcome)) e.outcomeErr = "";
                    else e.outComeErr = null;
                });
            }],
            scenarios:[(val,vals)=>{
                if(!val || val.length==0) return null;
                var hasError = false;
                val.forEach(e => {
                    if(isStringNullOrEmpty(e.outcome)) {
                        e.outcomeErr = "Please provide the oucome for this scenario";
                        hasError=true;
                    } else e.outcomeErr = null;
                });
                if(hasError) return "Outcome missing";
                else return null;
            }]
        })
        .defineAction('approve',this.approve,{
            rejectOrApprove:[(val,vals)=>{
                if(!vals.scenarios || vals.scenarios.length==0) return "Please select one of the scenarios";
                vals.scenarios.forEach(e => {
                    if(isStringNullOrEmpty(e.outcome)) e.outcomeErr = "";
                    else e.outComeErr = null;
                });
            }],
            scenarios:[(val,vals)=>{
                if(!val || val.length==0) return null;
                var hasError = false;
                val.forEach(e => {
                    if(isStringNullOrEmpty(e.outcome)) {
                        e.outcomeErr = "Please provide the oucome for this scenario";
                        hasError=true;
                    } else e.outcomeErr = null;
                });
                if(hasError) return "Outcome missing";
                else return null;
            }]
        })
    }

    reject = () => {
        const form = this.props.approvalForm;
        this.setState({rejecting:true},()=>{form.disableForm();})
    }

    cancelReject = () => {
        const form = this.props.approvalForm;
        this.setState({rejecting:false},()=>{form.enableForm();})
    }

    approve = () => {
        const form = this.props.approvalForm;
        this.setState({approving:true},()=>{form.disableForm();})
    }

    cancelApproving = () => {
        const form = this.props.approvalForm;
        this.setState({approving:false},()=>{form.enableForm();})
    }

    confirmRejection = () =>{

        this.setState({callingBackend:true},()=>{
            this.props.reject(()=>{
                this.setState({
                    rejecting:false,
                    approving:false ,
                    callingBackend:false           
                })
            });
        })
    }

    confirmApproval = () => {
        this.setState({callingBackend:true},()=>{
            this.props.approve(()=>{
                this.setState({
                    rejecting:false,
                    approving:false ,
                    callingBackend:false           
                })
            });
        })
        
    }

    scenarioCheckChanged = (s) => {
        const form = this.props.approvalForm;
        let scenarios;
        if(form.val('scenarios')){
            scenarios = form.val('scenarios');
        } else {
            scenarios = [];
        }

        var found = false;
        var index = null;
        for(var i =0; i < scenarios.length; i++){
            if(scenarios[i].id == s.id) {
                found = true;
                index = i;
            }
        }
        if(found){
            scenarios.splice(index,1);
        } else {
            scenarios.push({
                id:s.id,
                scenario: s.scenario,
                outcome:s.outcome
            });
        }
        form.fieldValueChanged('scenarios',scenarios);
    }

    onRejectOrApprove = () => {
        const form = this.props.approvalForm;
        if(form.val('rejectOrApprove')=="APPROVED"){
            form.runAction('approve')
        } else if(form.val('rejectOrApprove')=="REJECTED") {
            form.runAction('reject')
        } else {
            form.errs({rejectOrApprove:'Please select whether you would like to approve or reject'})
        }
    }

    render() {
        const form = this.props.approvalForm;
        return <>
            <div class="card mb-3">
                <div class="card-header">
                    Approving Officer Use Only
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <div className="col-md-4 py-2">Attachments</div>
                        <div className='col-md-8'>
                            <ApprovalFormFileUploader approvalForm={form} />
                        </div>
                    </div>

                    <div className={`row mb-3`}>
                        <div className={`col-md-4 py-2`}>Quality Assurance Checks</div>
                        <div className={`col-md-8 pt-1`}>
                            {form.renderRadioOptions("rejectOrApprove",[
                                {optionId:"APPROVED", label:"Pass",value:"APPROVED",isChecked:(val)=>val=="APPROVED"},
                                {optionId:"REJECTED", label:"Fail",value:"REJECTED",isChecked:(val)=>val=="REJECTED"},
                            ],true)}
                        </div>
                    </div>

                    {(form.val('rejectOrApprove')=="APPROVED" || form.val('rejectOrApprove')=="REJECTED") && <div className='row mb-3'>
                        <div className="col-md-4 py-2">Scenarios</div>
                        <div className='col-md-8'>
                            <ul class="list-group">
                                {scenarioOpts.filter(s=>(form.val('rejectOrApprove')=="APPROVED" && s.passing) || (form.val('rejectOrApprove')=="REJECTED" && !s.passing)).map(s=>{
                                    var scenario = null;
                                    if(form.val('scenarios') && form.val('scenarios').filter(sc=>sc.id == s.id).length!=0 ) 
                                        scenario = form.val('scenarios').filter(sc=>sc.id == s.id)[0];

                                    return <li className="list-group-item">
                                        <input className="form-check-input me-1" type="checkbox" value="" disabled={form.isFieldDisabled('scenarios') || form.isFormDisabled()} id={`scenario_opt_${s.id}`} onChange={()=>{this.scenarioCheckChanged(s);}} checked={scenario!=null}/>
                                        <label for={`scenario_opt_${s.id}`}>{s.scenario}</label>
                                        {scenario!=null && <div className="mt-3">
                                            <b className='mb-1'>Outcome:</b>
                                            <textarea
                                                onChange={(e)=>{
                                                    scenario.outcome=e.target.value;
                                                    form.vals({scenarios:form.val('scenarios')})
                                                }} 
                                                disabled={form.isFieldDisabled('scenarios') || form.isFormDisabled()}
                                                className={`form-control ${scenario.outcomeErr?'is-invalid':''}`}
                                            >{scenario.outcome}</textarea>
                                            <div className='invalid-feedback'>{scenario.outcomeErr}</div>
                                        </div>}
                                    </li>
                                })}
                            </ul>
                        </div>
                    </div>}

                    <ValidationApprovalHistory approvalHistories={this.props.approvalHistories} approvalForm={form} />
                </div>
            </div>
            {!(this.state.rejecting || this.state.approving) && <div class="text-end">
                {form.haveErrors() && <div className='alert alert-danger text-end'>
                Please complete the required information and resolve any errors that may display in order to proceed further.
                </div>}
                <button type="button" class="btn btn-primary" onClick={this.onRejectOrApprove} disabled={form.isFormDisabled()}>
                    Proceed
                </button>
            </div>}
            {this.state.rejecting &&  <div className='card border-danger border-1 mb-5 '>
                <div className='card-body bg-danger bg-opacity-10 text-center'>
                    <p className='text-danger'>
                        <b>Please confirm that you are rejecting this submission</b>
                    </p>
                    <p>
                        Please note that this form will be sent back to the DQP to update the details.
                    </p>
                    <div>
                        <button type="button" class="btn btn-secondary me-3" onClick={this.cancelReject} disabled={this.state.callingBackend}>
                            Cancel
                        </button>
                        <button type="button" class="btn btn-primary" onClick={this.confirmRejection} disabled={this.state.callingBackend}>
                            Confirm
                        </button>
                    </div>
                </div>
            </div>}
            {this.state.approving && <div className='card border-primary border-1 mb-5 '>
                <div className='card-body bg-primary bg-opacity-10 text-center'>
                    <p className='text-primary'>
                        <b>Please confirm that you are approving this submission</b>
                    </p>
                    <p>
                    Please note once your approval is confirmed, Meter will be created in relevant systems.
                    </p>
                    <div>
                        <button type="button" class="btn btn-secondary me-3" onClick={this.cancelApproving} disabled={this.state.callingBackend}>
                            Cancel
                        </button>
                        <button type="button" class="btn btn-primary" onClick={this.confirmApproval} disabled={this.state.callingBackend}>
                            Confirm
                        </button>
                    </div>
                </div>
            </div>}
        </>
    }
}