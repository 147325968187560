import React from 'react';
import moment from 'moment';
import * as bootstrap from 'bootstrap';
import uiHelpers from '../../../../helpers/uiHelpers';
import serviceUrls from '../../../../constants/serviceUrls';
import * as apiCaller from '../../../../helpers/apiCaller'
import { convertMeterCommissionAuditAction } from '../../../../helpers/converters';
import { Refresh } from '../../../../components/tools/Icons';
import { userRole, addUserRoleUpdateNotifier } from '../../../../helpers/userHelpers';
import { InputText } from '../../../../components/tools/BootstrapControls';

const actionFilterOptions = [
    'ADMIN_ACTION',
    'APPROVE_REGISTRATION',
    'APPROVE_VALIDATION',
    'ASSIGN_APPROVING_OFFICER',
    'CREATE',
    'ADD_NOTES',
    'REGISTRATION_QA_APPROVED_EMAIL',
    'REGISTRATION_QA_REJECTED_EMAIL',
    'REGISTRATION_SUBMISSION_EMAIL',
    'REJECT_REGISTRATION',
    'REJECT_VALIDATION',
    'SAVE_REGISTRATION',
    'SAVE_VALIDATION',
    'SUBMIT_REGISTRATION',
    'SUBMIT_VALIDATION',
    'TRANSFER_OWNER',
    'COMMISSION',
    'VALIDATION_QA_APPROVED_EMAIL',
    'VALIDATION_QA_REJECTED_EMAIL',
    'VALIDATION_SUBMISSION_EMAIL',
    'LID_VENDOR_EMAIL',
    'LID_REGISTRATION_APPROVED_EMAIL',
    'SUBMIT_LID_REGISTRATION',
    'CHANGE_LID_VENDOR',
    'LID_VENDOR_RESEND',
    'REJECT_LID_REGISTRATION',
    'LID_REGISTRATION_REJECTED_EMAIL'
]
export default class MeterCommissionAudits  extends React.Component {

    #commentsModalElementId = "model_" + (new Date()).getTime();
    #commentsModal;

    constructor(props) {
        super(props);
        this.state = {
            audits:[], 
            admin:(userRole.isSuperAdmin || userRole.isCDO),
            notes : '',
            notesError: null,
            actionFilter : ""
        }
    }

    async componentDidMount() {
        addUserRoleUpdateNotifier(this.userRolesUpdated);
        this.refresh();
        this.#commentsModal = new bootstrap.Modal(document.getElementById(this.#commentsModalElementId));
    }

    componentWillUnmount() {
        if(this.#commentsModal) this.#commentsModal.dispose();
    }

    openCommentsModal = () => {
        this.#commentsModal.show();
    }

    openCommentsModal = () => {
        this.#commentsModal.show();
    }

    refresh = async () => {
        uiHelpers.showProgressOnTop('Loading audits...');
        this.setState({loading:true})
        await apiCaller.post(serviceUrls.meterCommission.getAuditsById,{id:this.props.meterCommissionId})
        .then((data)=>{
            this.setState({audits:data.audits});
        }).finally(()=>{
            uiHelpers.hideProgressOnTop();
            this.setState({loading:false})
        });
    }

    userRolesUpdated = () => {
        if (userRole.isSuperAdmin || userRole.isCDO) {
            this.setState({ admin:true});
        }
    }

    saveNotes = async () => {
        this.setState({notesError : ''});
        if(this.state.notes.trim() == '') {
            this.setState({notesError : 'Please provide notes to be saved'});
            return;
        }

        uiHelpers.showProgressOnTop('Saving notes...');
        this.setState({loading:true})
        await apiCaller.post(serviceUrls.meterCommission.saveNotes,{id:this.props.meterCommissionId, notes: this.state.notes})
        .then((data)=>{
            this.setState({audits:data.audits});
            this.#commentsModal.hide();
        }).finally(()=>{
            uiHelpers.hideProgressOnTop();
            this.setState({loading:false})
        });
    }


    renderUser(audit) {
        switch(audit.auditAction) {
            case 'REJECT_REGISTRATION':
            case 'APPROVE_REGISTRATION':
            case 'REJECT_VALIDATION':
            case 'APPROVE_VALIDATION':
            case 'ASSIGN_APPROVING_OFFICER':
            case 'DATA_MIGRATION':
            case 'ADMIN_DELETE':
            case 'TRANSFER_OWNER':
            case 'ADMIN_ACTION':
                return (this.props.role=="OWNER" || this.props.role=="READER")?"WaterNSW Customer Data Officer":<>{audit.auditUser?<>{audit.auditUser.firstname} {audit.auditUser.lastname}</>:<i className='text-muted'>System</i>}</>;
            default:
                return <>{audit.auditUser?<>{audit.auditUser.firstname} {audit.auditUser.lastname}</>:<i className='text-muted'>System</i>}</>;
        }
    }

    onActionFilterSelectChanged = (e) => {
        this.setState({actionFilter: e.target.value})
    }

    render() {
        const audits = this.state.audits;
        let recordShown = 0;
        return <>
            <div class="card mb-3" >
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-md-auto  my-2'>
                            <button className='btn btn-outline-primary me-3 btn-sm' disabled={this.state.loading} onClick={this.refresh}><Refresh/></button>
                            {this.state.admin && <button className='btn btn-outline-primary me-3 btn-sm' disabled={this.state.loading} onClick={this.openCommentsModal}>Add notes</button>}
                        </div>
                        {this.state.admin && <div className='col-md-4  my-2'>
                            <div class="input-group input-group-sm">
                                <label class="input-group-text" for="actionFilterSelect">Search only: </label>
                                <select class="form-select" id="actionFilterSelect" onChange={this.onActionFilterSelectChanged}>
                                    <option value="" selected={this.state.actionFilter==null}></option>
                                    {actionFilterOptions.map(o=><option selected={this.state.actionFilter == o} value={o}>{convertMeterCommissionAuditAction(o)}</option>)}
                                </select>
                            </div>
                        </div>}
                    </div>


                    

                </div>
            </div>

            <div class="table-responsive-xxl">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col" style={{width:"200px"}}>Date &amp; Time</th>
                            <th scope="col" style={{width:"350px"}}>Action</th>
                            <th scope="col" style={{width:"180px"}}>User</th>
                            <th scope="col">Comments / Notes / Other Info</th>
                        </tr>
                    </thead>
                    <tbody>
                        {audits.map(a=>{
                        
                            let dasErrMsg;
                            if(a.externalApiPlatform=="DAS"){
                                if(a.externalApiResponseCode>400){
                                    dasErrMsg = JSON.parse(a.externalApiResponseContent).msg;
                                }
                            }

                            if(!this.state.admin && a.auditAction == "ADD_NOTES") return null;
                            if(this.state.actionFilter!= "" && this.state.actionFilter!= a.auditAction ) return null;

                            recordShown++;
                        
                            return <tr>
                                <td>{moment((new Date(a.auditDate))).format('DD/MM/YYYY h:mma')}</td>
                                <td>{convertMeterCommissionAuditAction(a.auditAction)} {!a.actionSucceeded && <i className='text-danger fs-80'>(Action Failed)</i>}</td>
                                <td>
                                    {this.renderUser(a)}
                                </td>
                                <td className='text-break'>
                                    {a.auditAction!='ASSIGN_APPROVING_OFFICER' ? a.comments : null}
                                    {dasErrMsg && <>{a.comments && <br/>}DAS message: <span className='text-danger'>{dasErrMsg}</span></>}
                                </td>
                            </tr>
                        })}

                        {recordShown==0 && <tr>
                            <td className='text-center' colSpan={4}><i className='text-muted'>No record/s</i></td>
                        </tr>}
                    </tbody>
                </table>
            </div>



            <div class="modal fade" id={this.#commentsModalElementId} data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Add notes</h5>
                        </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <InputText
                                label={null}
                                error={this.state.notesError}
                                id="notesInput"
                                onChange={(e) => {this.setState({notes : e.target.value})}}
                                value={this.state.notes}
                                disabled={this.state.loading}
                                placeholder="Notes"
                            />
                        </div>
                    </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" disabled={this.state.loading} data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" disabled={this.state.loading} onClick={this.saveNotes}>
                                Save
                                {this.state.loading && <>&nbsp;&nbsp;&nbsp;<div class="spinner-grow spinner-grow-sm" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div></>}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
}