import React from 'react';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux'
import 'react-redux';
import 'redux';
import "react-datepicker/dist/react-datepicker.css";

import { saveDraftAction, clickNextAction } from "./Validations"
import { validateCertificatePartC as validate} from './Validation';
import {dropdownDataFromReferenceData} from '../common/MeterCertificateCommon';
import { RadiosForRedux, InputTextForRedux , SelectForRedux, CheckboxForRedux, CreatableSelectForRedux, TextAreaForRedux, DatePickerForRedux}  from '../../tools/BootstrapControls'

let CertificatePartC = props => {

   const {
      handleSubmit, masterDataValues,secondaryEquipmentValidationData, previousPage, loading, hasError
   } = props

   console.log("refData "+ JSON.stringify(masterDataValues));
   debugger;
   let certOptions = dropdownDataFromReferenceData(masterDataValues.refData,"certificateFor");
   console.log("certOptions "+ JSON.stringify(certOptions));
  
   const disableFields = (parseInt(secondaryEquipmentValidationData.formStatus) == 2) || props.disableEditing || loading? true : false; 


   if (secondaryEquipmentValidationData.validationDate != null && secondaryEquipmentValidationData.validationDate != "")
   secondaryEquipmentValidationData.validationDate = new Date(secondaryEquipmentValidationData.validationDate);   

   if (secondaryEquipmentValidationData.certificateDate != null && secondaryEquipmentValidationData.certificateDate != "")
   secondaryEquipmentValidationData.certificateDate = new Date(secondaryEquipmentValidationData.certificateDate);

   if (secondaryEquipmentValidationData.surveyDate != null && secondaryEquipmentValidationData.surveyDate != "")
   secondaryEquipmentValidationData.surveyDate = new Date(secondaryEquipmentValidationData.surveyDate);   


   return (
      <form className="vertical" onSubmit={handleSubmit}>   
         <h5 className='mb-5'>Secondary measurement equipment</h5>
         <div className='row mb-3'>
            <div className='col-sm-6'>
               <Field
                  name="secondaryEquipmentValidationData.certFor"
                  component={SelectForRedux}
                  options={certOptions}
                  label={<>This certificate is for <span className='text-danger'>*</span></>}
                  props={{ disabled: disableFields }}
               /> 
            </div>
         </div>     
         <div className='row mb-3'>
            <div className='col-sm-6'>
               <Field
                  name={"secondaryEquipmentValidationData.meterType"}
                  component={RadiosForRedux} 
                  options={dropdownDataFromReferenceData(masterDataValues.refData,"meterType","")}
                  props={{ disabled: disableFields }}
                  values={secondaryEquipmentValidationData.meterType}
                  label={<>Meter Type <span className='text-danger'>*</span></>}
               />
            </div>
         </div>  

         <div className='row mb-3'>
            <div className='col-sm-6'>
               <Field
                  name="secondaryEquipmentValidationData.measurementIncrement"
                  component={SelectForRedux}
                  options={dropdownDataFromReferenceData(masterDataValues.refData,"measurementIncrement")}
                  label={<>Measurement Increment <span className='text-danger'>*</span></>}
                  props={{ disabled: disableFields}}
               /> 
            </div>
         </div>    
         <div className='row mb-3'>
            <div className='col-sm-6'>
               <Field
                  name="secondaryEquipmentValidationData.installedBy"
                  component={SelectForRedux}
                  options={dropdownDataFromReferenceData(masterDataValues.refData,"installedBy")}
                  label={<>Installed by <span className='text-danger'>*</span></>}
                  props={{ disabled: disableFields }}
               />   
            </div>
         </div>    

         {secondaryEquipmentValidationData.meterType == "1" ? <>
            <div className='row mb-3'>
               <div className='col-sm-6'>
                  <Field
                     name="secondaryEquipmentValidationData.gaugeOption"
                     component={SelectForRedux}
                     options={dropdownDataFromReferenceData(masterDataValues.refData,"gaugeOption")}
                     label={<>At what level is the Gauge board set? <span className='text-danger'>*</span></>}
                     props={{ disabled: disableFields, mandatory: true }}
                  />     
               </div>
            </div>         

            <p className='mt-4 mb-3 text-primary border-bottom border-primary'>
            Measurement device location 
            </p>

            <div className='row mb-3'>
               <div className='col-sm-6'>
                  {/* 
                  <Field
                     name="secondaryEquipmentValidationData.easting"
                     label={<>Easting <span className='text-danger'>*</span></>}
                     props={{ disabled: disableFields }}
                     component={InputTextForRedux}                        
                  />  
                  */}
                  <Field
                     name="secondaryEquipmentValidationData.latitude"
                     label={<>Latitude <span className='text-danger'>*</span></>}
                     props={{ disabled: disableFields }}
                     component={InputTextForRedux}                        
                  /> 
               </div>
               <div className='col-sm-6'>
                  {/* 
                  <Field
                     name="secondaryEquipmentValidationData.northing"
                     label={<>Northing <span className='text-danger'>*</span></>}
                     props={{ disabled: disableFields}}
                     component={InputTextForRedux}                        
                  />
                  */}
                  <Field
                     name="secondaryEquipmentValidationData.longitude"
                     label={<>Longitude <span className='text-danger'>*</span></>}
                     props={{ disabled: disableFields }}
                     component={InputTextForRedux}                        
                  /> 
               </div>
            </div>

            { (secondaryEquipmentValidationData.gaugeOption==1) && <div className='row mb-3'>
               {/* 
               <div className='col-sm-6'>
                  <Field
                     name="secondaryEquipmentValidationData.mga"
                     label={<>MGA <span className='text-danger'>*</span></>}
                     props={{ disabled: disableFields, mandatory: true }}
                     component={InputTextForRedux}                        
                  /> 
               </div>
               */}
               <div className='col-sm-6'>
                  <Field
                     name="secondaryEquipmentValidationData.levelGaugeHeight"
                     label={<>Level of gauge height (0.00m) - measuring point (m AHD) <span className='text-danger'>*</span></>}
                     props={{ disabled: disableFields, mandatory: true }}
                     component={InputTextForRedux}                        
                  />
               </div>
            </div>}
            {(secondaryEquipmentValidationData.gaugeOption==2) && <>
               <div className='row mb-3'>
                  <div className='col-sm-6'>
                     <Field
                        name="secondaryEquipmentValidationData.gaugeHeight"
                        label={<>Height on gauge at storage floor (m) - (eg. 0.35) <span className='text-danger'>*</span></>}
                        props={{ disabled: disableFields }}
                        component={InputTextForRedux}                        
                     /> 
                  </div>
                  <div className='col-sm-6'>
                     <Field
                        name="secondaryEquipmentValidationData.measuringPoint"
                        label={<>Level of gauge height - measuring point (m AHD) <span className='text-danger'>*</span></>}
                        props={{ disabled: disableFields }}
                        component={InputTextForRedux}                        
                     />
                  </div>
               </div>
               <div className='row mb-3'>
                  <div className='col-sm-6'>
                     <Field
                        name="secondaryEquipmentValidationData.storageFloorLevel"
                        label={<>Storage floor level adjacent to gauge (m AHD) <span className='text-danger'>*</span></>}
                        props={{ disabled: disableFields }}
                        component={InputTextForRedux}                        
                     /> 
                  </div>
               </div>
            </>}
            <div className='row mb-3'>
               <div className='col-sm-6'>
                  <label className='form-label'>Record the tamper-evident seal numbers<span className='text-danger'>* </span></label>
                  <Field
                     name='secondaryEquipmentValidationData.sealNo'
                     component={CreatableSelectForRedux}
                     options={secondaryEquipmentValidationData.sealNo}
                     props={{ disabled: disableFields }}
                  />
               </div>
            </div>
         </> : secondaryEquipmentValidationData.meterType == "2" ? <>
            <div className='row mb-3'>
               <div className='col-sm-6'>
                  <Field
                     name="secondaryEquipmentValidationData.solutionProvidor"
                     component={SelectForRedux}
                     options={dropdownDataFromReferenceData(masterDataValues.refData,"solutionProvidor")}
                     label={<>Solution Provider <span className='text-danger'>*</span></>}
                     props={{ disabled: disableFields}}
                  /> 
               </div>
            </div>  

            <p className='mt-4 mb-3 text-primary border-bottom border-primary'>
            Measurement device location 
            </p>

            <div className='row mb-3'>
               <div className='col-sm-6'>
                  <Field
                     name="secondaryEquipmentValidationData.latitude"
                     label={<>Latitude <span className='text-danger'>*</span></>}
                     props={{ disabled: disableFields }}
                     component={InputTextForRedux}                        
                  /> 
               </div>
               <div className='col-sm-6'>
                  <Field
                     name="secondaryEquipmentValidationData.longitude"
                     label={<>Longitude <span className='text-danger'>*</span></>}
                     props={{ disabled: disableFields }}
                     component={InputTextForRedux}                        
                  /> 
               </div>
            </div>

            <div className='row mb-3'>

               <div className='col-sm-6'>
                  <label className='form-label'>Record the tamper-evident seal numbers<span className='text-danger'>* </span></label>
                  <Field
                     name='secondaryEquipmentValidationData.sealNo'
                     component={CreatableSelectForRedux}
                     options={secondaryEquipmentValidationData.sealNo}
                     props={{ disabled: disableFields }}
                  />
               </div>
            </div>

            <p className='mt-4 mb-3 text-primary border-bottom border-primary'>
            Storage survey requirements (in accordance with Minister’s standard)
            </p>

            <div className='row mb-3'>
               <div className='col-sm-6'>
                  <Field
                     name={"secondaryEquipmentValidationData.surveryBenchmarksInstalled"}
                     component={RadiosForRedux} 
                     options={dropdownDataFromReferenceData(masterDataValues.refData,"surveryBenchmarksInstalled","")}
                     props={{ disabled: disableFields }}
                     values={secondaryEquipmentValidationData.surveryBenchmarksInstalled}
                     label={<>Survey Benchmarks Installed <span className='text-danger'>*</span></>}
                  />
               </div>
            </div> 

            <div className='row mb-3'>
               <div className='col-sm-6'>
                  <Field
                     name={"secondaryEquipmentValidationData.storageCurveAdopted"}
                     component={RadiosForRedux} 
                     options={dropdownDataFromReferenceData(masterDataValues.refData,"storageCurveAdopted","")}
                     props={{ disabled: disableFields }}
                     values={secondaryEquipmentValidationData.storageCurveAdopted}
                     label={<>Storage Curve Adopted <span className='text-danger'>*</span></>}
                  />
               </div>
            </div> 

            <p className='mt-4 mb-3 text-primary border-bottom border-primary'>
            Alternate secondary measurement equipment
            </p>

            <div className='row mb-3'>
               <div className='col-sm-4'>
                  <Field
                     name="secondaryEquipmentValidationData.sensorInstallationDate"
                     handleChange={date => { 
                        props.change(props.form, 'secondaryEquipmentValidationData.sensorInstallationDate', date) 
                     }}
                     component={DatePickerForRedux}  
                     label={<>Sensor Installation Date  <span className='text-danger'>*</span></>}                      
                     props={{ disabled: disableFields, maxDate: new Date()}}
                     values={secondaryEquipmentValidationData.sensorInstallationDate}
                  /> 
               </div>
               <div className='col-sm-4'>
                  <Field
                     name="secondaryEquipmentValidationData.siteCommissioningDate"
                     handleChange={date => { props.change(props.form, 'meteringEquipmentInformation.siteCommissioningDate', date) }}
                     component={DatePickerForRedux}
                     values={secondaryEquipmentValidationData.siteCommissioningDate}
                     props={{ disabled: disableFields, maxDate: new Date() }}
                     label={<>Site Commissioning Date <span className='text-danger'>*</span></>}                      
                  />
               </div>
            </div>

            <div className='row mb-3'>
               <div className='col-sm-6'>
                  <Field
                     name={"secondaryEquipmentValidationData.storageSensorType"}
                     component={RadiosForRedux} 
                     options={dropdownDataFromReferenceData(masterDataValues.refData,"storageSensorType","")}
                     props={{ disabled: disableFields }}
                     values={secondaryEquipmentValidationData.storageSensorType}
                     label={<>Storage Sensor Type <span className='text-danger'>*</span></>}
                  />
               </div>
            </div> 

            <div  className='row mb-3'>
               <div className='col-sm-6'>
                  <Field
                     name="secondaryEquipmentValidationData.manufacturerAndModel"
                     component={SelectForRedux}
                     options={dropdownDataFromReferenceData(masterDataValues.refData,"manufacturerAndModel")}
                     label={<>Storage sensor manufacturer and model <span className='text-danger'>*</span></>}
                     props={{ disabled: disableFields}}
                  />
               </div>
               <div className='col-sm-6'>
                  
                  {secondaryEquipmentValidationData.manufacturerAndModel == "OTHER" && <>
                     <Field
                        name="secondaryEquipmentValidationData.otherManufacturerAndModel"
                        label={<>Details of other sensor manufacturer and model<span className='text-danger'>*</span></>}
                        props={{ disabled: disableFields }}
                        component={InputTextForRedux}                        
                     />
                  </>}
               </div>
            </div>
                     
            <div  className='row mb-3'>
               <div className='col-sm-6'>
                  <Field
                     name="secondaryEquipmentValidationData.measuringPoint"
                     label={<>Level of sensor - measuring point (m AHD) <span className='text-danger'>*</span></>}
                     props={{ disabled: disableFields }}
                     component={InputTextForRedux}                        
                  />
               </div>
               <div className='col-sm-6'>
                  <Field
                     name="secondaryEquipmentValidationData.sensorSerialNumber"
                     label={<>Sensor Serial Number <span className='text-danger'>*</span></>}
                     props={{ disabled: disableFields }}
                     component={InputTextForRedux}                        
                  /> 
               </div>
            </div>

            <div className='row mb-3'>
               <div className='col-sm-4'>
                  <Field
                     name="secondaryEquipmentValidationData.dateSensorLevelledIn"
                     handleChange={date => { props.change(props.form, 'meteringEquipmentInformation.dateSensorLevelledIn', date) }}
                     component={DatePickerForRedux}
                     values={secondaryEquipmentValidationData.dateSensorLevelledIn}
                     props={{ 
                        disabled: disableFields, 
                        maxDate: new Date() ,
                        showTimeSelect:true,
                        timeIntervals:15,
                        timeCaption:"Time",
                        dateFormat:"dd/MM/yyyy h:mm aa"
                     }}
                     label={<>Date and time sensor levelled in <span className='text-danger'>*</span></>}
                  />
               </div>
            </div>

            <div  className='row mb-3'>
               <div className='col-sm-6'>
                  <Field
                     name="secondaryEquipmentValidationData.surveyorName"
                     label={<>Surveyor who levelled in measurement device <span className='text-danger'>*</span></>}
                     props={{ disabled: disableFields }}
                     component={InputTextForRedux}                        
                  /> 
               </div>
               <div className='col-sm-6'>
                  <Field
                     name="secondaryEquipmentValidationData.csvOrCmiNo"
                     label={<>CSV/CMI No. <span className='text-danger'>*</span></>}
                     props={{ disabled: disableFields }}
                     component={InputTextForRedux}                        
                  /> 
               </div>
            </div>
            


         </> : <>
               <div className='card my-5 border-3 rounded'>
                  <div className='card-body bg-light'>
                     There are field(s) that depend on the selection of <strong>Measurement Device Type</strong>. They will appear here after you select the <strong>Measurement Device Type</strong>.
                  </div>
               </div>
         </>}
         <div className='mb-3'>
            <Field
               name="secondaryEquipmentValidationData.comments"
               props={{ disabled: disableFields }}
               label={<>Comments</>}
               component={TextAreaForRedux}
               cols={40}
               rows={10}
            />  
         </div>

         {hasError && <div className='alert alert-danger'>
            Please fix all errors before you continue
         </div>}

         <div className="text-end">
            {(parseInt(secondaryEquipmentValidationData.formStatus) == 1)   && (!props.disableEditing)  && <>
               {secondaryEquipmentValidationData.id!=0 && <>
                  <button type="button"  className="btn btn-danger me-3" disabled={loading} 
                  data-bs-toggle="modal" data-bs-target="#deleteDraftModal">
                     Delete Draft
                  </button>
               </>}
            
               <button type="button"  className="btn btn-primary" disabled={loading}
                  onClick={() => { saveDraftAction(props); }}>
                  Save Draft
               </button>
               <div className='vr mx-3'></div>
            </>}
            <button type="button"  className="btn btn-warning me-3" disabled={loading} onClick={()=>{previousPage(secondaryEquipmentValidationData.formStatus)}}>
               Previous
            </button>
            <button type="button" className="btn btn-success" disabled={loading}
                  onClick={() => { clickNextAction(props); }}>
                  Next
            </button>
         </div>
      </form>
   )
}

CertificatePartC = reduxForm({
   destroyOnUnmount: false,
   forceUnregisterOnUnmount: true,
   validate,
   onSubmitFail: (errors, dispatch ) => {
      dispatch(change('certificate', 'hasError', true));
   },
   onSubmitSuccess:(result , dispatch )=>{
      dispatch(change('certificate', 'hasError', false));
      window.scrollTo(0, 0);
   }
})(CertificatePartC)

CertificatePartC = connect(
   (state, props) => {     
      const selectorCert = formValueSelector('certificate')
      const hasError = selectorCert(state, 'hasError')

      const selector = formValueSelector(props.form)
      const masterDataValues = selector(state, 'mdata')
      const documents = selector(state, 'documents')
      const secondaryEquipmentValidationData = selector(state, 'secondaryEquipmentValidationData')
      const loading = selector(state, 'loading')
      const form = props.form
      return {
         masterDataValues,
         secondaryEquipmentValidationData,
         documents,
         form,
         loading,
         hasError
      }
   }
)(CertificatePartC)

export default CertificatePartC