import React from 'react';
import { Redirect } from 'react-router-dom';
import { getMeterSiteAuditForm } from '../forms/MeterSiteAuditForm';
import serviceUrls from '../../../constants/serviceUrls';
import * as apiCaller from '../../../helpers/apiCaller'
import uiHelpers from '../../../helpers/uiHelpers';
import { AsteriskRed, LoadingPage } from '../../../components/tools/Others';
import { convertMeterSiteAuditFormStatus, convertMeterStatus } from '../../../helpers/converters';
import { ArrowRight, CheckCircle, Dot, Plus, X } from '../../../components/tools/Icons';
import DatePicker from 'react-datepicker';
import { isStringNullOrEmpty } from '../../../helpers/validators';
import { nulToEmptyAndTrim } from '../../../helpers/formatters';
import { getMeterSiteAuditApprovalForm } from '../forms/MeterSiteAuditApprovalForm';
import FileUploader from '../../../components/common/FileUploader';
import { UneditableTextInput } from '../../../components/tools/BootstrapControls';
import { MapView } from '../../../components/tools/MapView';


class DatePickerFieldForForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    

    render(){
        const form = this.props.form;
        const fieldName = this.props.fieldName;
        return <>
            <div className={`react-datepicker-block ${form.err(fieldName) ? "react-date-invalid" : ""}`}>
                <DatePicker
                    className={`form-control ${form.err(fieldName) ? "is-invalid" : ""}`}
                    onChange={value => {
                        form.vals({[fieldName]:value})
                    }}
                    selected={form.val(fieldName)?new Date(form.val(fieldName)):null}
                    maxDate={new Date()}

                    readOnly={form.isFieldDisabled(fieldName)}
                    showTimeSelect
                    timeIntervals={15}
                    filterTime={(time) => {
                        const currentDate = new Date();
                        const selectedDate = new Date(time);
                        return currentDate.getTime() >= selectedDate.getTime();
                    }}
                    dateFormat={"dd/MM/yyyy h:mm aa"}
                    timeCaption="Time"
                    open={!form.isFieldDisabled(fieldName) && this.state.datePickerOpen}
                    onInputClick={() => { this.setState(Object.assign(this.state, { datePickerOpen: true })); }}
                    onClickOutside={() => { this.setState(Object.assign(this.state, { datePickerOpen: false })); }}
                />
                <div class="invalid-feedback">
                    {form.err(fieldName)}
                </div>
            </div>
        </>
    }
}


class TamperSealNumbersField extends React.Component {

    constructor(props) {
        super(props);
        this.state = {newSealNumber:''}
    }

    onDeleteSealNumberClicked = (sealNumber) => {
        const form = this.props.form;
        const sealNumbers = form.val(this.props.fieldName);
        for(var i=0; i<sealNumbers.length; i++){
            if(sealNumbers[i] === sealNumber) {
                sealNumbers.splice(i, 1);
                form.vals({tamperSealNumbers:sealNumbers});
                return;
            }
        }
    }

    onNewSealNumberChanged = (e)=>{
        this.setState({newSealNumber:e.target.value})
    }

    onAddSealNumberClicked = () =>{
        if(isStringNullOrEmpty(this.state.newSealNumber)) return;
        const form = this.props.form;
        const sealNumbers = form.val(this.props.fieldName);
        sealNumbers.push({id:null,sealNumber:nulToEmptyAndTrim(this.state.newSealNumber)});
        form.vals({[this.props.fieldName]:sealNumbers},true,()=>this.setState({newSealNumber:''}))
    }

    render(){
        const form = this.props.form;
        const formReadOnly = this.props.formReadOnly;
        return <>
            {form.val(this.props.fieldName) && form.val(this.props.fieldName).map(t=><span className='p-2 bg-light btn-sm me-3 d-inline-block mb-3'>
                {t.sealNumber}
                {formReadOnly!==true && <button className='ms-3 btn btn-outline-secondary btn-sm' onClick={()=>this.onDeleteSealNumberClicked(t)} disabled={form.isFormDisabled() || form.isFieldDisabled(this.props.fieldName)}>
                    <X/>
                </button>}
            </span>)}

            {formReadOnly!==true && <div class="input-group">
                <input type="text" class={`form-control ${form.err(this.props.fieldName)?'is-invalid':''}`} value={this.state.newSealNumber} onChange={this.onNewSealNumberChanged} disabled={form.isFormDisabled() || form.isFieldDisabled(this.props.fieldName)}/>
                <button class="btn btn-outline-secondary" type="button" disabled={form.isFormDisabled() || form.isFieldDisabled(this.props.fieldName)} onClick={this.onAddSealNumberClicked}>Add <Plus/></button>
            </div>}
            {formReadOnly!==true && form.err(this.props.fieldName) && <div className='text-danger' style={{'font-size':'0.875em'}}>{form.err(this.props.fieldName)}</div>}
        </>
    }
}


class SingleFileField extends React.Component {

    fileUploaderDisplayListUpdate;
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        const form = this.props.form;
        const fieldName = this.props.fieldName;
        if(form.val(fieldName)) {
            this.fileUploaderDisplayListUpdate([form.val(fieldName)])
        } else {
            this.fileUploaderDisplayListUpdate([])
        }
    }

    componentWillReceiveProps(){
        const form = this.props.form;
        const fieldName = this.props.fieldName;
        if(form.val(fieldName)) {
            this.fileUploaderDisplayListUpdate([form.val(fieldName)])
        } else {
            this.fileUploaderDisplayListUpdate([])
        }
    }

    fileUploaded = async(files)=>{
        const form = this.props.form;
        const fieldName = this.props.fieldName;
        let document = null;
        files.forEach(f=>{
            document = {
                documentId:f.id,
                fileName:f.fileName,
                fileType: f.fileType,
                fileUrl: f.fileUrl,
                fileSize: f.fileSize,
                fileOriginalName: f.fileOriginalName,
                fileGUID: f.fileGUID,
                description: f.description
            };
        });
        form.vals({[fieldName]:document}, true,()=>{
            this.fileUploaderDisplayListUpdate([form.val(fieldName)])
        });
    }

    onfileDeleted=(file)=>{
        const form = this.props.form;
        const fieldName = this.props.fieldName;
        form.vals({[fieldName]:null}, true, ()=>{
            this.fileUploaderDisplayListUpdate([])
        });
     }

    render(){
        const form = this.props.form;
        const fieldName = this.props.fieldName;
        return <>
            <FileUploader
                maxFileSizeMB={10}
                exceedFileSizeErrorMsg = "Your file is too big"
                disabled={form.isFieldDisabled(fieldName) || form.isFormDisabled()}
                notShowInput={form.val(fieldName)!=null}
                onFilesUploaded={this.fileUploaded}
                provideDisplayDocumentsUpdator={(updateFunction)=>{this.fileUploaderDisplayListUpdate = updateFunction;}}
                onFileDeleted={this.onfileDeleted}
            />
            {form.haveErrors([fieldName]) && <div className='text-danger'>{form.err(fieldName)}</div>}
        </>
    }
}


const initState = {
    initialised:false,
    view:'MAIN',
    role:null,
    confirming:false,
    callingBackend:false,
    formReadOnly:false,
    rejecting:false,
    approving:false
};

class SiteAuditForm  extends React.Component {

    meterSiteAuditForm;
    meterSiteAuditFormId;
    meterSiteAuditApprovalForm
    constructor(props) {
        super(props);
        this.state = Object.assign({}, initState);
        this.meterSiteAuditForm = getMeterSiteAuditForm('meterSiteAuditForm',this,this.saveForm,this.submitForm);
        this.meterSiteAuditApprovalForm = getMeterSiteAuditApprovalForm('meterSiteAuditApprovalForm',this,this.reject, this.approve);
        this.meterSiteAuditFormId = parseInt(props.match.params.id);
    }

    async componentDidMount() {
        if(!this.meterSiteAuditFormId) return;
        this.refreshPage();
    }

    reject = () => {this.setState({rejecting:true},this.meterSiteAuditApprovalForm.disableForm)}
    cancelReject = () => {this.setState({rejecting:false},this.meterSiteAuditApprovalForm.enableForm)}
    approve = async() => {this.setState({approving:true},this.meterSiteAuditApprovalForm.disableForm)}
    cancelApproving = async() => {this.setState({approving:false},this.meterSiteAuditApprovalForm.enableForm)}

    saveForm = async () => {
        const form = this.meterSiteAuditForm;
        uiHelpers.showProgressOnTop('Please wait...');
        this.meterSiteAuditForm.disableForm();
        await apiCaller.post(serviceUrls.meterSiteAuditForm.saveSiteAuditForm,{meterSiteAuditForm:form.vals()})
        .then(this.wholePageDataBind).finally(this.wholePageAfterRefresh);
    }

    submitForm = async () => {
        this.setState({confirming:true},()=>this.meterSiteAuditForm.disableForm())
    }

    cancelSubmission = () => {
        this.setState({confirming:false},()=>this.meterSiteAuditForm.enableForm())
    }

    confirmSubmission = async () => {
        const form = this.meterSiteAuditForm;
        uiHelpers.showProgressOnTop('Please wait...');
        this.meterSiteAuditForm.disableForm(()=>this.setState({callingBackend:true}));
        await apiCaller.post(serviceUrls.meterSiteAuditForm.submitSiteAuditForm,{meterSiteAuditForm:form.vals()})
        .then((data)=>this.wholePageDataBind(data,()=>this.setState({callingBackend:false, submissionSucceed:true}))).finally(this.wholePageAfterRefresh);
    }

    refreshPage = async () => {
        uiHelpers.showProgressOnTop('Please wait...');
        this.meterSiteAuditForm.disableForm();
        await apiCaller.post(serviceUrls.meterSiteAuditForm.getSiteAuditForm,{id:this.meterSiteAuditFormId})
        .then(this.wholePageDataBind).finally(this.wholePageAfterRefresh);
    }

    wholePageAfterRefresh = () => {
        uiHelpers.hideProgressOnTop();
        window.scrollTo(0, 0);
    }

    wholePageDataBind = (data, callback) =>{
        if(data.userNoPermission) this.setState({initialised:true, noPermission:true});
        else {
            const newState = JSON.parse(JSON.stringify(initState));
            newState.initialised = true;
            newState.view = this.state.view;
            newState.role = data.role;
            if(data.meterSiteAuditForm.fields.status != 'PENDING_ENTRY') {
                newState.formReadOnly = true;
            }

            this.setState(newState,()=>{
                this.meterSiteAuditForm.vals(data.meterSiteAuditForm.fields, false);
                this.meterSiteAuditForm.setOptions(data.meterSiteAuditForm.optionsForFields, false);

                this.meterSiteAuditApprovalForm.vals(data.meterSiteAuditApprovalForm.fields,false);
                if(data.meterSiteAuditForm.fields.status == 'PENDING_ENTRY' && this.state.role=="OWNER") {
                    this.meterSiteAuditForm.enableForm(callback);
                } else {
                    this.meterSiteAuditForm.disableForm(callback);
                }
                
                if(this.fileUploaderDisplayListUpdate)
                    this.fileUploaderDisplayListUpdate(this.meterSiteAuditForm.val('documents'))
            })
        }
    }

    onRejectOrApprove = () => {
        const form = this.meterSiteAuditApprovalForm;
        if(form.val('rejectOrApprove')=="APPROVED"){
            form.errs({rejectOrApprove:null},false)
            form.runAction('approve')
        } else if(form.val('rejectOrApprove')=="REJECTED") {
            form.errs({rejectOrApprove:null},false)
            form.runAction('reject')
        } else {
            form.errs({rejectOrApprove:'Please select whether you would like to approve or reject'})
        }
    }

    confirmReject = async() => {
        uiHelpers.showProgressOnTop('Rejecting...');
        this.meterSiteAuditApprovalForm.disableForm(()=>this.setState({callingBackend:true}));
        await apiCaller.post(serviceUrls.meterSiteAuditForm.rejectMeterSiteAuditForm,{id:this.meterSiteAuditFormId, meterSiteAuditApprovalForm: this.meterSiteAuditApprovalForm.vals()})
        .then((data)=>this.wholePageDataBind(data,()=>{this.setState({rejectionSucceed:true, callingBackend:false})})).finally(this.wholePageAfterRefresh);
    }

    confirmApprove = async()=>{
        uiHelpers.showProgressOnTop('Approving form...');
        this.meterSiteAuditApprovalForm.disableForm(()=>this.setState({callingBackend:true}));
        await apiCaller.post(serviceUrls.meterSiteAuditForm.approveMeterSiteAuditForm,{id:this.meterSiteAuditFormId, meterSiteAuditApprovalForm: this.meterSiteAuditApprovalForm.vals()})
        .then((data)=>{
            if(data.backendErrors) {
                this.wholePageDataBind(data);
            } else {
                this.wholePageDataBind(data,()=>{this.setState({approvalSucceed:true, callingBackend:false})})
            }
            
        }).finally(this.wholePageAfterRefresh);
    }  

    //-------------------
    fileUploaded = async(files)=>{
        const form = this.meterSiteAuditForm;
        const documents = form.val('documents');
        files.forEach(f=>{
            documents.push({
                documentId:f.id,
                fileName:f.fileName,
                fileType: f.fileType,
                fileUrl: f.fileUrl,
                fileSize: f.fileSize,
                fileOriginalName: f.fileOriginalName,
                fileGUID: f.fileGUID
            })
        });
        form.vals({documents}, true,()=>this.fileUploaderDisplayListUpdate(documents));
    }

    onfileDeleted=(file)=>{
        const form = this.meterSiteAuditForm;
        const documents = form.val('documents');
        for(var i = 0; i< documents.length; i++){
            if(file===documents[i]) {
                documents.splice(i, 1);
                break;
            }
        }
        form.vals({documents}, true,()=>this.fileUploaderDisplayListUpdate(documents));
     }

    fileUploaderDisplayListUpdate;
    //---------------------

    renderPdfFileTop = () => {
        const form = this.meterSiteAuditForm;

        if(!form.val('certificatePath')) return null;

        return <div class="card mb-3">
            <div class="card-header bg-primary text-white">
                <b>Certificate</b> 
            </div>
            <div className='card-body'>
                {form.renderUneditableTextInputRow('certificatePath',<>Click to Open:</>,{labelWidth:4,fieldWidth:8, converter:(v)=><a target='_blank' href={'https://wnsw.sharepoint.com/teams/collaboration_CC-DQPPortal'+v}>Certificate</a>})}                
            </div>
        </div>
    }

    renderApprovalFormButtom = () => {
        const mainForm = this.meterSiteAuditForm;
        const form = this.meterSiteAuditApprovalForm;

        if(!(mainForm.val('status') == 'PENDING_QA' && this.state.role=="APPROVING_OFFICER")) return null;;
        return <>
            <div class="card mb-3">
                <div class="card-header bg-primary text-white">
                    <b>Approving Officer Use Only</b>
                </div>
                <div className='card-body'>
                    {form.renderTextBoxRow('comments',<>Comments <AsteriskRed/></>,{rowClasses:'mb-3'})}
                    <div className={`row mb-3`}>
                        <div className={`col-md-4 py-2`}>Quality Assurance Checks</div>
                        <div className={`col-md-8 pt-1`}>
                            {form.renderRadioOptions("rejectOrApprove",[
                                {optionId:"APPROVED", label:"Pass",value:"APPROVED",isChecked:(val)=>val=="APPROVED"},
                                {optionId:"REJECTED", label:"Fail",value:"REJECTED",isChecked:(val)=>val=="REJECTED"},
                            ],true)}
                        </div>
                    </div>
                </div>
            </div>
            {this.state.rejecting &&  <div className='card border-danger border-1 mb-5 '>
                <div className='card-body bg-danger bg-opacity-10 text-center'>
                    <p className='text-danger'>
                        <b>Please confirm that you are rejecting this submission</b>
                    </p>
                    <p>
                        Please note that this form will be sent back to the auditor to update the details.
                    </p>
                    <div>
                        <button type="button" class="btn btn-secondary me-3" onClick={this.cancelReject} disabled={this.state.callingBackend}>
                            Cancel
                        </button>
                        <button type="button" class="btn btn-primary" onClick={this.confirmReject} disabled={this.state.callingBackend}>
                            Confirm
                        </button>
                    </div>
                </div>
            </div>}
            {this.state.approving && <div className='card border-primary border-1 mb-5 '>
                <div className='card-body bg-primary bg-opacity-10 text-center'>
                    <p className='text-primary'>
                        <b>Please confirm that you are approving this submission</b>
                    </p>
                    <div>
                        <button type="button" class="btn btn-secondary me-3" onClick={this.cancelApproving} disabled={this.state.callingBackend}>
                            Cancel
                        </button>
                        <button type="button" class="btn btn-primary" onClick={this.confirmApprove} disabled={this.state.callingBackend}>
                            Confirm
                        </button>
                    </div>
                </div>
            </div>}
            {!(this.state.rejecting || this.state.approving) && <div class="text-end">
                {form.haveErrors() && <div className='alert alert-danger text-end'>
                Please complete the required information and resolve any errors that may display in order to proceed further.
                </div>}
                <button type="button" class="btn btn-primary" onClick={this.onRejectOrApprove} disabled={form.isFormDisabled()}>
                    Proceed
                </button>
            </div>}
            
        </>
    }


    renderApprovalFormTop = () => {
        const form = this.meterSiteAuditApprovalForm;
        if(!form.val('status') || form.val('status') == 'PENDING_APPROVAL') return null;

        return <div class="card mb-3">
            <div class="card-header bg-primary text-white">
                <b>QA Review Outcome</b>
            </div>
            <div className='card-body'>
                {form.renderUneditableTextInputRow('approvingOfficer',<>
                        {form.val('status')=='REJECTED' && <>Submission <b className='text-danger'>Not Approved</b> By</>}
                        {form.val('status')=='APPROVED' && <>Submission <b className='text-success'>Approved</b> By</>}
                    </>,
                    {rowClasses:'mb-3',converter:officer=>this.state.role=="OWNER" || this.state.role=="READER"?`WaterNSW Customer Data Officer`:<>{officer.firstname} {officer.lastname}</>}
                )}
                {form.renderUneditableTextInputRow('comments',<>Outcome</>,{labelWidth:4,fieldWidth:8,rowClasses:"mb-3"})}
                
            </div>
        </div>
    }

    renderDetailsView() {
        const form = this.meterSiteAuditForm;
        const holders = form.val('holders');
        return <>
            <hr/>
            <div className='card mb-3'>
                <div className='card-body py-1 fs-90'>
                    <span className='text-primary'><CheckCircle/> Find a Meter Site</span>
                    <span className='px-2'><ArrowRight/></span>
                    <span className='text-primary'><Dot /> <b>Site Audit Form</b></span>
                </div>
            </div>

            {this.renderPdfFileTop()}

            {this.renderApprovalFormTop()}

            {this.renderMeter()}

            <div class="card mb-3">
                <div class="card-header bg-primary text-white">
                    <b>Findings</b>
                </div>
                <div className='card-body'>
                    {form.renderYesNoOptionsRow('meetAS4747',<>Is the metering equipment installed to meet AS4747 &amp; manufacturer requirements? <AsteriskRed /></>,{rowClasses:'mb-3'})}
                    {form.val('meetAS4747')===false && form.renderTextBoxRow('detailsNoAS4747',<>Details of why? <AsteriskRed /></>,{rowClasses:'mb-3'})}

                    {form.renderYesNoOptionsRow('elecIntegrityChecks',<>Were manufacturer specified electronic integrity checks tested on site? <AsteriskRed /></>,{rowClasses:'mb-3'})}
                    {form.val('elecIntegrityChecks')===false && form.renderTextBoxRow('detailsNoElecIntegrityChecks',<>Details of why? <AsteriskRed /></>,{rowClasses:'mb-3'})}

                    {form.renderYesNoOptionsRow('powerSupplyInSpec',<>Is the power supply working within specifications? <AsteriskRed /></>,{rowClasses:'mb-3'})}
                    {form.val('powerSupplyInSpec')===false && form.renderTextBoxRow('detailsNoPowerSupplyInSpec',<>Details of why? <AsteriskRed /></>,{rowClasses:'mb-3'})}

                    {form.renderYesNoOptionsRow('solarChargingInSpec',<>Are solar panels and charging systems clean and within specifications? <AsteriskRed /></>,{rowClasses:'mb-3'})}
                    {form.val('solarChargingInSpec')===false && form.renderTextBoxRow('detailsSolarChargingInSpec',<>Details of why? <AsteriskRed /></>,{rowClasses:'mb-3'})}

                    {form.renderTextBoxRow('upstreamPipeLength',<>What is the upstream pipe length in mm on site?  <AsteriskRed /></>,{rowClasses:'mb-3',fieldWidth:3})}
                    {form.renderTextBoxRow('downstreamPipeLength',<>What is the downstream pipe length  in mm on site? <AsteriskRed /></>,{rowClasses:'mb-3',fieldWidth:3})}
                    
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>Enter tamper evident seal numbers that are being replaced  <AsteriskRed/></div>
                        <div className='col-md-8'>
                            <div className='card'><div className='card-body'>
                            <TamperSealNumbersField form={form} formReadOnly={this.state.formReadOnly} fieldName="originalTamperSealNumbers"/>
                            </div></div>
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>Enter new/replacement tamper evident seal numbers  <AsteriskRed/></div>
                        <div className='col-md-8'>
                            <div className='card'><div className='card-body'>
                            <TamperSealNumbersField form={form} formReadOnly={this.state.formReadOnly} fieldName="newTamperSealNumbers"/>
                            </div></div>
                        </div>
                    </div>
                    {form.renderTextBoxRow('lidSerialNumber',<>LID Serial Number <AsteriskRed /></>,{rowClasses:'mb-3',fieldWidth:3})}
                    {form.renderDropDownRow('lidConfigurationUnit',<>LID Configuration Unit <AsteriskRed /></>,(o)=>o.name, (o)=>o.name,{rowClasses:'mb-3',fieldWidth:3})}
                    {form.renderDropDownRow('scallingFactor',<>LID Scaling Factor / Multiplier Value <AsteriskRed /></>,(o)=>o.name, (o)=>o.name,{rowClasses:'mb-3',fieldWidth:3})}
                </div>
            </div>

            <div class="card mb-3">
                <div class="card-header bg-primary text-white">
                    <b>Photos</b>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-md-4 py-2'>
                        Evidence of Tamper Evident Seals <AsteriskRed/>
                        </div>
                        <div className='col-md-8'>
                            <SingleFileField form={form} fieldName="tamperPhotoDoc" />
                        </div>
                    </div>
                    <hr/>
                    <div className='row'>
                        <div className='col-md-4 py-2'>
                        Meter Emplacement <AsteriskRed/>
                        </div>
                        <div className='col-md-8'>
                            <SingleFileField form={form} fieldName="meterEmplacementDoc" />
                        </div>
                    </div>
                    <hr/>
                    <div className='row'>
                        <div className='col-md-4 py-2'>
                        Meter Display Reading at time of Audit <AsteriskRed/>
                        </div>
                        <div className='col-md-8'>
                            <SingleFileField form={form} fieldName="meterReadingDoc" />
                        </div>
                    </div>
                    <hr/>
                    <div className='row'>
                        <div className='col-md-4 py-2'>
                        Others
                        </div>
                        <div className='col-md-8'>
                            <FileUploader
                                maxFileSizeMB={10}
                                exceedFileSizeErrorMsg = "Your file is too big"
                                disabled={form.isFieldDisabled('documents') || form.isFormDisabled()}
                                notShowInput={false}
                                onFilesUploaded={this.fileUploaded}
                                provideDisplayDocumentsUpdator={(updateFunction)=>{this.fileUploaderDisplayListUpdate = updateFunction;}}
                                onFileDeleted={this.onfileDeleted}
                            />
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="card mb-3">
                <div class="card-header bg-primary text-white">
                    <b>Inspection Details</b>
                </div>
                <div className='card-body'>
                    <div className={`row mb-3`}>
                        <div className={`col-md-4 py-2`}>Date &amp; time inspection was completed <AsteriskRed/></div>
                        <div className={`col-md-3`}>
                            <DatePickerFieldForForm form={form} fieldName="inspectionCompletedDate" />
                        </div>
                    </div>
                    
                    {form.renderTextBoxRow('inspectionMeterRead',<>Meter read at time of inspection (totaliser value) <AsteriskRed /></>,{rowClasses:'mb-3',fieldWidth:3})}
                    {form.renderTextBoxRow('inspectionDASRawFlow',<>DAS raw flow value at 7:00pm on day of inspection <AsteriskRed /></>,{rowClasses:'mb-3',fieldWidth:3})}
                    {form.renderYesNoOptionsRow('inspectionDASAlarms',<>Is the DAS displaying any alarms? <AsteriskRed /></>,{rowClasses:'mb-3'})}
                    {form.val('inspectionDASAlarms')===true && form.renderTextBoxRow('inspectionDASAlarmsYes',<>Details of alarms <AsteriskRed /></>,{rowClasses:'mb-3'})}
                    {form.renderTextBoxRow('instructions',<>Information/Instructions for approval holder/s <AsteriskRed /></>,{rowClasses:'mb-3'})}
                </div>
            </div>

            {this.state.confirming ? <div className='card border-primary border-1 mb-5 '>
                <div className='card-body bg-primary bg-opacity-10 text-center'>
                    <p className='text-primary'>
                        <b>Please confirm all fields are filled in correctly</b>
                    </p>
                    <div>
                        <button type="button" class="btn btn-secondary me-3" onClick={this.cancelSubmission} disabled={this.state.callingBackend}>
                            Cancel
                        </button>
                        <button type="button" class="btn btn-primary" onClick={this.confirmSubmission} disabled={this.state.callingBackend}>
                            Confirm
                        </button>
                    </div>
                </div>
            </div> 
            :
            form.val('status') == 'PENDING_ENTRY' && <div className='text-end'>
                <div className='text-end'>
                    <button type="button" class="btn btn-primary me-3" onClick={()=>form.runAction('save')} disabled={form.isFormDisabled()}>
                        Save
                    </button>
                    <button type="button" class="btn btn-success" onClick={()=>form.runAction('submit')} disabled={form.isFormDisabled()}>
                        Submit
                    </button>

                </div>
            </div>
            }
            {this.renderApprovalFormButtom()}

            {this.state.submissionSucceed && <div className='alert alert-success text-center mt-3'>This form is submitted for QA Review. Upon completion of review, an email shall be sent.</div>}
            {this.state.approvalSucceed && <div className='alert alert-success text-center mt-3'>This form has passed QA review. An email notification will be sent to the Auditor. </div>}
            {this.state.rejectionSucceed && <div className='alert alert-primary text-center text-danger mt-3'>This form has failed QA review. An email notification will be sent to the Auditor.</div>}
        </>
    }

    renderHeader() {
        const form = this.meterSiteAuditForm;
        return <>
            <h3 class="mb-3">
            Meter Site Audit
            </h3>
            <div className='row mb-3'>
                <div className='col-md-3 mb-3'>
                    {form.renderUneditableTextInput("id",null,"Form ID")}
                </div>
                <div className='col-md-3 mb-3'>
                    {form.renderUneditableTextInput("status",s=>convertMeterSiteAuditFormStatus(s),"Form Status")}
                </div>
                <div className='col-md-3 mb-3'>
                    {form.renderUneditableTextInput("owner",o=><>{o.firstname} {o.lastname}</>,"User")}
                </div>
            </div>
            
        </>
    }

    renderMeter () {
        const form = this.meterSiteAuditForm;
        const meter = form.val('meter');
        if(meter == null) return null;
        const holders = form.val('holders');
        return <>

<div class="card mb-3">
                <div class="card-header  bg-primary text-white">
                    <b>Approval Details </b>
                </div>
                <div className='card-body'>
                    {form.renderUneditableTextInputRow('approval',<>Approval Number</>,{rowClasses:'mb-3',converter:o=>o.licence})}
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>Holder/s</div>
                        <div className='col-md-8 py-2'>
                            <ul class="list-group">
                                {holders && holders.map(h=><li class="list-group-item">
                                    {h.name}
                                    <div className='fs-80 ps-1'>
                                        <strong>Email:</strong> {h.email}<br/>
                                        <strong>Address:</strong> {h.postalAddressLine1} {h.postalAddressLine2?`, ${h.postalAddressLine2}`:''}, {h.postalSuburb}, {h.postalState}, {h.postalPostcode}<br/>
                                        {h.phoneNumbers.length!=0 && <>
                                            <strong>Phone Number/s:</strong> {h.phoneNumbers.map((p,i)=>{
                                                if(i!=0) return <>, {p.prefix} {p.phoneNumber}</>;
                                                return <>{p.prefix} {p.phoneNumber}</>;
                                            })}
                                        </>}
                                    </div>
                                </li>)}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mb-3">
                <div class="card-header bg-primary text-white">
                    <b>Details of Approved Works</b>
                </div>
                <div className='card-body'>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Property
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.property?meter.property.propertyName:null}
                            />
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Lot
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.lot?<>{meter.lot.lotNumber}/{meter.lot.sectionNumber}/{meter.lot.planNumber} {meter.lot.planType}</>:null}
                            />
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                            Extraction Site
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.extractionSite?meter.extractionSite.esid:null}
                            />
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                            Water Sharing Plan
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.extractionSite?meter.extractionSite.waterSharingPlanDesc:null}
                            />
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Water Source
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.extractionSite?meter.extractionSite.waterSourceName:null}
                            />
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Water Type
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.extractionSite?meter.extractionSite.waterType:null}
                            />
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Works ID
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.works?meter.works.worksId:null}
                            />
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Works Description
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.works?meter.works.workType:null}
                            />
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="card mb-3">
                <div class="card-header bg-primary text-white">
                    <b>Meter Details</b>
                </div>
                <div className='card-body'>
                    {form.renderUneditableTextInputRow('meter',<>Meter ID in Portal</>,{rowClasses:'mb-3',converter:o=>o.id})}
                    {form.renderUneditableTextInputRow('meter',<>Worksite ID</>,{rowClasses:'mb-3',converter:o=>o.meterSiteId})}
                    {form.renderUneditableTextInputRow('meter',<>Source ID</>,{rowClasses:'mb-3',converter:o=>o.meterId})}
                    {form.renderUneditableTextInputRow('meter',<>Meter Status</>,{rowClasses:'mb-3',converter:o=>convertMeterStatus(o.status)})}
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Meter Number
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.meterNumber}
                            />
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Meter System Type
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.meteringSystemTypes?meter.meteringSystemTypes.name:null}
                            />
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Meter Manufacturer and Model
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.meterTypeModel?meter.meterTypeModel.name:null}
                            />
                        </div>
                    </div>
                    {meter.meterTypeModel && meter.meterTypeModel.key=='Other' && <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Other Manufacturer and Model Text
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.otherModelText}
                            />
                        </div>
                    </div>}
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Meter Serial Number
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.meterSerialNumber}
                            />
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Meter Size(mm)
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.meterSize}
                            />
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Pattern Approval Number (if applicable)
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.patternApprovalNumber}
                            />
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Tamper-evident Seal Numbers
                        </div>
                        <div className='col-md-8'>
                            {meter.sealNumbers.map(s=><span class="bg-light me-3 p-2 d-inline-block">{s}</span>)}
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mb-3">
                <div class="card-header bg-primary text-white">
                    <b>Meter Location</b>
                </div>
                <div className='card-body'>
                    <div className='row mb-3'>
                        <div className='col-md-4'>
                            <UneditableTextInput
                                label="Latitude"
                                fieldExtraClass="bg-light"
                                value={meter.latitude}
                            />
                        </div>
                        <div className='col-md-4'>
                            <UneditableTextInput
                                label="Longitude"
                                fieldExtraClass="bg-light"
                                value={meter.longitude}
                            />
                        </div>
                    </div>

                    <MapView
                        readOnly={true}                
                        point={{ latitude: meter.latitude, longitude: meter.longitude}}
                        disabled={true}
                    />
                </div>
            </div>

            {meter.meteringSystemTypes && (meter.meteringSystemTypes.key=='CLOSED_CONDUIT' || meter.meteringSystemTypes.key=='PORTABLE_METER') && <div class="card mb-3">
                <div class="card-header bg-primary text-white">
                    <b>Emplacement Details</b>
                </div>
                <div className='card-body'>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Pipe length upstream of meter (mm)
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.pipeLengthUpstream}
                            />
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Pipe length downstream of meter (mm)
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.pipeLengthDownstream}
                            />
                        </div>
                    </div>
                </div>
            </div>}

            <div class="card mb-3">
                <div class="card-header bg-primary text-white">
                    <b>LID Details</b>
                </div>
                <div className='card-body'>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Is the LID being used for Telemetry?
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.meterLid.isLidTelemetry === true ? "Yes" : meter.meterLid.isLidTelemetry === false ? "No" : null}
                            />
                        </div>
                    </div>
                    {meter.meterLid.isLidTelemetry && <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        LID Telemetry Type
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.meterLid.telemetryType}
                            />
                        </div>
                    </div>}
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        LID Device
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.meterLid.lid.name}
                            />
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        LID Serial number
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.meterLid.lidSerialNumber}
                            />
                        </div>
                    </div>

                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        LID Type
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.meterType?meter.meterType.name:null}
                            />
                        </div>
                    </div>
                    {meter.meterType && meter.meterType.key=='PULSE' && <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Scaling Factor (Multiplier)
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.meterScalingFactor}
                            />
                        </div>
                    </div>}
                </div>
            </div>

            <div class="card mb-3">
                <div class="card-header bg-primary text-white">
                    <b>Compliance</b>
                </div>
                <div className='card-body'>
                    <p><b>Does the metering equipment comply with Australian Standard 4747: Meters for non-urban water supply?</b></p>
                    <p>{meter.isComplied===true?<>Yes</>:meter.isComplied===false?<>No</>:"Nil"}</p>
                    {meter.meterNoneComplianceReasons.length>0 && <>
                        <p><b>Reasons for not compliance</b></p>
                        <ul>
                            {meter.meterNoneComplianceReasons.map(r=><li>{r}</li>)}
                        </ul>
                    </>}
                </div>
            </div>
        </>
    }


    render() {
        if(this.state.redirectTo) return <Redirect to={this.state.redirectTo} />;
        if(!this.meterSiteAuditFormId) return <div className='alert alert-danger'>You access this page incorrectly</div>
        if(!this.state.initialised) return <LoadingPage />;

        if(this.state.role=='NO_ACCESS') return <div className='alert alert-danger'>Access denied.</div>

        return <>
            {this.renderHeader()}
            {this.state.view == 'MAIN' && this.renderDetailsView()}
        </>
    }
}

export default SiteAuditForm;
