import React, { Fragment } from 'react';
import axios from 'axios';
import Authentication from '../helpers/Authentication'
import Select  from 'react-select';
import BasePageLayout from './base/BasePageLayout'
import ApprovalSearchFormWithConsent from '../components/common/ApprovalSearchFormWithConsent'  
import RegistrationFormReadOnly from '../components/meterRegistration/RegistrationFormReadOnly'
import {telemetryReplacementSelectOptions} from '../constants/TelemetryReplacementConstants'
import uiHelpers from '../helpers/uiHelpers';

const authentication = new Authentication();


const convertFormStatus = (formStatus) => {
    switch(formStatus) {
        case 0 : return 'Open'
        case 1 : return 'Submitted'
        case 2 : return 'Installed'
        case 3 : return 'Removed'
        default:return 'Unknown'
    }
}

class NUMTelemetryReplacement extends React.Component {



   constructor(props) {
      super(props);
      this.state = {
          formDisplayControl : {
            showApprovalSearch : true,
            showWorkspaceSearch : false,
            workspaceSearching : false,
            showRegistrationFormSearch : false,
            registrationSearching : false,
            registrationSearchError : '',
            confirmReplacement:false,
            creatingNewRegistration : false
          },
          approvalSearchResult : {
            approvalNumber : null
          },
          workspaceSearchResult : null,
          selectedWorkspace : null,
          registrationSearchResult : null,
          selectedRegistration : null,

          selectedReplacementType : null
      }
   }

    axiosConfig = {
        baseURL: process.env.REACT_APP_WEBAPI_URL,
        headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authentication.getToken() }
    };

    approvalSelectedHandler = async (approvalNumber, selectedHolder, callback) => {
        //this.setState(Object.assign(this.state.formDisplayControl, { showApprovalSearch: false, showWorkspaceSearch:true }));
        this.setState(Object.assign(this.state.approvalSearchResult, { approvalNumber: approvalNumber }));
        await this.searchWorkSpaces(approvalNumber);
        callback();
    }

    async searchWorkSpaces(approvalNumber){
        this.setState(Object.assign(this.state.formDisplayControl, { workspaceSearching : true }));
        var currentContext = this;
        uiHelpers.showProgressOnTop('Searching for work space...');
        await axios.post('/das/getWorkspacesByApprovalNumber', JSON.stringify({approvalNumber}), this.axiosConfig).then(function (response) {
            currentContext.setState(Object.assign(currentContext.state.formDisplayControl, { showApprovalSearch: false, showWorkspaceSearch:true }));
            currentContext.setState(Object.assign(currentContext.state, { workspaceSearchResult : response.data.data }));
            uiHelpers.hideProgressOnTop();
        }).catch(function (error) {
            console.log(error);
            uiHelpers.showErrorTop('Error occurred when searching workspace for approval...');
        });
        this.setState(Object.assign(this.state.formDisplayControl, { workspaceSearching : false }));
    }

    async searchRegistraions(workspace){
        
        this.setState(Object.assign(this.state, { selectedWorkspace : workspace }));
        this.setState(Object.assign(this.state.formDisplayControl, { showWorkspaceSearch : false, showRegistrationFormSearch: true, registrationSearching: true }));
        var currentContext = this;
        await axios.post('/meterregistration/getByMeterSiteId', JSON.stringify({meterSiteId : workspace.workspaceName}), this.axiosConfig).then(function (response) {
            currentContext.setState(Object.assign(currentContext.state, { registrationSearchResult : response.data.data }));
        }).catch(function (error) {
            console.log(error);
            currentContext.setState(Object.assign(currentContext.state.formDisplayControl, { registrationSearchError : 'Error in search for registrations' }));
        });
        this.setState(Object.assign(this.state.formDisplayControl, { registrationSearching : false }));
    }

    backToSearchWorkSpaces = ()=>{
        this.setState(Object.assign(this.state, { selectedWorkspace : null, registrationSearchResult: null, selectedRegistration:null, selectedReplacementType:null }));
        this.setState(Object.assign(this.state.formDisplayControl, { showRegistrationFormSearch : false, showWorkspaceSearch:true , confirmReplacement:false}));
    }

    selectRegistration = (registration) =>{
        this.setState(Object.assign(this.state, { selectedRegistration : registration }));
    }

    replaceOptionChange = (e) => {
        this.setState(Object.assign(this.state, { selectedReplacementType:e.value }));
    }


    replaceBtnClick =() =>{
        this.setState(Object.assign(this.state.formDisplayControl, { confirmReplacement:true }));
    }

    cancelConfirmation = () =>{
        this.setState(Object.assign(this.state.formDisplayControl, { confirmReplacement:false }));
    }

    confirmReplacement =() =>{
        
        this.setState(Object.assign(this.state.formDisplayControl, { creatingNewRegistration:true }));
        var currentContext = this;
        axios.post('/meterregistration/createReplacementForm', JSON.stringify({ 
            replacingMeterRegistrationId: this.state.selectedRegistration.id,
            replacementType :this.state.selectedReplacementType
        }), this.ajaxConfig).then((response) => {

        }, (error) => {
            alert('Error occur when creating replacement form')
            console.log(error);
            currentContext.setState(Object.assign(currentContext.state.formDisplayControl, { creatingNewRegistration:false }));
        });
    }
    

    render(){
        return <>
               <h3 className='mb-3'>Non-Urbarn Metering Telemetry Replacement Form </h3>

               {this.state.formDisplayControl.showApprovalSearch && <ApprovalSearchFormWithConsent onCustomerConsentValid={this.approvalSelectedHandler} />}

               {this.state.formDisplayControl.showWorkspaceSearch && <>
                    
                    <h5 className='mb-3'>Select a workspace in DAS for <span className="text-blue">{this.state.approvalSearchResult.approvalNumber}</span></h5>

                    {this.state.workspaceSearchResult && this.state.workspaceSearchResult.length==0 && <div className="alert alert-primary">No workspace found for {this.state.approvalSearchResult.approvalNumber}</div>}

                    {this.state.workspaceSearchResult && this.state.workspaceSearchResult.length!=0  && <table className="table fs-90">
                        <thead>
                            <tr>
                                <th style={{"width":"100px"}}></th>
                                <th style={{"width":"200px"}}>Workspace</th>
                                <th style={{"width":"100px"}}>Source</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.workspaceSearchResult.map((workspace, i) => <tr>
                                <td>
                                    <button type="button" class="btn btn-primary btn-sm" onClick={(e)=>{this.searchRegistraions(workspace)}}>Select</button>
                                </td>
                                <td>{workspace.workspaceName}</td>
                                <td>{workspace.sourceName}</td>
                                <td>{workspace.latestFlowValueUnit==null?<span class="label label-default">New</span> : 
                                    workspace.lnstallationDate==null?<span class="label label-warning">Connected to LID</span> : 
                                    <span class="label label-success">Installed</span>
                                }</td>
                            </tr>)}
                        </tbody>
                    </table>}
               </>}

               {this.state.formDisplayControl.showRegistrationFormSearch && <>
                    
                    <div className='mb-3'>
                        <button type="button" class="btn btn-secondary btn-sm" disabled={this.state.formDisplayControl.registrationSearching || this.state.formDisplayControl.creatingNewRegistration} onClick={this.backToSearchWorkSpaces}>&nbsp;&nbsp;&nbsp;Back&nbsp;&nbsp;&nbsp;</button>
                    </div>

                    <table className='table'>
                        <tbody>
                            <tr>
                                <th style={{width:"120px"}}>Workspace:</th>
                                <td>{this.state.selectedWorkspace.workspaceName}</td>
                            </tr>
                            <tr>
                                <th >Source:</th>
                                <td>{this.state.selectedWorkspace.sourceName}</td>
                            </tr>
                            <tr>
                                <th >Status:</th>
                                <td>
                                    {this.state.selectedWorkspace.latestFlowValueUnit==null?<span class="badge bg-secondary">New</span> : 
                                    this.state.selectedWorkspace.lnstallationDate==null?<span class="badge  bg-warning">Testing</span> : 
                                    <span class="badge bg-success">Installed</span>}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="card">
                    {!this.state.selectedRegistration && <div class="card-header fw-bold">Select a previous registration form for this workspace for replacement</div>}
                    {this.state.selectedRegistration && <div class="card-header fw-bold">Please select type of replacement for the selected registration </div>}
                    <div class="card-body">
                        {this.state.formDisplayControl.registrationSearching && <div className="alert alert-info">Searching for registrations ... <span style={{ color: 'green' }}><i className="fa fa-spinner fa-spin" style={{ fontSize: '24px', color: 'green' }}></i></span></div>}
                        {this.state.formDisplayControl.registrationSearchError!='' && <div className="alert alert-danger">{this.state.formDisplayControl.registrationSearchError}</div>}
                        {this.state.registrationSearchResult && <>
                            {this.state.registrationSearchResult.length==0 && <div className="alert alert-info">No registration found for this workspace</div>}

                            {this.state.registrationSearchResult.length!=0 && !this.state.selectedRegistration && <div>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th style={{width:"50px"}}></th>
                                            <th style={{width:"50px"}}>ID</th>
                                            <th style={{width:"100px"}}>Status</th>
                                            <th style={{width:"150px"}}>DQP</th>
                                            <th style={{width:"150px"}}>DAS created</th>
                                            <th style={{width:"150px"}}>DAS installed</th>
                                            <th style={{width:"200px"}}>DAS Workspace ID</th>
                                            <th>DAS Source ID</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.registrationSearchResult.map((registration, i) => <tr>
                                            <td>
                                                <button type="button" class="btn btn-primary btn-sm" onClick={(e)=>{this.selectRegistration(registration)}}>Select</button>
                                            </td>
                                            <td>{registration.id}</td>
                                            <td>{convertFormStatus(registration.formStatus)}</td>
                                            <td>{registration.createdBy.firstName} {registration.createdBy.lastName}</td>
                                            <td>
                                                {registration.eagleIoIntegrationStatus ==null && registration.formStatus!=1 && <span class="label label-default">Not created</span>}
                                                {registration.eagleIoIntegrationStatus ==null && registration.formStatus==1 && <span class="label label-danger">Not created</span>}
                                                {registration.eagleIoIntegrationStatus ==0 && <span class="label label-success">Created</span>}
                                                {registration.eagleIoIntegrationStatus ==1 && <span class="label label-danger">Error</span>}
                                            </td>
                                            <td>
                                                {!registration.installationCompletedEmailSent && registration.formStatus!=2 && <span class="label label-default">Not installed</span>}
                                                {!registration.installationCompletedEmailSent && registration.formStatus==2 && <span class="label label-danger">Not installed</span>}
                                                {registration.installationCompletedEmailSent && <span class="label label-success">Installed</span>}
                                            </td>
                                            <td>{registration.meterSiteId ? registration.meterSiteId.meterSiteId : "" }</td>
                                            <td>{registration.meterId ? registration.meterId.meterId : "" }</td>
                                            
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>}

                            {this.state.selectedRegistration && <>
                                <div class="card mb-3">
                                    <div class="card-body">
                                        {!this.state.formDisplayControl.confirmReplacement && <div className='row '>
                                            <div className='col-sm-6'>
                                                <Select
                                                    options={telemetryReplacementSelectOptions}
                                                    onChange = {this.replaceOptionChange}  
                                                    defaultValue={telemetryReplacementSelectOptions.map((option, index) => {
                                                        if(option.value == this.state.selectedReplacementType) {
                                                            return option;
                                                        }
                                                    })}
                                                />
                                            </div>
                                            <div className='col-sm-3'>
                                                <button class="btn btn-primary" disabled={!this.state.selectedReplacementType} onClick={this.replaceBtnClick} type="button">Next</button>
                                            </div>
                                        </div>}
                                        {this.state.formDisplayControl.confirmReplacement && <>
                                            
                                                <p>
                                                Please confirm that you would like to replace the selected registration form below with option 
                                                </p>
                                                <p>
                                                    <strong className="text-primary">
                                                    {telemetryReplacementSelectOptions.map((option, index) => {
                                                        if(option.value == this.state.selectedReplacementType) {
                                                            return option.label;
                                                        }
                                                    })}
                                                    </strong>
                                                </p>
                                                <button class="btn btn-primary" disabled={this.state.formDisplayControl.creatingNewRegistration} onClick={this.confirmReplacement} type="button">Confirm and continue</button>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                <button class="btn btn-secondary" disabled={this.state.formDisplayControl.creatingNewRegistration} onClick={this.cancelConfirmation} type="button">Cancel</button>
                                        </>}
                                    </div>
                                </div>
                                <RegistrationFormReadOnly meterRegistration={this.state.selectedRegistration} />
                            </>}

                        </>}
                    </div>
                    </div>
                    

                    
                </>}

        </>;
   }
}

export default NUMTelemetryReplacement;