import { FlatForm,  mandatoryValidator, positiveNumberValidator, preConditionToValidate, trueValidator, txtMandatoryValidator } from "../../../helpers/FormHelper";
import serviceUrls from '../../../constants/serviceUrls';
import * as apiCaller from '../../../helpers/apiCaller'
import uiHelpers from '../../../helpers/uiHelpers';

const refreshLidOptions = async (form)=>{
    
    const telemetryType = form.val('telemetryType');

    form.disableForm();
    uiHelpers.showProgressOnTop('Refreshing LID options...');
    await apiCaller.post(
        serviceUrls.meterFormCommon.getLidOptionsForVendor,
        {
            isLidTelemetry: form.val('isLidTelemetry'),
            installationTypeId: form.val('installationType')?form.val('installationType').id:null,
            telemetryTypeId: telemetryType?telemetryType.id:null,
            formGUID:form.val("guid")
        }
    ,true)
    .then((result)=>{
        form.setOptions({lid:result.lidOptions},false);
    }).finally(()=>{
        form.enableForm();
        uiHelpers.hideProgressOnTop();
    });
}



const refreshLidsWithSameApproval = async (form)=>{

    form.disableForm();
    uiHelpers.showProgressOnTop('Refreshing LIDs under approval...');

    await apiCaller.post(
        serviceUrls.meterFormCommon.getExistingLidsForApprovalForVendor,
        {
            formGUID:form.val("guid"),
            lid:form.val('lid')
        }
    )
    .then((result)=>{
        form.setOptions({existingLid:result.lids})
    }).finally(()=>{
        form.enableForm();
        uiHelpers.hideProgressOnTop();
    });
    
}

export const getLidInstallationForVendorForm = (formName, context, acceptFormAction, rejectFormAction, submitFormAction, openLidConfirmationModal) => {

    const result = new FlatForm(formName, context);

    result.initialise([
        "id",
        "installationType",
        "isLidTelemetry",
        "telemetryType",
        "lid",
        "isFirstMeterForLID",
        "existingLid",
        "lidVendor",
        "formVersion",
        "lidInstallationStatus",
        "meterScalingFactor",
        "meterType",

        "workApproval",
        "esid",
        "meterNumber",
        "manufacturerAndModel",
        "otherManufacturerAndModelText",
        "existingWasMeter",
        "existingMeter",
        "guid",
        "tested",
        "comments",
        "certificate"
    ])
    .setValueChangedValidators({
        existingLid:[
            (val)=>{
                if(val==null) return null;
                if(val.meters.length>=4) return "We have maxiumn 4 meters to be allowed to connect to one LID";
            }
        ]
    })
    .setPostChangeActions({
        telemetryType: (value, values, form)=>{
            form.vals({lid:null,isFirstMeterForLID:null,existingLid:null,meterType:null,meterScalingFactor:''}, false);
            form.errs({lid:null,isFirstMeterForLID:null,existingLid:null,meterType:null,meterScalingFactor:null}, false);
            refreshLidOptions(form);
        },
        lid: (value, values, form) =>{
            openLidConfirmationModal();
            let meterType = null;
            const meterTypeOptions = form.options("meterType");
            if(value.allowMultiple) {
                for(var i =0; i < meterTypeOptions.length; i++){
                    if(meterTypeOptions[i].key=="MODBUS"){
                        meterType=meterTypeOptions[i];
                    }
                }
            }

            form.vals({
                isFirstMeterForLID:null,
                existingLid:null,
                meterType,
                meterScalingFactor:''
            },false);
            form.errs({
                isFirstMeterForLID:null,
                existingLid:null,
                meterType:null,
                meterScalingFactor:null
            },false);
            form.setOptions({existingLid:[]})
        },
        isFirstMeterForLID:(value, values, form)=>{
            if(form.val('isFirstMeterForLID')===false) {
                refreshLidsWithSameApproval(form);
            } else {
                form.vals({existingLid:null},false);
                form.errs({existingLid:null},false);
                form.setOptions({existingLid:[]})
            }
        },
        meterType:(value, values, form)=>{
            form.vals({
                meterScalingFactor:''
            },false);
            form.errs({
                meterScalingFactor:null
            },false);
        }
    })
    .defineAction('accept',acceptFormAction,{
        telemetryType :[preConditionToValidate((val, vals)=>vals.isLidTelemetry,mandatoryValidator("This is required"))],
        lid:[mandatoryValidator("This is required")],
        isFirstMeterForLID:[preConditionToValidate((val, vals)=>vals.lid && vals.lid.allowMultiple===true,mandatoryValidator("This is required"))],
        meterType:[mandatoryValidator("This is required")],
        meterScalingFactor:[preConditionToValidate((val, vals)=>vals.meterType && vals.meterType.key=='PULSE',txtMandatoryValidator("This is required")),positiveNumberValidator('Please enter a valid number')],
        existingLid:[(val,vals)=>{
            if(vals.isFirstMeterForLID===false) {
                if(val==null) return "This is required"
                if(val.meters.length >= 4) return "We have maxiumn 4 meters to be allowed to connect to one LID";
            }
            return null;
        }],
        //tested:[trueValidator("Please test this meter before submitting")],
        //certificate:[mandatoryValidator("This is required")]
    })
    .defineAction('reject',rejectFormAction,{
        comments:[txtMandatoryValidator("This is required when you are returning the form to DQP")]
    })
    .defineAction('submit',submitFormAction,{
        tested:[trueValidator("Please test this meter before submitting")],
        //certificate:[mandatoryValidator("This is required")]
    })
    
    ;
    return result;
}
