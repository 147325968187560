import React from 'react';
import moment from 'moment';
import * as $ from 'jquery'
import * as apiPathes from '../apiPathes';
import * as serviceCaller from '../serviceCaller'
import {ArrowRight, Pen, Meter} from '../../components/tools/Icons'
import {convertDateIsoToAusFormat, convertOtherInFlowType,convertDateTimeIsoToAusFormat, convertStatus, convertErrorCode} from '../conveters'
import {UneditableTextInput} from '../../components/tools/BootstrapControls'
import {isStringNullOrEmpty} from '../../helpers/validators'


class CloseOrFinalisePeriod  extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            loading: false
        };
    }

    componentDidMount() {
        $("html, body").animate({ scrollTop: 0}, 50);
    }

    closeOrFinalisePeriod = () => {
        const period = this.props.nominatedMeasurementPeriod;
        serviceCaller.post(period.status=="OPEN" ? apiPathes.closeNominatedMeasurementPeriod : apiPathes.finaliseNominatedMeasurementPeriod,
            {nominatedMeasurementPeriodId: period.id}, 
            {
                noIWasLoginHandler: this.props.noLoginCallback,
                loadingMsg: period.status=="OPEN" ? 'Closing period...' : 'Finalising period...',
                beforeSend: ()=>{
                    this.setState({loading:true, error : null})
                },
                success: (data)=>{
                    this.props.updatePeriod(data, false, false, ()=>{
                        this.props.completedOrCancel();
                    })
                },
                errorCodeHandler: (errorCode) => {
                    this.setState({loading:false, error : convertErrorCode(errorCode)})
                }
        });
    }

    displayReason = () => {

        const period = this.props.nominatedMeasurementPeriod;
        
        if(period.status=="OPEN")
            return (new Date() - new Date(period.periodEndDate))/(1000 * 60 * 60 * 24) > 1
        else 
            return (new Date(period.closedDate) - new Date(period.periodEndDate))/(1000 * 60 * 60 * 24) > 1
    }

    render(){

        const period = this.props.nominatedMeasurementPeriod;
        const otherInFlows = period.nominatedMeasurementPeriodInFlows.filter((o)=>!o.isDeleted);
        const storages = period.nominatedMeasurementPeriodStorages;

        return <>
            <h3 className='mb-3'>Confirming {period.status=="OPEN" ? 'closing':'finalising'} the nominated measurement period</h3>
            <div className='mb-3 alert alert-primary'>Please <strong>confirm</strong> that you would like to {period.status=="OPEN" ? 'close':'finalise'} this period</div>

            <div className='row'>
                <div className='col-md-3 mb-3'>
                    <UneditableTextInput  label="Record id:" fieldExtraClass="bg-light"  value={period.id} />
                </div>
                <div className='col-md-3 mb-3'>
                    <UneditableTextInput label="Status changing to:"  fieldExtraClass="bg-primary bg-opacity-10 border-primary" value={<>{convertStatus(period.status)} <ArrowRight/> <strong>{convertStatus(period.status=="OPEN"?'CLOSED':'FINALISED')}</strong></> }/>
                </div>
                <div className='col-md-3 mb-3'>
                    <UneditableTextInput  label="Approval:"  fieldExtraClass="bg-light" value={period.approvalNumber} />
                </div>
            </div>

            <div className='row'>
                <div className='col-md-3 mb-3'>
                    <UneditableTextInput 
                        label={<>Start date &amp; time:</>} fieldExtraClass={`${period.status=="OPEN" ? 'bg-primary bg-opacity-10 border-primary' : 'bg-light'}`}
                        value={convertDateTimeIsoToAusFormat(period.periodStartDate, <i className='text-muted'>Not provided</i>)}
                    />
                </div>
                <div className='col-md-3 mb-3'>
                    <UneditableTextInput 
                        label={<>End date &amp; time:</>} fieldExtraClass={`${period.status=="OPEN" ? 'bg-primary bg-opacity-10 border-primary' : 'bg-light'}`}
                        value={convertDateTimeIsoToAusFormat(period.periodEndDate, <i className='text-muted'>Not provided</i>)}
                    />
                </div>
            </div>
            {this.displayReason() && <div className="row mb-3">
                <div class="col-md-8">
                    <div className='card card-body'>
                        <label  class="form-label"><strong>Reason for not lodging within 24 hours</strong></label>
                        <div>
                            <em>
                            The end date of this measurement period is not within 24h of the close date/time. This will be logged for record with the Natural Resources Access Regulator (NRAR)
                            </em>
                        </div>
                        <div className={`card card-body ${period.status=="OPEN" ? "border-primary bg-primary bg-opacity-10" : ""}`}>
                            <pre style={{"white-space": "pre-wrap"}}>{isStringNullOrEmpty(period.reasonForNotLodging) ? <i className='text-muted'>Reason not provided yet...</i> : period.reasonForNotLodging }</pre>
                        </div>
                    </div>
                </div>
            </div>}
            {period.status=="OPEN" && <div className='row'>
                <div className={`${this.displayReason() ? "col-md-8" : "col-md-6"} mb-3`}>
                    <div className='alert alert-warning'>After closing this record, <strong>start date &amp; time</strong>, <strong>end date &amp; time</strong> {this.displayReason() && <>and <strong>reason for not lodging within 24 hours</strong></>} cannot be changed again</div>
                </div>
            </div>}

            {otherInFlows.length>0 && <div className='card mt-4 mb-3'>
                <div className='card-header'>
                Other in-flow during the measurement period
                </div>
                <div className='card-body'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th scope="col" style={{"width":"120px"}}>Start date</th>
                                <th scope="col" style={{"width":"120px"}}>End date</th>
                                <th scope="col" style={{"width":"200px"}}>Type of water</th>
                                <th scope="col" style={{"width":"200px"}}>Access licence</th>
                                <th scope="col">Volumn (ML)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {otherInFlows.map(o=><tr>
                                <td>{convertDateIsoToAusFormat(o.startDate)}</td>
                                <td>{convertDateIsoToAusFormat(o.endDate)}</td>
                                <td>{convertOtherInFlowType(o.inFlowType)}</td>
                                <td>{o.accessLicense}</td>
                                <td>{o.volume}</td>
                            </tr>)}
                            <tr className={`table-secondary`}>
                                <td colSpan={4} className="text-end"><strong>Total volume:</strong></td>
                                <td >
                                    <strong className='text-primary'>
                                        {function(){
                                            var total = 0 ;
                                            otherInFlows.forEach(o=>{
                                                if(o.isDeleted) return;
                                                total = total + o.volume;
                                            })
                                            return total;
                                        }()}
                                    </strong>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>}

            {storages.map(s=><>
                <div className='card mb-3'>
                    <div className='card-header'>
                    Readings for <strong>{s.ratingTable.ratingTableName}</strong>
                    </div>
                    <div className='card-body'>
                        {s.isManual && <>
                            <div className='text-primary mb-3'>Daily log for this storage is entered manually <Pen/></div>
                            {period.status=="CLOSED"  && <div className='alert alert-warning'>Daily log cannot be changed after finalised</div>}
                        </>}
                        {!s.isManual && <div className='text-primary mb-3'>Daily log for this storage is from DAS <Meter/></div>}
                        <table className='table'>
                            <tbody>
                                <tr>
                                    <th style={{width:"320px"}} className='text-end'> Height (AHD) prior to period start:</th>
                                    <td>{s.isManual ? s.heightPriorToPeriodManualAhd : s.heightPriorToPeriodDAS}</td>
                                </tr>
                                <tr>
                                    <th className='text-end'> Volume prior to period start:</th>
                                    <td>{s.isManual ? s.volumePriorToPeriodManual : s.volumePriorToPeriodDAS}</td>
                                </tr>
                            </tbody>
                        </table>

                        <table className='table mb-3'>
                            <thead>
                                <tr>
                                    <th style={{"width":"200px"}}>
                                        Date
                                    </th>
                                    {(s.isManual && s.secondaryEquipmentValidation && s.secondaryEquipmentValidation.meterType=="2") ? <>
                                    
                                    </> : <>
                                        {s.isManual && <th style={{"width":"200px"}}>
                                        Height (gauge reading)
                                        </th>}
                                        <th style={{"width":"200px"}}>
                                        Height (AHD)
                                        </th>
                                    </>}
                                    
                                    <th>
                                        Volume
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {s.nominatedMeasurementPeriodStorageDailys.map(d=>{
                                    if(s.isManual && s.secondaryEquipmentValidation && s.secondaryEquipmentValidation.meterType=="2") return <tr>
                                        <td>{convertDateIsoToAusFormat(d.dailyDate)}</td>
                                        <td>{d.volumeManual ? d.volumeManual : <em className='text-muted'>No data</em>}</td>
                                    </tr>;

                                    if(s.isManual) return <tr>
                                        <td>{convertDateIsoToAusFormat(d.dailyDate)}</td>
                                        <td>{d.heightManual ? d.heightManual : <em className='text-muted'>No data</em>}</td>
                                        <td>{d.heightManualAhd ? d.heightManualAhd : <em className='text-muted'>No data</em>}</td>
                                        <td>{d.volumeManual ? d.volumeManual : <em className='text-muted'>No data</em>}</td>
                                    </tr>
                                
                                    return <tr>
                                        <td>{convertDateIsoToAusFormat(d.dailyDate)}</td>
                                        <td>{d.heightDAS ? d.heightDAS : <em className='text-muted'>No data</em>}</td>
                                        <td>{d.volumeDAS ? d.volumeDAS : <em className='text-muted'>No data</em>}</td>
                                    </tr>
                                })}

                                {(s.isManual && s.secondaryEquipmentValidation && s.secondaryEquipmentValidation.meterType=="2") ? <>
                                    <tr className='table-secondary'>
                                        <th className='text-end'>Change in volume:</th>
                                        <td><strong className="text-primary">{s.totalVolumeChange} {s.totalVolumeChange!=null && "ML"}</strong></td>
                                    </tr>
                                </> : 
                                <tr className='table-secondary'>
                                    <th className='text-end' colSpan={s.isManual?3:2}>Change in volume:</th>
                                    <td><strong className="text-primary">{s.totalVolumeChange} {s.totalVolumeChange!=null && "ML"}</strong></td>
                                </tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>)}

            <div className="card mb-3 border-primary">
                <div class="card-header text-primary">
                    <strong>Summary</strong>
                </div>
                <div class="card-body">
                    <table className='table'>
                        <tbody>
                            <tr>
                                <th className='w-25 text-end'>
                                    Total change in storage volume:
                                </th>
                                <td>
                                    {period.totalChangeInStorageVolume} ML
                                </td>
                            </tr>
                            <tr>
                                <th className='w-25 text-end'>
                                    Total other Non-FPH inflow:
                                </th>
                                <td>
                                    {period.totalOtherNonFPHInFlow} ML
                                </td>
                            </tr>
                            <tr className='table-primary'>
                                <th className='w-25 text-end'>
                                    Total FPH Volume:
                                </th>
                                <td>
                                    {period.totalFPHVolume} ML
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            {this.state.error && <div className='alert alert-danger text-end'>
                {this.state.error}
            </div>}

            <div className='text-end'>
                <button className='btn btn-primary me-3' disabled={this.state.loading} onClick={this.closeOrFinalisePeriod}>Confirm {period.status=="OPEN" ? 'close':'finalise'} period</button>
                <button className='btn btn-outline-secondary' disabled={this.state.loading} onClick={this.props.completedOrCancel}>Cancel</button>
            </div>

            
        </>;
    }

}

export default CloseOrFinalisePeriod;