import React from 'react';
import {Dot, ArrowRight, CheckCircle, QuestionFill} from '../../../components/tools/Icons'
import uiHelpers from '../../../helpers/uiHelpers';
import serviceUrls from '../../../constants/serviceUrls';
import * as apiCaller from '../../../helpers/apiCaller'
import {  converters } from '../../../helpers/converters';
import { Link } from "react-router-dom";
import MeterCommissionHeading from './common/MeterCommissionHeading';
import { LoadingPage } from '../../../components/tools/Others';
import MeterCommissionAudits from './common/MeterCommissionAudits';
import { userRole, addUserRoleUpdateNotifier } from '../../../helpers/userHelpers';
import MeterCommissionAdmin from './common/MeterCommissionAdmin';
import MeterCommissionConsent from './common/MeterCommissionConsent';
import { getLidInstallationForm } from '../forms/LidInstallationForm';


const initState = {
    view:'MAIN',
    initialised:false,
    noPermission: false,
    adminTab:false,
    originalVendor:null,
    confirmingSave:false,
    vendorChangedSuccessfully:false
}

class LidInstallation  extends React.Component {

    meterCommissionId;
    lidInstallationForm;

    constructor(props) {
        super(props);
        this.state = JSON.parse(JSON.stringify(initState));
        this.lidInstallationForm = getLidInstallationForm('lidIntallationForm',this);
        this.meterCommissionId = parseInt(props.match.params.id);
    }


    async componentDidMount() {
        addUserRoleUpdateNotifier(this.userRolesUpdated);
        if(!this.meterCommissionId) return;
        this.refreshPage();
    }

    userRolesUpdated = () => {
        if (userRole.isSuperAdmin) {
            this.setState({ adminTab:true});
        }
    }

    refreshPage = async () => {
        uiHelpers.showProgressOnTop('Please wait...');
        this.lidInstallationForm.disableForm();
        await apiCaller.post(serviceUrls.meterCommission.getMeterCommissionLidRegistration,{id:this.meterCommissionId})
        .then(this.wholePageDataBind).finally(this.wholePageAfterRefresh);
    }

    changeLidVendor = async () => {
        uiHelpers.showProgressOnTop('Please wait...');
        this.lidInstallationForm.disableForm();
        await apiCaller.post(serviceUrls.meterCommission.changeLidVendorLidRegistration,{id:this.meterCommissionId, newLidVendorId: this.lidInstallationForm.val("lidVendor").id})
        .then((data)=>this.wholePageDataBind(data,()=>{
            this.setState({vendorChangedSuccessfully:true})
        })).finally(this.wholePageAfterRefresh);
    }

    wholePageDataBind = (data, callback=null) =>{
        if(data.userNoPermission) this.setState({initialised:true, noPermission:true});
        else {
            const newState = JSON.parse(JSON.stringify(initState));
            newState.role = data.role;
            newState.meterCommission = data.meterCommission;
            newState.initialised = true;
            newState.audits = data.audits;
            newState.view = this.state.view;
            newState.adminTab = userRole.isSuperAdmin;
            newState.originalVendor = data.lidInstallationForm.fields.lidVendor;
            
            this.setState(newState,()=>{

                this.lidInstallationForm.vals(data.lidInstallationForm.fields, false);
                this.lidInstallationForm.setOptions(data.lidInstallationForm.optionsForFields, false);
                if(this.lidInstallationForm.val("lidInstallationStatus") == "REJECTED" ) this.lidInstallationForm.enableField("lidVendor",false);
                if(data.meterCommission.status == 'PENDING_LID_REGISTRATION' && this.state.role=="OWNER") {
                    this.lidInstallationForm.enableForm(callback);
                } else {
                    this.lidInstallationForm.disableForm(callback);
                } 
            })
        }
    }

    wholePageAfterRefresh = () => {
        uiHelpers.hideProgressOnTop();
        window.scrollTo(0, 0);
    }

    onCancelClick = () => {
        this.refreshPage();
    }

    onSaveClick = () => {
        this.setState({confirmingSave:true},()=>this.lidInstallationForm.disableField("lidVendor"))
    }


    render() {
        if(!this.meterCommissionId) return <div className='alert alert-danger'>You access this page incorrectly</div>
        if(!this.state.initialised) return <LoadingPage />;
        if(this.state.noPermission) return <div className='alert alert-danger'>Access denied.</div>

        const form = this.lidInstallationForm;
        const getExistingLidLable = (o)=>
            <div className="text-truncate">This LID is connecting to {o.meters.map((m,i)=>{
                if(i==0) return <><strong className='text-primary fs-80'>{m.meterSiteId}</strong></>;
                return <>, <strong className='text-primary fs-80'>{m.meterSiteId}</strong></>;
            })}
        </div>;
        return <>

            <MeterCommissionHeading 
                meterCommission={this.state.meterCommission} role={this.state.role} 
            />

            <div className='mb-3'>
                <ul className="nav nav-pills">
                    <li className="nav-item">
                        <a onClick={(e)=>{e.preventDefault();this.setState({view:'MAIN'});}} className={`nav-link ${this.state.view=='MAIN'?'active':''}`}  href="#">Process Details</a>
                    </li>
                    <li className="nav-item">
                        <a onClick={(e)=>{e.preventDefault();this.setState({view:'AUDIT'})}} className={`nav-link ${this.state.view=='AUDIT'?'active':''}`} href="#">Audit</a>
                    </li>
                    <li className="nav-item">
                        <a onClick={(e)=>{e.preventDefault();this.setState({view:'CONSENT'})}} className={`nav-link ${this.state.view=='CONSENT'?'active':''}`} href="#">Customer Authorisation</a>
                    </li>
                    {this.state.role!="OWNER" && this.state.adminTab && <li className="nav-item">
                        <a onClick={(e)=>{e.preventDefault();this.setState({view:'ADMIN'})}} className={`nav-link ${this.state.view=='ADMIN'?'active':''}`} href="#">Admin</a>
                    </li>}
                </ul>
            </div>

            <hr/>
            {this.state.view=='AUDIT' ? <>
                <MeterCommissionAudits role={this.state.role} meterCommissionId={this.meterCommissionId}/>
            </> : this.state.view=='CONSENT' ? 
                <MeterCommissionConsent consent={this.state.meterCommission.consent}/> 
            : this.state.view=='ADMIN' ? <>
                <MeterCommissionAdmin 
                    meterCommission={this.state.meterCommission} 
                    refreshPage={this.refreshPage} 
                    lid={form.val('lid')}
                    installationTypeKey= { form.vals().installationType ? form.vals().installationType.key:null}
                />
            </> : <>

                <div className='card mb-3'>
                    <div className='card-body py-1 fs-90'>
                        <span className='text-primary'><CheckCircle /> Find Approval &amp; Customer Authorisation</span> 
                        <span className='px-2'><ArrowRight/></span>

                        <CheckCircle /> <Link to={`/compliance-process/${this.state.meterCommission.id}/site-registration`}>Site Registration</Link>                        
                        <span className='px-2'><ArrowRight/></span>

                        {this.state.meterCommission.status=='PENDING_LID_REGISTRATION' ?  <Dot/> : <CheckCircle/>}
                        <strong className='text-primary'> LID Registration</strong>
                        <span className='px-2'><ArrowRight/></span>


                        {this.state.meterCommission.status=='COMPLETED' ?  <span className='text-primary'><CheckCircle/> </span> : <span className='text-primary'><Dot/> </span>}
                        {this.state.meterCommission.status=='PENDING_LID_REGISTRATION' ?
                            <i className='text-secondary fs-80'>Commissioning &amp; Validation</i>
                            :
                            <Link to={`/compliance-process/${this.state.meterCommission.id}/validation`}>Commissioning &amp; Validation</Link>                        
                        }
                    </div>
                </div>
                <div class="card mb-3">
                    <div class="card-header bg-primary text-white">
                        <b>LID Vendor</b>
                    </div>
                    <div className='card-body'>
                        {form.val('lidInstallationStatus') == 'REJECTED' ? 
                            form.renderDropDownRow('lidVendor',<>LID Vendor <span className='text-danger'>*</span></>,(o)=>o.id,o=>o.name,{rowClasses:'mb-3'})
                            :
                            form.renderUneditableTextInputRow('lidVendor',<>LID Vendor</>,{rowClasses:'mb-3', converter:o=>o.name})
                        }

                        {(this.state.originalVendor && form.val("lidVendor") && this.state.originalVendor.id != form.val("lidVendor").id ) &&  <>
                            {this.state.confirmingSave ? <div className='card border-primary border-1 mb-5 '>
                                <div className="card-body bg-primary bg-opacity-10 text-center">
                                    <p className="text-primary"><b>Please confirm that you would like to update the LID Vendor</b></p>
                                    <p className='fs-80'>Please note that a new email will be sent to the updated LID Vendor and the previous email with the link will be disabled.</p>
                                    <div>
                                        <button type="button" className="btn btn-secondary me-3" disabled={form.isFormDisabled()} onClick={this.onCancelClick}>Cancel</button>
                                        <button type="button" className="btn btn-primary" disabled={form.isFormDisabled()} onClick={this.changeLidVendor}>Confirm</button>
                                    </div>
                                </div>
                            </div>
                            : 
                            <div className='row'>
                                <div className='col-md-4'></div>
                                <div className='col-md-8'>
                                    <button className='btn btn-primary me-3' disabled={form.isFormDisabled()} onClick={this.onSaveClick}>Save</button>
                                    <button className='btn btn-secondary' onClick={this.onCancelClick} disabled={form.isFormDisabled()}>Cancel</button>
                                </div>
                            </div>}
                        </>}

                        {(form.val("lidInstallationStatus") == "REJECTED" && this.state.originalVendor && form.val("lidVendor") && this.state.originalVendor.id == form.val("lidVendor").id )  && <>

                            {this.state.confirmingSave ? <div className='card border-primary border-1 mb-5 '>
                                <div className="card-body bg-primary bg-opacity-10 text-center">
                                    <p className="text-primary"><b>Please confirm that you would like to update the LID Vendor</b></p>
                                    <p className='fs-80'>Please note that a new email will be sent to the updated LID Vendor and the previous email with the link will be disabled.</p>
                                    <div>
                                        <button type="button" className="btn btn-secondary me-3" disabled={form.isFormDisabled()} onClick={this.onCancelClick}>Cancel</button>
                                        <button type="button" className="btn btn-primary" disabled={form.isFormDisabled()} onClick={this.changeLidVendor}>Confirm</button>
                                    </div>
                                </div>
                            </div>
                            : 
                            <div className='row'>
                                <div className='col-md-4'></div>
                                <div className='col-md-8'>
                                    <button className='btn btn-primary me-3' disabled={form.isFormDisabled()} onClick={this.onSaveClick}>Resend</button>
                                </div>
                            </div>}

                        </>}

                        {this.state.vendorChangedSuccessfully && <div className='alert alert-success'>
                            Email is sent to LID vendor for LID Registration. Please check your email for further notifications.
                        </div>}
                        
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="card-header bg-primary text-white">
                        <b>LID Details</b>
                    </div>
                    <div className='card-body'>

                        {form.val("lidInstallationStatus") == "REJECTED" && <div className='alert alert-danger'>
                            <p>The LID Vendor has returned this form to you. Please update the LID Vendor.</p>
                            Comments from LID Vendor: {form.val('comments')}
                        </div>}

                        {form.val("lidInstallationStatus") == "PENDING_SUBMIT" && <div className='alert alert-primary'>
                            Pending for LID Vendor to provide LID details
                        </div>}

                        {form.val("lidInstallationStatus") == "ACCEPTED" && <div className='alert alert-primary'>
                            Pending for LID Vendor to do testing
                        </div>}

                        {form.renderUneditableTextInputRow('isLidTelemetry',<>Is the LID being used for Telemetry?</>,{rowClasses:'mb-3', converter:converters.common.converBoolToYesNo})}

                        {
                            form.val('isLidTelemetry')===true 
                            && 
                            form.renderUneditableTextInputRow('telemetryType',<>LID Telemetry Type</>,{rowClasses:'mb-3', converter:t=>t?t.name:null})
                        }
                        {form.renderUneditableTextInputRow('lid',<>LID Device</>,{rowClasses:'mb-3', converter:lid=>lid?lid.name:null})}

                        {form.renderUneditableTextInputRow("meterType",<>LID Type</>,{converter:o=>o && o.name,rowClasses:'mb-3'})}
                        {form.val('meterType') && form.val('meterType').key=='PULSE' && form.renderUneditableTextInputRow('meterScalingFactor',<>Scaling Factor (Multiplier)</>,{rowClasses:'mb-3'})}

                        {   
                            form.val('lid') && form.val('lid').allowMultiple 
                            &&
                            form.renderUneditableTextInputRow('isFirstMeterForLID',<>Is this the first meter registered to this LID?</>,{rowClasses:'mb-3', converter:converters.common.converBoolToYesNo})
                        }

                        {form.val('isFirstMeterForLID') ===false && <>
                            {form.renderUneditableTextInputRow('existingLid',<>Existing LID </>,{rowClasses:'mb-3', converter:getExistingLidLable})}
                            {form.renderUneditableTextInputRow('existingLid',"LID Serial Number",{converter:value=>value?value.lidSerialNumber:null})}
                        </>}

                        {form.renderUneditableTextInputRow('comments',<>Comments</>,{rowClasses:'mb-3'})}

                        {form.renderUneditableTextInputRow('tested',<>LID to DAS Test Successful?</>,{rowClasses:'mb-3', converter:converters.common.converBoolToYesNo})}

                    </div>
                </div>

                {form.val("lidInstallationStatus") == 'COMPLETED' && <div className='text-end'>
                    <Link className="btn btn-success" to={`/compliance-process/${this.state.meterCommission.id}/validation`}>Next</Link>
                </div>}
                


            </>}
        </>
    }

    
}

export default LidInstallation;
